import { OrderFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { ChartDataResponse } from '@models/home';
import { HandlingStatusMap, OrderDetails, OrdersOverviewResponse } from '@models/orders';
import { UserAccess } from '@models/user';
import { getData, postData } from '@utils/data';
import { isUserAccessOfInternalAdmin } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { flatten } from 'lodash';
import { urls } from './config';

const appJsonContentTypeHeader = new Map([
  [HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON],
]);
export interface OngoingOrdersSummaryResponse {
  data: ChartDataResponse;
  totalOrdersNumber: number;
}
export const fetchOngoingOrdersSummary = (
  userAccess: UserAccess,
): Promise<OngoingOrdersSummaryResponse> => {
  if (isUserAccessOfInternalAdmin(userAccess)) {
    if (!urls.ongoingOrdersSummaryForAdmin) {
      throw Error('Ongoing orders summary for admins URL not found');
    }

    const businessPartnerCodes = userAccess.selectedBusinessPartners.map(bp => bp.id);
    return postData<OngoingOrdersSummaryResponse>(
      urls.ongoingOrdersSummaryForAdmin,
      JSON.stringify({
        businessPartnerCodes,
      }),
      appJsonContentTypeHeader,
    );
  }

  if (!urls.ongoingOrdersSummary) throw Error('Ongoing orders summary URL not found');

  return getData<OngoingOrdersSummaryResponse>(urls.ongoingOrdersSummary);
};

/**
 * Different order status codes can have same translations, e.g. O27_PICKED and O29_DELIVE are both translated to 'In transportation'
 * Since selectItems for Order Status Multiselect are created by using uniqBy function (see mapOrderStatusToSelectItems function),
 * re-mapping has to be performed here to get all order status codes, that were lost by uniqBy
 */
export const fetchOrders = async (
  userAccess: UserAccess,
  orderFilters: OrdersSelectedFiltersWithSearch,
  activePage: number,
  numberOfPageElements: number,
  sortBy: string,

  language: string,
): Promise<OrdersOverviewResponse> => {
  const url = `?page=${activePage - 1}&size=${numberOfPageElements}`;

  if (!urls.orderInfo) throw Error('Orders url not found');

  const allStatuses = flatten(orderFilters.orderStatus.map(status => HandlingStatusMap[status]));

  //TODO try to find better solution for empty filters
  const filters = {
    orderStatus: allStatuses.length === 0 ? null : allStatuses,
    orderType: orderFilters.orderType.length === 0 ? null : orderFilters.orderType,
    city: orderFilters.city.length === 0 ? null : orderFilters.city,
    containerType: orderFilters.containerType.length === 0 ? null : orderFilters.containerType,
    transportType: orderFilters.transportType.length === 0 ? null : orderFilters.transportType,
    businessPartner:
      orderFilters.businessPartner.length === 0 ? null : orderFilters.businessPartner,
    wasteDescription:
      orderFilters.wasteDescription.length === 0 ? null : orderFilters.wasteDescription,
    search: orderFilters.search === '' ? null : orderFilters.search,
    timePeriod: orderFilters.timePeriod,
  };

  if (isUserAccessOfInternalAdmin(userAccess)) {
    return postData(
      `${urls.orderInfoAdmin}${url}`,
      JSON.stringify({
        businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
        language,
        sortBy,
        filters,
      }),
      appJsonContentTypeHeader,
    );
  }
  return postData(
    `${urls.orderInfo}${url}`,
    JSON.stringify({
      language,
      sortBy,
      filters,
    }),
    appJsonContentTypeHeader,
  );
};

export const fetchOrderFilters = (
  userAccess: UserAccess,
  precedence: { key: string; order: number }[],
  selectedFilters: OrdersSelectedFiltersWithSearch,
  language: string,
): Promise<OrderFilters> => {
  const isInternalAdmin = isUserAccessOfInternalAdmin(userAccess);
  const url = isInternalAdmin ? urls.ordersFiltersForAdmin : urls.ordersFilters;

  if (!url) {
    throw new Error(
      isInternalAdmin
        ? `Orders filters for Admin URL not found`
        : `Orders filters for Admin URL not found`,
    );
  }

  const allStatuses = flatten(selectedFilters.orderStatus.map(status => HandlingStatusMap[status]));

  const body = {
    language,
    metaFilters: {
      filters: { ...selectedFilters, orderStatus: allStatuses },
      precedence,
    },
    businessPartnersIds: isInternalAdmin
      ? userAccess.selectedBusinessPartners.map(bp => bp.id)
      : null,
  };

  return postData(url, JSON.stringify(body), appJsonContentTypeHeader);
};

export const fetchOrderDetails = (orderNo: string, language: string) => {
  if (!urls.orderDetails) {
    throw new Error('Order details URL not found');
  }

  const url = `${urls.orderDetails}/${orderNo}`;

  return postData<OrderDetails>(
    url,
    JSON.stringify({
      language,
    }),
    appJsonContentTypeHeader,
  );
};
