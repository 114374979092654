import { useOrderFilters } from '@data/hooks/useOrderFilters';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { OrdersFiltersHandlerReturnType, SelectItems, SELECT_ITEMS_INITIAL_VALUES } from './types';
import { useFiltersSetters } from './useFiltersSetters';
import { useDebounceValue } from 'usehooks-ts';
import { useMemo, useEffect, useState, useLayoutEffect } from 'react';
import {
  mapOrderStatusToSelectItems,
  mapToTranslatedSelectItems,
  mapToSelectItems,
} from '../utils';

const debounceOptions: Parameters<typeof useDebounceValue>[2] = { maxWait: 1000, trailing: true };

export const useOrdersFiltersHandler = (
  temporaryState: boolean,
  debounceTimeMS: number | undefined = 400,
): OrdersFiltersHandlerReturnType => {
  const { i18n } = useTranslation<Namespace>('orders');

  const { selectedFiltersValues, setters, reset, setAllExceptSearch, filtersPrecedence, setAll } =
    useFiltersSetters();

  const [
    debouncedSelectedFiltersValuesWithPrecedence,
    setDebouncedSelectedFiltersValuesWithPrecedence,
  ] = useDebounceValue(
    useMemo(() => ({ selectedFiltersValues, filtersPrecedence }), []),
    debounceTimeMS,
    debounceOptions,
  );

  useEffect(() => {
    setDebouncedSelectedFiltersValuesWithPrecedence({
      selectedFiltersValues,
      filtersPrecedence,
    });
  }, [selectedFiltersValues]);

  const {
    data: availableFilters,
    isLoading,
    isError,
  } = useOrderFilters(
    temporaryState,
    debouncedSelectedFiltersValuesWithPrecedence.filtersPrecedence,
    debouncedSelectedFiltersValuesWithPrecedence.selectedFiltersValues,
  );
  const [selectItems, setSelectItems] = useState<SelectItems>(SELECT_ITEMS_INITIAL_VALUES);

  useEffect(() => {
    if (!availableFilters) {
      return;
    }

    setSelectItems({
      orderStatus: mapOrderStatusToSelectItems(availableFilters.orderStatus),
      orderType: mapToTranslatedSelectItems(availableFilters.orderType, 'domain', 'orderTypes'),
      city: mapToSelectItems(availableFilters.city),
      containerType: mapToSelectItems(availableFilters.containerType),
      transportType: mapToTranslatedSelectItems(
        availableFilters.transportType,
        'domain',
        'transportType',
      ),
      businessPartner: mapToSelectItems(availableFilters.businessPartner),
      wasteDescription: mapToSelectItems(availableFilters.wasteDescription),
      timePeriod: mapToTranslatedSelectItems(
        availableFilters.timePeriod,
        'orders',
        'filters.timePeriod.values',
      ),
    });
  }, [availableFilters, i18n.language]);

  useLayoutEffect(() => {
    reset();
  }, [i18n.language]);

  return {
    selectedFiltersValues,
    setters,
    selectItems,
    reset,
    setAllExceptSearch,
    filtersPrecedence,
    setAll,
    isLoading,
    isError,
    debouncedSelectedFiltersValuesWithPrecedence,
  };
};
