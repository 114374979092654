export const EmptyContractsIcon = () => (
  <svg
    width="208"
    height="144"
    viewBox="0 0 208 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 120H208V144H0V120Z" fill="#F2F2F2" />
    <path d="M0 72H208V96H0V72Z" fill="#F2F2F2" />
    <path d="M0 24H208V48H0V24Z" fill="#F2F2F2" />
    <path d="M16 0H48V16H16V0Z" fill="#0F7A5A" />
    <path d="M16 32H48V40H16V32Z" fill="#C9C9C9" />
    <path d="M16 56H48V64H16V56Z" fill="#C9C9C9" />
    <path d="M16 80H48V88H16V80Z" fill="#C9C9C9" />
    <path d="M16 104H48V112H16V104Z" fill="#C9C9C9" />
    <path d="M16 128H48V136H16V128Z" fill="#C9C9C9" />
    <path d="M64 0H96V16H64V0Z" fill="#0F7A5A" />
    <path d="M64 32H96V40H64V32Z" fill="#C9C9C9" />
    <path d="M64 56H96V64H64V56Z" fill="#C9C9C9" />
    <path d="M64 80H96V88H64V80Z" fill="#C9C9C9" />
    <path d="M64 104H96V112H64V104Z" fill="#C9C9C9" />
    <path d="M64 128H96V136H64V128Z" fill="#C9C9C9" />
    <path d="M112 0H144V16H112V0Z" fill="#0F7A5A" />
    <path d="M112 32H144V40H112V32Z" fill="#C9C9C9" />
    <path d="M112 56H144V64H112V56Z" fill="#C9C9C9" />
    <path d="M112 80H144V88H112V80Z" fill="#C9C9C9" />
    <path d="M112 104H144V112H112V104Z" fill="#C9C9C9" />
    <path d="M112 128H144V136H112V128Z" fill="#C9C9C9" />
    <path d="M160 0H192V16H160V0Z" fill="#0F7A5A" />
    <path d="M160 32H192V40H160V32Z" fill="#C9C9C9" />
    <path d="M160 56H192V64H160V56Z" fill="#C9C9C9" />
    <path d="M160 80H192V88H160V80Z" fill="#C9C9C9" />
    <path d="M160 104H192V112H160V104Z" fill="#C9C9C9" />
    <path d="M160 128H192V136H160V128Z" fill="#C9C9C9" />
  </svg>
);
