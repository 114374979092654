import { verticalContainer } from '@components/styles';
import { ColProps, CommonGridProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const VerticalContainerWithGap = styled.div`
  ${verticalContainer};

  gap: ${spacing.xs};
`;

export const gridStyles: CommonGridProps = {
  mh: { xxl: 0 },
  maxWidth: { xxl: '100%' },
};

export const columnStyles: ColProps = {
  xxs: 12,
  l: 6,
};
