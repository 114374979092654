import { ChangeEvent, memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  BoxPropValue,
  Multiselect as ElementalMultiselect,
  MultiselectProps as ElementalMultiselectProps,
  Select,
  SelectProps,
  SelectValueType,
} from '@fortum/elemental-ui';
import { getElementsNames } from './utils';
import { isEqual } from 'lodash';
import { Stylable } from '@components/types';

type MultiselectProps<T extends SelectValueType> = {
  name: string;
  onSelectedItemsChange: (selectedItems: T[]) => void;
  maxWidth?: BoxPropValue;
  label: string;
} & Pick<
  SelectProps<T>,
  | 'error'
  | 'errorMessage'
  | 'disabled'
  | 'placeholder'
  | 'displayValue'
  | 'borderStyle'
  | 'id'
  | 'variant'
> &
  Pick<
    ElementalMultiselectProps<T>,
    'selectAll' | 'items' | 'selected' | 'width' | 'ml' | 'filterItems' | 'size'
  >;

const CustomMultiselect = <T extends SelectValueType>({
  name,
  disabled,
  onSelectedItemsChange,
  filterItems,
  displayValue,
  className,
  ...rest
}: MultiselectProps<T> & Stylable) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isInputUsed, setIsInputUsed] = useState(false);

  const { selectName, multiselectName, multiselectSelector } = useMemo(
    () => getElementsNames(name),
    [name],
  );

  useLayoutEffect(() => {
    if (!isInputUsed || disabled) return;

    const currentRef = containerRef.current;
    const multiselectElement = currentRef?.querySelector<HTMLElement>(multiselectSelector);

    const listener = () => {
      setIsInputUsed(false);
    };

    multiselectElement?.focus();
    multiselectElement?.addEventListener('blur', listener, { once: true });

    return () =>
      currentRef
        ?.querySelector<HTMLElement>(multiselectSelector)
        ?.removeEventListener('blur', listener);
  }, [isInputUsed, multiselectSelector, disabled]);

  const onChange = useCallback(
    (
      e: ChangeEvent<{
        name?: string;
        value: T[];
      }>,
    ) => {
      onSelectedItemsChange(e.target.value);
    },
    [onSelectedItemsChange],
  );

  const onSelectClick = useCallback(() => {
    if (!disabled) {
      setIsInputUsed(true);
    }
  }, [disabled]);

  return (
    <div ref={containerRef} className={className}>
      {isInputUsed ? (
        <ElementalMultiselect
          name={multiselectName}
          onChange={onChange}
          filterItems={filterItems}
          {...rest}
        />
      ) : (
        <Select
          multiselect
          displayValue={displayValue}
          name={selectName}
          onClick={onSelectClick}
          {...rest}
        />
      )}
    </div>
  );
};

export const Multiselect = memo(CustomMultiselect, (prev, next) =>
  isEqual(prev, next),
) as typeof CustomMultiselect;
