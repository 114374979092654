import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Menu, IconLanguage } from '@fortum/elemental-ui';
import { SupportedLanguage, languagesNames } from '@common/languages';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface LanguageWidgetProps {
  languages: SupportedLanguage[];
  selected: SupportedLanguage;
  onLanguageChange: (lang: SupportedLanguage) => void;
}

export const LanguageWidget: FC<LanguageWidgetProps> = ({
  onLanguageChange,
  languages,
  selected,
}) => {
  const { i18n } = useTranslation<Namespace>('mainLayout');

  const onLangSelect = useCallback(
    (lang: SupportedLanguage | SyntheticEvent<HTMLElement, Event>) => {
      if (typeof lang !== 'string') {
        return;
      }

      onLanguageChange(lang);
    },
    [],
  );

  const items = useMemo(
    () => languages.map(lang => ({ value: lang, name: languagesNames[lang] })),
    [i18n.language],
  );

  return (
    <Menu
      id="language-widget"
      icon={<IconLanguage />}
      onSelect={onLangSelect}
      items={items}
      label={selected.toUpperCase()}
      hideExpandIcon
      selected={selected}
    />
  );
};
