import {
  ContentText,
  fontWeights,
  IconChevronUp,
  IconChevronDown,
  IconProps,
  ContentTextProps,
} from '@fortum/elemental-ui';
import { FC, memo } from 'react';
import { Header } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface OrderTileHeaderProps {
  detailsOpen: boolean;
  orderIndex: number;
  onClick: () => void;
}

const headerTextStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};

const iconStyles: IconProps = {
  size: 24,
};

export const OrderTileHeader: FC<OrderTileHeaderProps> = memo(
  ({ onClick, detailsOpen, orderIndex }) => {
    const { t } = useTranslation<Namespace>('orderCreation');

    const headerText = t('orderTileHeader', { orderIndex: orderIndex + 1 });

    return (
      <Header onClick={onClick} data-testid="order-tile-header">
        <ContentText {...headerTextStyles}>{headerText}</ContentText>

        {detailsOpen ? (
          <IconChevronUp {...iconStyles} data-testid="icon-chevron-up" />
        ) : (
          <IconChevronDown {...iconStyles} data-testid="icon-chevron-down" />
        )}
      </Header>
    );
  },
);

OrderTileHeader.displayName = 'OrderTileHeader';
