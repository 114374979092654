import { LIMITED_LAYOUT_MAX_WIDTH_PX } from '@components/layout/styles';
import {
  BoxProps,
  BoxPropValue,
  colors,
  ContentTextProps,
  CssValue,
  fontSizes,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import { mapHeight } from './components/style';

export const COL_HEIGHT: CssValue = '320px';
const SECTION_MB: BoxPropValue = '48px';

export const pageContainerStyles: BoxProps = {
  backgroundColor: colors.snowWhite,
  flex: 1,
};

export const titleContainerStyles: BoxProps = {
  paddingHorizontal: { xs: spacing.xxs, m: spacing.xs },
  backgroundColor: colors.lightGrey,
  height: '91px',
  maxHeight: '91px',
  display: 'flex',
  alignItems: 'center',
};

export const pageTitleProps: ContentTextProps = {
  fontSize: fontSizes.xl,
};

export const pageContentContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: `calc(${LIMITED_LAYOUT_MAX_WIDTH_PX} - var(--app-navigation-width))`,
};

export const descriptionStyles = {
  marginHorizontal: { xs: spacing.xxs, m: spacing.xs },
  marginTop: spacing.xs,
};

export const gridLayoutStyles: BoxProps = {
  marginHorizontal: { xs: spacing.xxs, m: spacing.xs },
  maxWidth: { m: `calc(100% - 2 * ${spacing.xs})`, xs: `calc(100% - 2 * ${spacing.xxs})` },
  paddingHorizontal: { xs: 0 },
  marginTop: spacing.xs,
  marginBottom: spacing.s,
};

export const titleStyles: ContentTextProps = {
  fontSize: fontSizes.m,
  fontWeight: fontWeights.medium,
  marginBottom: spacing.xxxs,
};

export const infoTitleStyles: ContentTextProps = {
  ...titleStyles,
  paddingTop: spacing.m,
};

export const quickAccessStyles: BoxProps = {
  marginBottom: SECTION_MB,
};

export const mapLayoutStyles: BoxProps = {
  height: mapHeight,
  maxHeight: mapHeight,
  marginBottom: SECTION_MB,
};
