import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import {
  Box,
  Col,
  ContentText,
  ForwardLink,
  IconConstructionWorker,
  IconCustomerService,
  IconLinkedin,
  Row,
  RowProps,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui';
import { getEnvProperty } from '@utils/general';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ContactUsTileContainer,
  CustomerServiceIconRoundedContainer,
  ETrainingContainer,
  LeftColumnContainer,
  NonWrappingForwardLink,
  SocialMediaContainer,
} from './styles';

export const InfoSection: FC<RowProps> = props => {
  const navigate = useNavigate();
  const { t } = useTranslation<Namespace[]>(['home', 'mainLayout']);

  const openLinkedIn = useCallback(() => {
    const linkedInUrl = getEnvProperty('REACT_APP_LINKEDIN_URL');

    if (linkedInUrl) window.open(linkedInUrl, '_blank');
  }, []);

  const openETraining = useCallback(() => {
    const eTrainingUrl = getEnvProperty('REACT_APP_ETRAINING_URL');

    if (eTrainingUrl) window.open(eTrainingUrl, '_blank');
  }, []);

  return (
    <Row {...props}>
      <Col xl={7} mb={{ xl: 0, xs: spacing.xxs }}>
        <LeftColumnContainer>
          <ETrainingContainer>
            <IconConstructionWorker size={47} marginRight={spacing.xxs} color={colors.oceanGreen} />

            <ContentText fontSize={fontSizes.s} mr={spacing.xxs}>
              {t('info.etraning.desc')}
            </ContentText>
            <NonWrappingForwardLink
              onClick={openETraining}
              color={colors.oceanGreen}
              id="e-training-forward-link"
              marginLeft="auto"
            >
              {t('home:info.etraning.linkDesc')}
            </NonWrappingForwardLink>
          </ETrainingContainer>

          <SocialMediaContainer onClick={openLinkedIn} id="social-media-container">
            <IconLinkedin size={40} marginRight={spacing.xxs} color={colors.inkGrey} />

            <ContentText fontSize={fontSizes.s} mr={spacing.xxs}>
              {t('home:info.socialMedia.additionalText')}
            </ContentText>

            <NonWrappingForwardLink
              onClick={openLinkedIn}
              color={colors.oceanGreen}
              id="linked-in-forward-link"
              marginLeft="auto"
            >
              {t('home:info.socialMedia.mainText')}
            </NonWrappingForwardLink>
          </SocialMediaContainer>
        </LeftColumnContainer>
      </Col>

      <Col xl={5}>
        <ContactUsTileContainer>
          <Box display="flex" alignItems="center" mb={spacing.xxxs}>
            <CustomerServiceIconRoundedContainer>
              <IconCustomerService size={38} color={colors.turquoiseGreen} />
            </CustomerServiceIconRoundedContainer>

            <ContentText fontSize={fontSizes.xl} marginLeft={spacing.xxxs}>
              {t('home:info.contactUs.header')}
            </ContentText>
          </Box>

          <ContentText fontSize={fontSizes.s} mb={spacing.xs} textAlign="center">
            {t('home:info.contactUs.desc')}
          </ContentText>

          <ForwardLink
            color={colors.pineGreen}
            onClick={() => navigate(paths.contactUs)}
            id="contact-us-forward-link"
          >
            {t('mainLayout:contact')}
          </ForwardLink>
        </ContactUsTileContainer>
      </Col>
    </Row>
  );
};
