import styled, { css } from 'styled-components';
import {
  ErrorMessage,
  colors,
  fontSizes,
  spacing,
  InputFieldProps as ElementalInputFieldProps,
  CssValue,
} from '@fortum/elemental-ui';
import { borderTypeToColorMap, staticBorderStyles, verticalContainer } from '@components/styles';

const heightPerSize: { [key in NonNullable<ElementalInputFieldProps['size']>]: CssValue } = {
  s: '48px',
  m: '56px',
  l: '64px',
};

export const ReadOnlyIconContainer = styled.span<{
  $size: NonNullable<ElementalInputFieldProps['size']>;
}>`
  position: absolute;
  left: ${spacing.xxs};
  height: ${({ $size }) => heightPerSize[$size]};

  display: flex;
  align-items: center;

  color: ${borderTypeToColorMap['inactive']};
  background: transparent;
`;

export const IconContainer = styled(ReadOnlyIconContainer)<{
  $error: boolean;
}>`
  svg {
    transition: color 300ms;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }

  color: ${({ $error }) =>
    $error ? borderTypeToColorMap['error'] : borderTypeToColorMap['inactive']};
`;

export const ReadOnlyContainer = styled.div`
  ${verticalContainer};

  flex: 1;

  position: relative;
`;

export const Container = styled(ReadOnlyContainer)<{
  $error: boolean;
  $disabled: boolean;
}>`
  &:focus-within > label,
  &:focus-within > span {
    color: ${borderTypeToColorMap['active']};
  }

  &:hover > input {
    outline: none;
    border-color: ${({ $error, $disabled }) =>
      $disabled
        ? borderTypeToColorMap['inactive']
        : borderTypeToColorMap[$error ? 'error' : 'active']};
  }

  &:hover > span {
    color: ${({ $error, $disabled }) =>
      $disabled
        ? borderTypeToColorMap['inactive']
        : borderTypeToColorMap[$error ? 'error' : 'active']};
  }
`;

export const ReadOnlyInputField = styled.input<{
  $size: NonNullable<ElementalInputFieldProps['size']>;
  $withIcon: boolean;
}>`
  ${({ $size }) =>
    $size === 's'
      ? css`
          font-size: ${fontSizes.s.s};
          padding-block: 18px 5px;
        `
      : css`
          font-size: ${fontSizes.m.s};
          padding-block: 28px 11px;
        `};

  height: ${({ $size }) => heightPerSize[$size]};

  color: ${colors.inkGrey};
  text-align: start;

  padding-inline: ${({ $withIcon }) => ($withIcon ? '48px' : '14px')};

  box-sizing: border-box;

  border-color: transparent;

  &:enabled:focus {
    outline: none;
  }
`;

export const StyledInputField = styled(ReadOnlyInputField)<{
  $error: boolean;
  $size: ElementalInputFieldProps['size'];
}>`
  ${staticBorderStyles('2px')};

  border-color: ${({ $error }) =>
    $error ? borderTypeToColorMap['error'] : borderTypeToColorMap['inactive']};

  transition: border-color 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

  &::placeholder {
    color: ${borderTypeToColorMap['inactive']};
  }

  &:enabled:focus {
    border-color: ${borderTypeToColorMap['active']};
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  }
`;

export const InputLabel = styled.label<{
  $size: ElementalInputFieldProps['size'];
  $withIcon: boolean;
}>`
  --padding-left: ${({ $withIcon }) => ($withIcon ? '48px' : spacing.xxs)};
  --padding-right: ${spacing.xxs};

  color: ${colors.emperorGrey};
  line-height: 18px;
  font-size: ${({ $size }) => ($size === 's' ? fontSizes.xs.default : fontSizes.xs.s)};
  position: absolute;
  left: 0;
  top: 0;

  padding-top: ${({ $size }) => ($size === 's' ? '3px' : '9px')};
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  max-width: calc(100% - var(--padding-left) - var(--padding-right));

  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  div {
    font-size: ${fontSizes.m.s};
  }
`;
