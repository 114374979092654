import { Button, useToggle } from '@fortum/elemental-ui';
import { BookmarksRemovalModal } from './BookmarksRemovalModal';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import { Notification, useNotification } from '@components/Notification';
import { StyledSelect } from './styles';
import { BookmarksContext } from '@components/qlik/BookmarksProvider';
import { mapQBookmarksToSelectionItems } from '@utils/reports/bookmarks';

export const BookmarksManagement: FC = () => {
  const bookmarksContext = useContext(BookmarksContext);

  const { t, i18n } = useTranslation<Namespace[]>(['reports', 'errors']);
  const [modalOpened, toggleModalOpened] = useToggle(false);
  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();

  const [errorNotificationMessage, setErrorNotificationMessage] = useState<string>('');

  if (!bookmarksContext) {
    throw Error('Bookmarks context missing');
  }

  const bookmarksSelectItems = useMemo(
    () => mapQBookmarksToSelectionItems(bookmarksContext.bookmarksFetchingResult.qBookmarks),
    [bookmarksContext.bookmarksFetchingResult.qBookmarks],
  );

  const selectDisabled = useMemo(
    () =>
      bookmarksContext.bookmarksFetchingResult.isError ||
      bookmarksContext.bookmarksFetchingResult.isLoading,
    [
      bookmarksContext.bookmarksFetchingResult.isLoading,
      bookmarksContext.bookmarksFetchingResult.isError,
    ],
  );
  const bookmarksRemovalDisabled = useMemo(
    () =>
      bookmarksContext.bookmarksFetchingResult.isError ||
      bookmarksContext.bookmarksFetchingResult.isLoading ||
      bookmarksContext.bookmarksFetchingResult.qBookmarks.length === 0,
    [
      bookmarksContext.bookmarksFetchingResult.isError,
      bookmarksContext.bookmarksFetchingResult.isLoading,
      bookmarksContext.bookmarksFetchingResult.qBookmarks.length,
    ],
  );

  const onChange = useCallback(
    (
      e: ChangeEvent<{
        name?: string;
        value: string;
      }>,
    ) => {
      bookmarksContext.selectBookmark(e.currentTarget.value).catch(() => {
        setErrorNotificationMessage(t('errors:reports.bookmarks.failedToApply'));
        setDisplayErrorNotification();
      });
    },
    [bookmarksContext.selectBookmark, i18n.language],
  );

  /**
   * displayValue prop is used to re-render the Select component when new bookmark created
   * When creating a bookmark, selectedBookmark state is updated first, then the bookmarksSelectItems.
   * So when the newly created bookmark is set as selected, it cannot be found in the bookmarksSelectItems,
   * thus it is not displayed on the component correctly.
   * In order to have it working, the component re-render has to be forced and it can be done by changing the displayValue
   */
  const displayValue = useMemo(
    () => bookmarksSelectItems.find(item => item.value === bookmarksContext.selectedBookmark)?.name,
    [bookmarksSelectItems, bookmarksContext.selectedBookmark],
  );

  return (
    <>
      <StyledSelect
        disabled={selectDisabled}
        items={bookmarksSelectItems}
        name="bookmarks"
        maxWidth="240px"
        label={t('bookmarks.selection.label')}
        placeholder={t('reports:bookmarks.selection.placeholder')}
        onChange={onChange}
        selected={bookmarksContext.selectedBookmark}
        variant="condensed"
        showItems={4}
        error={bookmarksContext.bookmarksFetchingResult.isError}
        errorMessage={t('errors:reports.bookmarks.failedToLoad')}
        displayValue={displayValue}
      />

      <Button
        status="secondary"
        variant="condensed"
        disabled={bookmarksRemovalDisabled}
        onClick={toggleModalOpened}
      >
        {t('reports:bookmarks.manageBookmarks')}
      </Button>
      <BookmarksRemovalModal
        opened={modalOpened}
        onClose={toggleModalOpened}
        qBookmarks={bookmarksContext.bookmarksFetchingResult.qBookmarks}
        removeBookmark={bookmarksContext.removeBookmark}
      />

      <Notification
        type="error"
        id="form-error-notification"
        message={errorNotificationMessage}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="40rem"
      />
    </>
  );
};
