import i18n, { Namespace } from '@config/i18n';
import { ContractsFilterStateProvider } from '@contexts/ContractsFiltersContext';
import { ContentText, Button, IconAdd } from '@fortum/elemental-ui';
import { FC, useState, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationHeaderWithMarginBottom, OrdersCreationContext } from '../components';
import {
  NoServicesSelectedContainer,
  WideModal,
  headerTextStyles,
  instructionsTextStyles,
  shortDescriptionTextStyles,
} from './components/styles';
import { ServicesSelector } from './components/ServicesSelector';
import { ServicesTiles } from './components/ServicesTiles';
import { VerticalContainer } from '@components/styles';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { useNavigate } from 'react-router-dom';

export const OrdersCreation: FC = () => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalOpen = useCallback(() => setModalOpen(prev => !prev), []);

  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'common']);

  const { creationForms, initializeCreationForms } = useContext(OrdersCreationContext);

  const onContractChoose = useCallback((contractIdentifier: string) => {
    initializeCreationForms(contractIdentifier);
    setModalOpen(false);
  }, []);

  const openModal = useCallback(() => setModalOpen(true), []);

  const headerLinkProps = useMemo<PageNavigationHeaderProps['link']>(
    () => ({
      id: 'navigate-back-link',
      description: t('orderCreation:returnToPreviousPage'),
      onClick: () => navigate(-1),
    }),
    [i18n.language],
  );

  return (
    <>
      <NavigationHeaderWithMarginBottom
        link={headerLinkProps}
        title={t('orderCreation:pageHeader')}
      />

      {creationForms.length === 0 ? (
        <NoServicesSelectedContainer>
          <VerticalContainer>
            <ContentText {...headerTextStyles}>{t('wasteDetails:wasteServiceDetails')}</ContentText>
            <ContentText {...instructionsTextStyles}>
              {t('orderCreation:noServicesSelectedPage.instruction')}
            </ContentText>
          </VerticalContainer>

          <ContentText {...shortDescriptionTextStyles}>
            {t('orderCreation:noServicesSelectedPage.shortDescription')}
          </ContentText>

          <Button status="secondary" leftIcon={<IconAdd />} onClick={toggleModalOpen}>
            {t('common:chooseService')}
          </Button>
        </NoServicesSelectedContainer>
      ) : (
        <ServicesTiles onChooseOtherServiceClick={openModal} />
      )}

      <WideModal fullScreen="desktop" opened={modalOpen} onClose={toggleModalOpen}>
        <ContractsFilterStateProvider>
          <ServicesSelector onClose={toggleModalOpen} onContractChoose={onContractChoose} />
        </ContractsFilterStateProvider>
      </WideModal>
    </>
  );
};
