import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo } from 'react';
import { Detail, ServiceType } from '@models/service';
import { DetailsTable } from './styles';
import { ContentText, RowKey, TableColumnProps, fontSizes } from '@fortum/elemental-ui';
import { WasteType } from '@config/services';
import { useLocation, useNavigate } from 'react-router-dom';
import { Namespace } from '@config/i18n';
import { translate } from '@utils/internationalization';
import { compact } from 'lodash';
import { createContractIdentifier } from '@utils/routes';

interface Props {
  service: ServiceType;
}

export const ContractsTable: FC<Props> = memo(({ service: { contracts, type } }) => {
  const { t, i18n } = useTranslation<Namespace>('services');
  const navigate = useNavigate();
  const location = useLocation();
  const collator = useMemo(() => new Intl.Collator(i18n.language), [i18n.language]);

  const isEquipmentSales =
    type === WasteType.EQUIPMENT_SALES_SERVICE || type === WasteType.EQUIPMENT_SALES;

  const columns = useMemo<TableColumnProps<Detail, RowKey>[]>(
    () =>
      compact([
        isEquipmentSales
          ? undefined
          : {
              getCellData: (row: { wasteDescription: string }) => row.wasteDescription,
              key: 'wasteType',
              name: t('wasteType'),
              sortable: true,
            },
        {
          getCellData: (row: { containerType: string }) => row.containerType,
          key: 'equipmentType',
          name: t('equipmentType'),
          sortable: true,
        },
        {
          getCellData: (row: { transportType: string }) =>
            translate(row.transportType, 'domain', 'transportType'),
          key: 'transportBy',
          name: t('transportBy'),
          sortable: true,
        },
        {
          getCellData: (row: { cycleType: string }) =>
            translate(row.cycleType, 'domain', 'cycleType'),
          key: 'recurring',
          name: t('recurring'),
          sortable: true,
        },
        {
          getCellData: (row: { queue: number }) => row.queue,
          key: 'queue',
          name: t('queue'),
          sortable: true,
          renderCell: (_, row) => (
            <ContentText textAlign="left" fontSize={fontSizes.s}>
              {row.queue}
            </ContentText>
          ),
        },

        {
          getCellData: (row: { contractNo: string }) => row.contractNo,
          key: 'contractNo',
          name: t('contractNo'),
          sortable: true,
        },
      ]),
    [contracts, type],
  );

  const compareContracts = (a: Detail, b: Detail) => {
    return collator.compare(
      isEquipmentSales ? a.containerType : a.wasteDescription,
      isEquipmentSales ? b.containerType : b.wasteDescription,
    );
  };

  const rows = contracts.sort(compareContracts);

  const onRowClick = (contract: Detail) => {
    const contractIdentifier = createContractIdentifier(contract.dataSource, contract.contractNo);

    navigate(contractIdentifier, {
      state: { navigateBackPath: `${location.pathname}${location.search}` },
    });
  };

  return (
    <DetailsTable
      id={`table-${type}`}
      size="small"
      rows={rows}
      columns={columns}
      rowStyle="zebra"
      onRowClick={onRowClick}
      getRowKey={row => row.contractNo}
    />
  );
});

ContractsTable.displayName = 'ContractsTable';
