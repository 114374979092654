import { FiltersAccordion } from '@components/FiltersAccordion';
import { SelectChangeEvent } from '@components/types';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { Box, ChipItem, spacing } from '@fortum/elemental-ui';
import { chipsDisplayOrder } from '@routes/orders/config';
import { filterSelectItems } from '@utils/dataOperations/multiselect';
import { debounce } from 'lodash';
import { ChangeEvent, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { OrdersFiltersContext } from '../OrdersFiltersContextProvider';
import { useFiltersDisplayValues } from '../useFiltersDisplayValue';
import { OrdersFiltersHandlerReturnType } from '../useOrdersFiltersHandler';
import { FiltersAvailabilityValidator, getSelectedFiltersAmount } from '../utils';
import {
  FILTER_MAX_WIDTH,
  FiltersContainer,
  SearchWithBorder,
  ShrinkableMultiselect,
  ShrinkableSelect,
} from './styles';
import { deconstructChipValue, isKeyOfSetters, mapSelectedFiltersToChips } from './utils';

type MainLayoutSelectItemsKeys = Pick<
  OrdersFiltersHandlerReturnType['selectItems'],
  'wasteDescription' | 'orderStatus' | 'timePeriod'
>;

const FiltersComponent = () => {
  const { t } = useTranslation<Namespace[]>(['orders', 'common', 'errors']);
  const filtersContext = useContext(OrdersFiltersContext);
  const [temporarySearch, setTemporarySearch] = useState<string>('');
  const location = useLocation();

  if (!filtersContext) {
    throw Error('Missing orders filters context');
  }

  useEffect(() => {
    const path = window.location.href.split('?')[0];
    window.history.replaceState(null, '', path);

    if (location.state) return filtersContext.setters.search(location.state?.contractNo);
  }, [location.state]);

  const displayValues = useFiltersDisplayValues(
    filtersContext.selectedFiltersValues,
    filtersContext.selectItems,
  );

  useEffect(() => {
    setTemporarySearch(filtersContext.selectedFiltersValues.search);
  }, [filtersContext.selectedFiltersValues.search]);

  const debouncedLogEvent = useMemo(
    () =>
      debounce(
        (newValue: string) => logEvent('searchUsedInOrders', { searchedValue: newValue }),
        400,
        { trailing: true, maxWait: 1000 },
      ),
    [],
  );

  const setSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTemporarySearch(e.target.value);

    if (e.target.value.trim().length > 2 || !e.target.value) {
      filtersContext.setters.search(e.target.value);

      if (e.target.value.trim().length > 0) {
        debouncedLogEvent(e.target.value);
      }
    }
  }, []);

  const setTimePeriod = useCallback(
    (e: ChangeEvent<SelectChangeEvent<string>>) =>
      filtersContext.setters.timePeriod(e.target.value),
    [],
  );

  const chipsItems = useMemo<ChipItem<string>[]>(() => {
    if (getSelectedFiltersAmount(filtersContext.selectedFiltersValues, true) === 0) {
      return [];
    }
    return mapSelectedFiltersToChips(
      filtersContext.selectedFiltersValues,
      filtersContext.selectItems,
      chipsDisplayOrder,
    );
  }, [filtersContext.selectedFiltersValues, filtersContext.selectItems]);

  const removeFilter = useCallback(
    (item: ChipItem<string>) => {
      const { key, selectValue } = deconstructChipValue(item.value);

      if (!isKeyOfSetters(key, filtersContext.setters)) {
        return;
      }

      if (key === 'search' || key === 'timePeriod') {
        filtersContext.setters[key]('');
        return;
      }

      if (key === 'orderStatus') {
        filtersContext.setters.orderStatus(
          filtersContext.selectedFiltersValues.orderStatus.filter(status => status !== selectValue),
        );
        return;
      }

      const newValues = filtersContext.selectedFiltersValues[key].filter(
        selectedFilterValue => selectedFilterValue !== selectValue,
      );

      filtersContext.setters[key](newValues);
    },
    [filtersContext.selectedFiltersValues],
  );

  const shouldBeDisabled = useMemo<{
    [key in keyof MainLayoutSelectItemsKeys]: boolean;
  }>(() => {
    const validator = new FiltersAvailabilityValidator(
      filtersContext.selectItems,
      filtersContext.isLoading,
      filtersContext.isError,
      filtersContext.filtersPrecedence,
    );

    return {
      orderStatus: validator.filterDisabled('orderStatus'),
      wasteDescription: validator.filterDisabled('wasteDescription'),
      timePeriod: filtersContext.selectItems.timePeriod.length === 0,
    };
  }, [
    filtersContext.selectItems,
    filtersContext.isLoading,
    filtersContext.filtersPrecedence,
    filtersContext.isError,
  ]);

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxs}>
      <FiltersContainer>
        <ShrinkableSelect
          name="time-period"
          items={filtersContext.selectItems.timePeriod}
          selected={filtersContext.selectedFiltersValues.timePeriod}
          label={t('orders:filters.timePeriod.label')}
          variant="condensed"
          onChange={setTimePeriod}
          borderStyle="full"
          placeholder={t('common:all')}
          disabled={shouldBeDisabled.timePeriod}
          maxWidth={FILTER_MAX_WIDTH}
          error={filtersContext.isError}
          errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
        />

        <ShrinkableMultiselect
          selected={filtersContext.selectedFiltersValues.orderStatus}
          items={filtersContext.selectItems.orderStatus}
          onSelectedItemsChange={filtersContext.setters.orderStatus}
          label={t('orders:filters.orderStatus.label')}
          name="order-status"
          variant="condensed"
          borderStyle="full"
          disabled={shouldBeDisabled.orderStatus}
          displayValue={displayValues.orderStatusDisplayValue}
          placeholder={t('common:all')}
          maxWidth={FILTER_MAX_WIDTH}
          filterItems={filterSelectItems}
          error={filtersContext.isError}
          errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
        />
        <ShrinkableMultiselect
          selected={filtersContext.selectedFiltersValues.wasteDescription}
          items={filtersContext.selectItems.wasteDescription}
          onSelectedItemsChange={filtersContext.setters.wasteDescription}
          label={t('orders:filters.wasteDesc.label')}
          name="waste-desc-on"
          variant="condensed"
          borderStyle="full"
          disabled={shouldBeDisabled.wasteDescription}
          displayValue={displayValues.wasteDescriptionDisplayValue}
          placeholder={t('common:all')}
          maxWidth={FILTER_MAX_WIDTH}
          filterItems={filterSelectItems}
          error={filtersContext.isError}
          errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
        />
        <SearchWithBorder
          name="search"
          label={t('orders:search.label')}
          variant="condensed"
          onChange={setSearch}
          value={temporarySearch}
          borderStyle="full"
          maxWidth={FILTER_MAX_WIDTH}
          error={filtersContext.isError}
          errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
        />
      </FiltersContainer>

      {chipsItems.length > 0 && (
        <FiltersAccordion
          chipsItems={chipsItems}
          clearAllFilters={filtersContext.reset}
          removeFilter={removeFilter}
          accordionOpenedByDefault={location.state}
        />
      )}
    </Box>
  );
};

export const MainFiltersLayout = memo(FiltersComponent);
