import { staticBorderStyles, verticalContainer } from '@components/styles';
import { breakpoints, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const TileContainer = styled.div`
  ${verticalContainer};
  ${staticBorderStyles()};

  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: ${spacing.l} ${spacing.l} 0;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    padding: ${spacing.xs};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0px;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.xxs};
`;
