import { Box } from '@fortum/elemental-ui';
import { FC, useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  ContactFooter,
  OrdersCreationContext,
  OutletContainer,
  containerStyles,
} from './components';

export const OrdersCreationLayout: FC = () => {
  const location = useLocation();

  const { initializeCreationForms } = useContext(OrdersCreationContext);

  useEffect(() => {
    if (location?.state?.contractIdentifier) {
      initializeCreationForms(location?.state?.contractIdentifier);
    }
  }, []);

  return (
    <Box {...containerStyles}>
      <OutletContainer>
        <Outlet />
      </OutletContainer>

      <ContactFooter />
    </Box>
  );
};
