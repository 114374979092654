import { Code } from './common';
import { ResponseWithPagination } from './general';

export type OrdersOverviewResponse = ResponseWithPagination<Order[]>;
export interface Order {
  orderHandlingStatus: string;
  orderType: string;
  executionDate: string;
  orderNo: string;
  contractNo: string;
  tourNo: string;
  wasteDescription: string;
  transportType: string;
  address: string;
  city: string;
  locationSpecificName: string;
  exactNameTranslations: string;
  equipmentTranslations: string;
  businessPartnerCode: string;
  businessPartnerName: string;
}

export const HandlingStatusesGroups = [
  'WAITING_IN_LINE',
  'IN_PROCESS',
  'TRANSPORTATION_IS_PLANNED',
  'IN_TRANSPORTATION',
  'RECEIVED_DELIVERED',
  'INCOMPLETE',
] as const;

export type HandlingStatusGroup = (typeof HandlingStatusesGroups)[number];

export const HandlingStatusMap: { [key in HandlingStatusGroup]: string[] } = {
  WAITING_IN_LINE: ['O01_QUEUE'],
  IN_PROCESS: ['O20_EXECUT', 'O21_PLAN', 'O22_VENDOR'],
  TRANSPORTATION_IS_PLANNED: ['O25_TRANS'],
  IN_TRANSPORTATION: ['O27_PICKED', 'O29_DELIVE', 'O48_SENT'],
  RECEIVED_DELIVERED: [
    'O30_ARRIVE',
    'O40_RECEPT',
    'O50_DONE',
    'O51_DROPPE',
    'O55_READY',
    'O60_WAIT',
    'O65_ERROR',
  ],
  INCOMPLETE: ['O15_MISSIN', 'O61_EQUIP', 'O62_DRIVER', 'O63_MISSIN'],
};

interface OrderEquipmentDetails {
  equipmentType: string;
  estimation: boolean;
  ownership: string;
  quantity: string | null;
  serviceType: string;
}

interface InvoicingLocation {
  companyName: string;
  address: string;
  city: string;
  specificName: string | null;
  invoicingEmail: string | null;
}

interface OrderInvoicingDetails {
  customerReference: string | null;
  eInvoiceAddress: string;
  invoicingContactPerson: string | null;
  orderReference: string | null;
  invoicingLocation: InvoicingLocation;
}

interface OrderGeneralInfo {
  contractNo: string;
  dataSource: string;
  creationDate: string;
  customerOrderNo: string;
  executionDate: string;
  orderNo: string;
  orderType: string;
  tourNo: string;
}

interface CompanyLocation {
  companyName: string;
  address: string;
}

interface ContactDetails {
  name: string;
  phone: string;
  email: string;
}

interface OrderTransportDetails {
  company: string;
  contactDetails: ContactDetails | null;
  instructionsForDriver: string | null;
  loadingLocation: CompanyLocation | null;
  receivingLocation: CompanyLocation | null;
  transportType: string;
}

export interface OrderWasteDetails {
  adrLabels: string[];
  adrWaste21355: boolean | null;
  animalicByProduct: boolean | null;
  environmentalHazardous: boolean | null;
  estimation: boolean;
  ewcCode: Code;
  hazardous: boolean | null;
  hazMatTypeDescription: string | null;
  hpCodes: Code[] | null;
  packagingGroup: string | null;
  popCompounds: string | null;
  quantity: string | null;
  unNumber: string | null;
  wasteConsistency: string[];
  wasteDescription: string;
  wasteOilType: string | null;
  rdCode: string;
}

export interface OrderDetails {
  companySiteDetails: {
    address: string;
    company: string;
  };
  equipmentDetails: OrderEquipmentDetails;
  generalInfo: OrderGeneralInfo;
  invoicingDetails: OrderInvoicingDetails;
  orderStatus: {
    description: string;
    lastUpdated: string;
  };
  transportDetails: OrderTransportDetails;
  wasteDetails: OrderWasteDetails | null;
}
export const orderTypes = ['COLLECTION', 'DELIVERY', 'EMPTYING'] as const;

export type OrderType = (typeof orderTypes)[number];
