// TODO: add Norvegian to languages and uncomment when Norvegian translations will be finished

export const languages = ['en', 'fi', 'sv'] as const;
export const customerServiceCountryCodes = ['fi', 'sv', 'nb'] as const;

export type SupportedLanguage = (typeof languages)[number];

export const fallbackLang: SupportedLanguage = 'en';

export const languagesNames: { [key in SupportedLanguage]: string } = {
  en: 'English',
  fi: 'Suomi',
  sv: 'Svenska',
  // nb: 'Norsk',
};

export const isSupportedLanguage = (lang: string): lang is SupportedLanguage =>
  languages.includes(lang as SupportedLanguage);
