import { FC, useContext, useMemo } from 'react';
import {
  StyledTable,
  mediumCellContainerStyle,
  narrowCellContainerStyle,
  wideCellContainerStyle,
} from './styles';
import { SectionContainer, sectionHeaderStyles } from '../styles';
import { ContentText, TableColumnProps, tableColumn } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { OrdersCreationContext } from '@routes/ordersCreation/components';
import { CompanyDetails } from '@components/CompanyDetails';
import { displayedValue } from '@utils/dataOperations';
import { OrderSummary } from 'src/types/ordersCreation';

const renderCompanyCell = (_data: string, row: OrderSummary) =>
  row.serviceSummary?.company ? <CompanyDetails company={row.serviceSummary?.company} /> : null;

//TODO: add sorting to the table when multiple orders supported
export const SummaryTable: FC = () => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'services']);
  const { creationForms } = useContext(OrdersCreationContext);

  const forms = useMemo<OrderSummary[]>(
    () =>
      creationForms.flatMap(creationForm =>
        creationForm.forms.map(orderForm => ({
          serviceSummary: creationForm.serviceSummary,
          formIdentifier: orderForm.formIdentifier,
          ...orderForm.values,
        })),
      ),
    [creationForms],
  );

  const columns = useMemo<TableColumnProps<OrderSummary, string>[]>(
    () => [
      tableColumn<OrderSummary, string>(row => row.serviceSummary?.company?.customer ?? '')({
        key: 'summary-company',
        name: t('wasteDetails:company'),
        cellContainerStyle: wideCellContainerStyle,

        renderCell: renderCompanyCell,
      }),
      tableColumn<OrderSummary, string>(row =>
        displayedValue(row.serviceSummary?.wasteDescription),
      )({
        key: 'summary-waste-desc',
        name: t('wasteDetails:wasteDescription'),
        cellContainerStyle: mediumCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row => row.estimatedWasteQuantity)({
        key: 'summary-estimated-waste-qty',
        name: t('orderCreation:summaryPage.table.estimatedWasteQty'),
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row =>
        displayedValue(row.serviceSummary?.equipmentDescription),
      )({
        key: 'summary-eq-type',
        name: t('wasteDetails:equipmentType'),
        cellContainerStyle: mediumCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row => row.equipmentQuantity)({
        key: 'summary-equipment-qty',
        name: t('orderCreation:summaryPage.table.estimatedQty'),
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row => t(`orderCreation:form.orderType.${row.orderType}`))({
        key: 'summary-order-type',
        name: t('orderDetails:orderTypeFieldLabel'),
        cellContainerStyle: mediumCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row => row.serviceSummary?.contractNo ?? '')({
        key: 'summary-contract-no',
        name: t('wasteDetails:contractNo'),
        cellContainerStyle: mediumCellContainerStyle,
      }),
      tableColumn<OrderSummary, string>(row => row.comments)({
        key: 'summary-comment',
        name: t('orderCreation:summaryPage.table.additionalComment'),
        cellContainerStyle: wideCellContainerStyle,
      }),
    ],
    [forms, t],
  );

  return (
    <SectionContainer>
      <ContentText {...sectionHeaderStyles}>{t('summaryTableSection.header')}</ContentText>

      <StyledTable
        columns={columns}
        rows={forms}
        rowStyle="zebra"
        getRowKey={row => row.formIdentifier}
        size="small"
      />
    </SectionContainer>
  );
};
