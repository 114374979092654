import { useCallback, useContext } from 'react';
import { QlikApp, QlikVisualization } from '../types';
import { QlikAppContext } from '../QlikAppProvider';

const getVisualization = async (app: QlikApp | null, objectId: string) => {
  if (!app?.visualization) {
    throw Error('Application not found');
  }
  return await app.visualization.get(objectId);
};

export const useDataExport = () => {
  const { app } = useContext(QlikAppContext);

  const exportData = useCallback(
    async (objectId: string, options?: Parameters<QlikVisualization['exportData']>[0]) => {
      const qVisualization = await getVisualization(app, objectId);

      if (!qVisualization) {
        throw Error('Visualization not found');
      }
      const url = await qVisualization.exportData(options ?? { format: 'OOXML' });
      window.open(url, '_blank');
    },
    [app],
  );

  return { exportData };
};
