import { EquipmentServiceType } from '@models/contract';
import { OrderType } from '@models/orders';

export const equipmentServiceTypesToOrderTypesMap: { [key in EquipmentServiceType]: OrderType[] } =
  {
    PICK_UP: ['COLLECTION'],
    SUBMISSION: ['DELIVERY'],
    CLEARANCE: ['EMPTYING'],
    EXCHANGE: ['COLLECTION', 'DELIVERY'],
  };
