export const namespaces = [
  'common',
  'mainLayout',
  'errors',
  'contactUs',
  'services',
  'wasteDetails',
  'businessPartners',
  'reports',
  'user',
  'termsAndCondition',
  'domain',
  'home',
  'orders',
  'orderDetails',
  'orderCreation',
] as const;

export type Namespace = (typeof namespaces)[number];
