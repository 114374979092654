import styled from 'styled-components';
import {
  ContentText,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  px2rem,
  spacing,
} from '@fortum/elemental-ui';

export const ContactFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.lightGrey};
  height: ${px2rem(104)};
  gap: ${spacing.s};
  padding: ${spacing.xs} ${spacing.xl};
  @media ${`(max-width: ${breakpoints.xl}px)`} {
    display: flex;
    flex-direction: column;
    height: ${px2rem(179)};
    gap: ${spacing.xs};
  }
  @media ${`(max-width: 1920px)`} {
    padding: ${spacing.xs} calc((100% - 1052px) / 2);
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxxs};
  width: ${px2rem(326)};
  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    align-items: center;
  }
`;

export const ContactInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.s};
  @media ${`(max-width: ${breakpoints.xl}px)`} {
    gap: ${spacing.xxs};
    padding: 0 ${spacing.xl};
  }
`;

export const CustomerServiceIconRoundedContainer = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${colors.oceanGreen};
`;

export const ContactEmail = styled(ContentText)`
  word-wrap: wrap;
  word-break: break-all;
  font-size: ${fontSizes.l};
  font-weight: ${fontWeights.medium};
  color: ${colors.oceanGreen};
`;
