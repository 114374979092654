import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { displayedValue } from '@utils/dataOperations';
import { GeneralInfo } from '@models/contract';
import { StyledDescriptionListItem } from '@components/styles';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { SiteAddress } from './SiteAddress';

interface CompanySiteDetailsTileProps {
  customer: GeneralInfo['customer'];
  location: GeneralInfo['location'];
  wrapSiteAddress?: boolean;
}

export const CompanySiteDetailsTile: FC<CompanySiteDetailsTileProps> = ({
  customer,
  location,
  wrapSiteAddress = false,
}) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  return (
    <Tile header={t('companySiteDetails')} data-testid={getTileTestId('company-site-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('company')} data-testid={getListItemTestId('company')}>
          {displayedValue(customer)}
        </StyledDescriptionListItem>
        <StyledDescriptionListItem label={t('address')} data-testid={getListItemTestId('address')}>
          <SiteAddress location={location} wrap={wrapSiteAddress} />
        </StyledDescriptionListItem>
      </DescriptionList>
    </Tile>
  );
};
