import styled from 'styled-components';
import { HorizontalContainer } from '../styles';
import { QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL } from '@components/qlik/components/QlikSelectionObject';
import { verticalContainer } from '@components/styles';

export const BottomContainer = styled(HorizontalContainer)`
  flex: 1;
`;

export const TopContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: calc(${`${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`} + var(--column-gap));

  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
`;

export const LeftSideContainer = styled.div`
  ${verticalContainer};

  row-gap: calc(6 * var(--row-gap));
`;
