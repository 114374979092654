export const QueryKeys = {
  userInfo: 'user-info',
  services: 'services',
  servicesAdmin: 'services-admin',
  serviceFilters: 'service-filters',
  serviceFiltersAdmin: 'service-filters-admin',
  servicesDetails: 'services-details',
  contractDetails: 'contract-details',
  contractLocations: 'contract-locations',
  qlikAuth: 'qlik-auth',
  businessPartners: 'business-partners',
  hazardSummaryInWasteServices: 'hazard-summary-in-waste-services',
  ongoingOrdersSummary: 'ongoing-orders-summary',
  locations: 'map-locations',
  locationsAdmin: 'map-locations-admin',
  orderFilters: 'order-filters',
  orderFiltersAdmin: 'order-filters-admin',
  orders: 'orders',
  ordersAdmin: 'orders-admin',
  orderDetails: 'order-details',
  contractsSites: 'contracts-sites',
  contractsSitesAdmin: 'contracts-sites-admin',
  contractsByLocations: 'contracts-by-locations',
  contactsByBusinessPartner: 'contacts-by-business-partner',
  passwordChangeUrl: 'password-change-url',
};
