import { QueryKeys } from '@common/query';
import { StorageUtils } from '@common/storage';
import { setUpConnection } from '@data/api/qlik';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from './types';

export const qlikSessionQuery = (idToken: string): QueryOptions<boolean> => ({
  queryKey: [QueryKeys.qlikAuth, idToken],
  queryFn: setUpConnection,
  refetchOnWindowFocus: false,
});

export const useQlikSession = () => {
  const idToken = sessionStorage.getItem(StorageUtils.sessionKeys.idToken);
  if (!idToken) throw Error('Id token not found');
  return useQuery<boolean, Error>(qlikSessionQuery(idToken));
};
