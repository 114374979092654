import { horizontalContainer } from '@components/styles';
import { colors, Link, Loader, px2rem } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const LIMITED_LAYOUT_MAX_WIDTH_PX = '1920px';

export const ApplicationContainer = styled.div`
  --app-header-height: ${px2rem(64)};
  --app-navigation-width: ${px2rem(204)};
  --page-header-height: ${px2rem(91)};

  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.snowWhite};
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

export const ApplicationInnerContainer = styled.div`
  ${horizontalContainer}

  flex:1;

  & > div:nth-child(2) {
    max-width: calc(100% - var(--app-navigation-width));
  }
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  justify-content: space-between;
`;

export const OutletContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.lightGrey};
`;

export const CenteredLoader = styled(Loader)`
  margin: auto auto;
`;

export const SuspenseLoader = styled(Loader)`
  & {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const NonWrappingLink = styled(Link)`
  white-space: nowrap;
`;

export const NonWrappingLinkLowerCased = styled(NonWrappingLink)`
  text-transform: lowercase;
`;
