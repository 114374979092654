import { FC } from 'react';
import {
  colors,
  ContentText,
  Float,
  IconCheck,
  IconError,
  Notification as ElementalNotification,
  NotificationProps as ElementalNotificationProps,
} from '@fortum/elemental-ui';

export type NotificationType = 'error' | 'success';
type Position =
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'topCenter'
  | 'bottomCenter';
interface NotificationProps {
  type: NotificationType;
  message?: string;
  position?: Position;
}

export const Notification: FC<NotificationProps & ElementalNotificationProps> = ({
  type,
  message,
  position = 'bottomCenter',
  id,
  ...props
}) => (
  <Float opened={props.opened || false} anchorPos={position} id={id}>
    <ElementalNotification
      {...props}
      iconButtonProps={{ 'aria-label': 'close notification' }}
      backgroundColor={type === 'error' ? colors.hazeRedOrange : colors.forestGreen}
      icon={type === 'error' ? IconError : IconCheck}
    >
      <ContentText>{message}</ContentText>
    </ElementalNotification>
  </Float>
);
