import {
  BoxProps,
  ContentTextProps,
  LinkProps,
  colors,
  fontSizes,
  fontWeights,
} from '@fortum/elemental-ui';

const HEADER_HEIGHT_PX = '91px';

export const containerProps: BoxProps = {
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  justifyContent: 'center',
  height: HEADER_HEIGHT_PX,
  maxHeight: HEADER_HEIGHT_PX,
};

export const titleProps: ContentTextProps = {
  color: colors.inkGrey,
  fontSize: fontSizes.xl,
  fontWeight: fontWeights.regular,
};

export const linkStyles: LinkProps = {
  color: colors.oceanGreen,
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSizes.xs,
  fontWeight: fontWeights.medium,
  noUnderline: true,
};
