import { borderTypeToColorMap, staticBorderStyles, verticalContainer } from '@components/styles';
import {
  ContentText,
  TextareaProps as ElementalTextareaProps,
  ErrorMessage,
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@fortum/elemental-ui';
import styled, { css } from 'styled-components';

export const GeneralContainer = styled.div`
  ${verticalContainer};
  flex: 1;
  position: relative;
`;

export const ReadOnlyContainer = styled.div`
  ${verticalContainer};
  flex: 1;
  position: relative;
  padding: 28px 0px 14px 14px;
  overflow: auto;

  border: transparent;

  scrollbar-width: thin;
  scrollbar-color: ${colors.oceanGreen} ${colors.snowWhite};
`;

export const Container = styled(ReadOnlyContainer)<{ $error: boolean; $disabled: boolean }>`
  ${staticBorderStyles('2px')};

  border-color: ${({ $error }) =>
    $error ? borderTypeToColorMap['error'] : borderTypeToColorMap['inactive']};

  background-color: ${({ $disabled }) => ($disabled ? colors.cloudGrey : colors.snowWhite)};

  transition: border 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

  &:focus-within > label {
    color: ${colors.oceanGreen};
  }

  &:hover {
    outline: none;
    border-color: ${({ $error, $disabled }) =>
      $disabled
        ? borderTypeToColorMap['inactive']
        : borderTypeToColorMap[$error ? 'error' : 'active']};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'inherit')};
  }

  &:focus-within {
    outline: none;
    border-color: ${borderTypeToColorMap['active']};
  }

  &:hover > textarea,
  label {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'inherit')};
  }
`;

export const ReadOnlyTextarea = styled.textarea<{
  $size: ElementalTextareaProps['size'];
}>`
  ${({ $size }) =>
    $size === 's'
      ? css`
          min-height: 113px;
          font-size: ${fontSizes.m.default};
        `
      : css`
          min-height: 111.5px;
          font-size: ${fontSizes.l.default};
        `};
  width: 100%;
  box-sizing: border-box;

  border: transparent;
  resize: none;

  color: ${colors.inkGrey};
  line-height: 1.2;
  text-align: left;

  &:enabled:focus {
    outline: none;
  }
`;

export const StyledTextarea = styled(ReadOnlyTextarea)<{
  $size: ElementalTextareaProps['size'];
  $error: boolean;
  $resizable: boolean;
}>`
  resize: ${({ $resizable }) => ($resizable ? 'vertical' : 'none')};
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: ${colors.fogGrey};
  }

  &::-moz-placeholder {
    color: ${colors.fogGrey};
  }

  &::-ms-placeholder {
    color: ${colors.fogGrey};
  }

  &::placeholder {
    color: ${colors.fogGrey};
    line-height: ${lineHeights.normal};
  }
`;

export const TextareaLabel = styled.label`
  position: absolute;

  top: 9px;
  font-size: ${fontSizes.xs.s};
  line-height: ${lineHeights.compact};
  color: ${colors.emperorGrey};
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  div {
    font-size: ${fontSizes.m.s};
  }
`;

export const StyledCounter = styled(ContentText)<{ $limitReached: boolean }>`
  font-size: ${fontSizes.m.default};
  line-height: ${lineHeights.loose};
  color: ${({ $limitReached }) => ($limitReached ? colors.punchRed : colors.fogGrey)};

  margin-left: ${spacing.xxxs};
  display: inline-block;
  overflow: hidden;
  text-align: right;
  vertical-align: top;
`;
