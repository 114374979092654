import { FC } from 'react';
import {
  ContentText,
  Heading,
  fontWeights,
  Box,
  colors,
  Link,
  fontSizes,
  lineHeights,
  spacing,
} from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from './styles';
import { PageNotFoundImage } from '@assets/images/PageNotFoundImage';

const PageNotFound: FC = () => {
  const { t } = useTranslation<Namespace>('errors');
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" backgroundColor={colors.snowWhite} height={'100%'}>
      <MainContainer>
        <Heading level={3} textAlign="center" maxWidth={'644px'}>
          {t('pageNotFound.header')}
        </Heading>
        <PageNotFoundImage />
        <Box display="flex" flexDirection="column">
          <Heading
            level={5}
            fontWeight={fontWeights.bold}
            lineHeight={1}
            textAlign="center"
            paddingBottom={spacing.xxs}
          >
            {t('pageNotFound.errorCode')}
          </Heading>
          <ContentText
            fontSize={fontSizes.l}
            fontWeight={fontWeights.regular}
            lineHeight={lineHeights.normal}
            textAlign="center"
          >
            {t('pageNotFound.desc')}
          </ContentText>
          <Link fontSize={fontSizes.l} noUnderline onClick={() => navigate('/')} textAlign="center">
            {t('pageNotFound.homePageLink')}
          </Link>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default PageNotFound;
