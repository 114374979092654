import { Auth0Provider } from '@auth0/auth0-react';
import { envPropKeys } from '@common/environmentProps';
import { SuspenseLoader } from '@components/layout/styles';
import { initAppInsights, reactPlugin } from '@config/azureInsights';
import { queryClient } from '@config/queryClient';
import {
  ORDERS_CREATION_ROUTE_PATH,
  ORDERS_CREATION_SUMMARY_ROUTE_PATH,
  paths,
} from '@config/routes';
import { ContractsFilterStateProvider } from '@contexts/ContractsFiltersContext';
import '@fortum/elemental-ui/FortumSans.css';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import Authorization from '@routes/auth';
import ContactUs from '@routes/contact/contact';
import { SelectCustomer } from '@routes/customer';
import { GeneralError } from '@routes/errors/general/general-error';
import PageNotFound from '@routes/errors/not-found/pageNotFound';
import { Home } from '@routes/home';
import { OrderDetails } from '@routes/orderDetails';
import { OrdersFiltersContextProvider } from '@routes/orders/components';
import OrdersOverview from '@routes/orders/orders';
import { OrdersCreationLayout } from '@routes/ordersCreation';
import { OrdersCreationContextProvider } from '@routes/ordersCreation/components';
import { OrdersCreation } from '@routes/ordersCreation/creationPage';
import { SummaryPage } from '@routes/ordersCreation/summaryPage';
import ReportsOverview, { ErrorElement as ReportsErrorElement } from '@routes/reports';
import { CostReport } from '@routes/reports/cost';
import DashboardReport from '@routes/reports/dashboard';
import { WasteQuantityReport } from '@routes/reports/wasteQty';
import ServiceDetails from '@routes/serviceDetails';
import TermsAndConditions from '@routes/terms';
import UserProfile from '@routes/user';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { shouldDisplayOrdersCreation } from '@utils/featureToggles';
import { getEnvProperty } from '@utils/general';
import { FC, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import './config/i18n/i18n';
import reportWebVitals from './reportWebVitals';
import Root, { loader as rootLoader } from './routes';
import ServicesOverview from './routes/services';
import GlobalStyle from './style';

const ErrorPropagator: FC = () => {
  const er = useRouteError();

  if (er) {
    throw er;
  }

  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={paths.main}
        element={<Root />}
        loader={() => rootLoader(queryClient)}
        errorElement={<ErrorPropagator />}
      >
        <Route index element={<Home />}></Route>
        <Route path={paths.services} errorElement={<GeneralError />}>
          <Route
            path={paths.services}
            element={
              <ContractsFilterStateProvider>
                <ServicesOverview />
              </ContractsFilterStateProvider>
            }
          />
          <Route
            path={':contractIdentifier'}
            element={
              <ContractsFilterStateProvider>
                <ServiceDetails />
              </ContractsFilterStateProvider>
            }
          />
        </Route>

        <Route
          path={paths.reports}
          element={<ReportsOverview />}
          errorElement={<ReportsErrorElement />}
        >
          <Route path={paths.dashboardReport} element={<DashboardReport />} />
          <Route path={paths.costDetailsReport} element={<CostReport />} />
          <Route path={paths.wasteDetailsReport} element={<WasteQuantityReport />} />
        </Route>
        <Route path={paths.contactUs} element={<ContactUs />} errorElement={<GeneralError />} />
        <Route path={paths.orders} errorElement={<GeneralError />}>
          <Route
            index
            element={
              <OrdersFiltersContextProvider>
                <OrdersOverview />
              </OrdersFiltersContextProvider>
            }
          />
          {shouldDisplayOrdersCreation && (
            <Route
              path={ORDERS_CREATION_ROUTE_PATH}
              element={
                <OrdersCreationContextProvider>
                  <OrdersCreationLayout />
                </OrdersCreationContextProvider>
              }
            >
              <Route index element={<OrdersCreation />} />
              <Route path={ORDERS_CREATION_SUMMARY_ROUTE_PATH} element={<SummaryPage />} />
            </Route>
          )}

          <Route
            path=":orderNo"
            element={
              <OrdersFiltersContextProvider>
                <OrderDetails />
              </OrdersFiltersContextProvider>
            }
          />
        </Route>
        <Route path={paths.userProfile} element={<UserProfile />} errorElement={<GeneralError />} />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path={paths.selectCustomer}
          errorElement={<GeneralError />}
          element={<SelectCustomer />}
        />
      </Route>
      <Route
        path={paths.termsAndConditions}
        element={<TermsAndConditions />}
        errorElement={<ErrorPropagator />}
      />
      <Route path={paths.auth} element={<Authorization />} errorElement={<ErrorPropagator />} />
    </>,
  ),
);

const prepareRoot = () => {
  initAppInsights();

  return (
    <Auth0Provider
      domain={getEnvProperty(envPropKeys.reactAppAuthDomain)}
      clientId={getEnvProperty(envPropKeys.reactAppAuthClientId)}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<SuspenseLoader />}>
          <AppInsightsErrorBoundary onError={GeneralError} appInsights={reactPlugin}>
            <>
              <GlobalStyle />
              <RouterProvider fallbackElement={<SuspenseLoader />} router={router} />
            </>
          </AppInsightsErrorBoundary>

          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </Suspense>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(prepareRoot());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
