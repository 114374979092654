import { CONTAINER_BORDER_RADIUS, Table, staticBorderStyles } from '@components/styles';
import { CommonBoxProps, TableProps } from '@fortum/elemental-ui';
import styled from 'styled-components';
import type { OrderSummary } from 'src/types/ordersCreation';

export const StyledTable = styled(Table)<TableProps<OrderSummary>>`
  //border-radius: ${CONTAINER_BORDER_RADIUS};
  ${staticBorderStyles()};

  table {
    width: 100%;
  }

  th {
    white-space: normal;
  }
`;

export const narrowCellContainerStyle: CommonBoxProps = {
  minWidth: '160px',
};

export const mediumCellContainerStyle: CommonBoxProps = {
  minWidth: '168px',
};

export const wideCellContainerStyle: CommonBoxProps = {
  minWidth: '294px',
};
