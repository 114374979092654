import styled from 'styled-components';
import { colors, Search } from '@fortum/elemental-ui';
import { Multiselect } from '@components/Multiselect';
import { staticBorderStyles } from '@components/styles';

export const StyledSelect: typeof Multiselect = styled(Multiselect)`
  div > span {
    color: ${colors.sonicGrey};
  }
  button {
    ${staticBorderStyles('2px')};
  }
`;

export const StyledSearch = styled(Search)`
  input {
    ${staticBorderStyles('2px')};
  }
`;
