import { FC, ReactNode, useRef } from 'react';
import { Overlay, Drawer } from './styles';
import { useClickOutside } from '@hooks/useClickOutside';
import { Stylable } from '@components/types';

interface SideDrawerProps extends Stylable {
  children: ReactNode;
  onClickOutside: () => void;
  width?: string;
  open: boolean;
}
export const SideDrawer: FC<SideDrawerProps> = ({
  className,
  children,
  onClickOutside,
  width = '300px',
  open,
}) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(open ? drawerRef : null, onClickOutside);

  return (
    <Overlay className={className} $open={open}>
      <Drawer $open={open} $width={width} ref={drawerRef}>
        {children}
      </Drawer>
    </Overlay>
  );
};
