import { OrderType } from '@models/orders';
import { FormRequiredFields } from 'src/types/ordersCreation';

export const requiredFields: FormRequiredFields = {
  COLLECTION: ['equipmentQuantity', 'estimatedWasteQuantity', 'orderType'],
  EMPTYING: ['equipmentQuantity', 'estimatedWasteQuantity', 'orderType'],
  DELIVERY: ['equipmentQuantity', 'orderType'],
};

export const orderTypesSortingOrder: OrderType[] = ['COLLECTION', 'DELIVERY', 'EMPTYING'] as const;
