import styled from 'styled-components';
import { spacing, colors } from '@fortum/elemental-ui';
import { QlikObject } from '@components/qlik';
import { horizontalContainer, verticalContainer } from '@components/styles';
import { ErrorView } from '@components/layout';

export const QlikButton = styled(QlikObject)`
  span {
    font-size: 13px;
  }
`;

export const ReportWrapper = styled.div`
  --row-gap: ${spacing.xxxs};
  --column-gap: ${spacing.xxxs};

  ${verticalContainer}

  flex: 1;

  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
  padding: ${spacing.xxs};
`;

export const HorizontalContainer = styled.div`
  ${horizontalContainer}

  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
`;

export const VerticalContainer = styled.div`
  ${verticalContainer}
  row-gap: var(--row-gap);
`;

export const HorizontalWrappableContainer = styled(HorizontalContainer)`
  flex-wrap: wrap;
`;

export const HeaderContainer = styled.div`
  ${horizontalContainer};

  height: 91px;
  min-height: 91px;

  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing.xs};
`;

export const HeaderButtonsContainer = styled.div`
  ${horizontalContainer}
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${spacing.xxs};
`;

export const ErrorViewWithBackground = styled(ErrorView)`
  background-color: ${colors.snowWhite};
  padding-top: 246px;
`;
