import { isPlatformSupported } from '@common/browserUtil';
import { CenteredLoader } from '@components/layout/styles';
import { isQlikError } from '@components/qlik';
import { logException } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { useQlikSession } from '@data/hooks/useQlikSession';
import { BrowserNotSupported } from '@routes/errors/browser-not-supported';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useRouteError } from 'react-router-dom';
import { SessionTimedOutError } from './components';
import { ErrorViewWithBackground } from './styles';

export const ErrorElement: FC = () => {
  const error = useRouteError();
  const { t } = useTranslation<Namespace>('errors');

  useEffect(() => {
    logException(error as Error);
  }, []);

  if (isQlikError(error) && error.getErrorType() === 'SESSION_TIMED_OUT') {
    return <SessionTimedOutError />;
  }

  if (isQlikError(error) && error.getErrorType() === 'ACCESS_DENIED') {
    return (
      <ErrorViewWithBackground
        header={t('reports.accessDenied.header')}
        message={t('reports.accessDenied.message')}
        link={{
          navigatePath: paths.contactUs,
          text: t('reports.accessDenied.linkMessage'),
        }}
      />
    );
  }

  return (
    <ErrorViewWithBackground
      header={t('reports.generalError.header')}
      message={t('reports.generalError.message')}
      link={{
        navigatePath: paths.contactUs,
        text: t('reports.generalError.linkMessage'),
      }}
    />
  );
};

const ReportsOverview: FC = () => {
  const { data, isLoading } = useQlikSession();

  if (isPlatformSupported('Safari')) return <BrowserNotSupported />;

  if (isLoading) return <CenteredLoader />;

  return data ? <Outlet /> : <ErrorElement />;
};

export default ReportsOverview;
