import { OrdersSelectedFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { useCallback, useState } from 'react';
import {
  SELECTED_FILTERS_DEFAULT_VALUES,
  FiltersPrecedence,
  DEFAULT_PRECEDENCE,
  ALL_TIME_PERIOD,
} from './types';
import { HandlingStatusGroup } from '@models/orders';

export const useFiltersSetters = () => {
  const [selectedFiltersValues, setSelectedFiltersValues] =
    useState<OrdersSelectedFiltersWithSearch>(SELECTED_FILTERS_DEFAULT_VALUES);
  const [filtersPrecedence, setFiltersPrecedence] = useState<FiltersPrecedence>(DEFAULT_PRECEDENCE);

  const reset = useCallback(() => {
    setSelectedFiltersValues(SELECTED_FILTERS_DEFAULT_VALUES);
    setFiltersPrecedence(DEFAULT_PRECEDENCE);
  }, []);

  const setSelectedFilterPrecedence = useCallback(
    (key: keyof OrdersSelectedFiltersWithSearch, remove: boolean) => {
      setFiltersPrecedence(prev => {
        if (remove) {
          return prev.filter(prevPrecedence => prevPrecedence.key !== key);
        }
        if (prev.find(prevPrecedence => prevPrecedence.key === key)) {
          return prev;
        }

        return [...prev, { key, order: prev.length }];
      });
    },
    [],
  );

  /**
   * Sending ALL value to the BE has same effect as sending en empty '' value
   * From the UI point of view it is better to store an empty selected value,
   * When the language is changed, all filters should be reset. When using ALL as unselected value what happens in the background is:
   * 1. Select component sets ALL item as selected. Since data has not been refreshed yet, this item contains translation to the old language.
   * At the same time request to the BE for data in new language is sent.
   * 2. Response from BE is received. It contains data in new language, it triggers SelectItems creation
   * 3. Select component is updated with SelectItems in new language, but selected item set in the 1st step cannot be found there - it is translated to the old lang.
   * As a result, selected item displayed on the component and list of items displayed in the component are translated to different languages.
   * Using the empty value eliminates this problem and simplifies the solution
   */
  const setTimePeriod = useCallback((selectValue: string) => {
    setSelectedFilterPrecedence('timePeriod', selectValue === ALL_TIME_PERIOD);
    setSelectedFiltersValues(prev => ({
      ...prev,
      timePeriod: selectValue === ALL_TIME_PERIOD ? '' : selectValue,
    }));
  }, []);

  const setOrderStatus = useCallback((orderStatus: HandlingStatusGroup[]) => {
    setSelectedFilterPrecedence('orderStatus', orderStatus.length === 0);

    setSelectedFiltersValues(prev => ({ ...prev, orderStatus }));
  }, []);

  const setOrderTypes = useCallback((orderTypes: string[]) => {
    setSelectedFilterPrecedence('orderType', orderTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, orderType: orderTypes }));
  }, []);

  const setCities = useCallback((cities: string[]) => {
    setSelectedFilterPrecedence('city', cities.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, city: cities }));
  }, []);

  const setContainerTypes = useCallback((containerTypes: string[]) => {
    setSelectedFilterPrecedence('containerType', containerTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, containerType: containerTypes }));
  }, []);

  const setTransportTypes = useCallback((transportTypes: string[]) => {
    setSelectedFilterPrecedence('transportType', transportTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, transportType: transportTypes }));
  }, []);

  const setBusinessPartners = useCallback((businessPartners: string[]) => {
    setSelectedFilterPrecedence('businessPartner', businessPartners.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, businessPartner: businessPartners }));
  }, []);

  const setWasteDescriptions = useCallback((wasteDescriptions: string[]) => {
    setSelectedFilterPrecedence('wasteDescription', wasteDescriptions.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, wasteDescription: wasteDescriptions }));
  }, []);

  const setSearch = useCallback((value: string) => {
    if (value.length === 0 || value.trim().length > 0) {
      setSelectedFilterPrecedence('search', value.length === 0);
      setSelectedFiltersValues(prev => ({ ...prev, search: value }));
    }
  }, []);

  const setAllExceptSearch = useCallback(
    (values: OrdersSelectedFilters, precedence: FiltersPrecedence) => {
      setSelectedFiltersValues(prev => ({ ...prev, ...values }));
      setFiltersPrecedence(precedence);
    },
    [],
  );

  const setAll = useCallback(
    (values: OrdersSelectedFiltersWithSearch, precedence: FiltersPrecedence) => {
      setSelectedFiltersValues(values);
      setFiltersPrecedence(precedence);
    },
    [],
  );

  return {
    selectedFiltersValues,
    setters: {
      timePeriod: setTimePeriod,
      orderType: setOrderTypes,
      orderStatus: setOrderStatus,
      city: setCities,
      containerType: setContainerTypes,
      transportType: setTransportTypes,
      businessPartner: setBusinessPartners,
      wasteDescription: setWasteDescriptions,
      search: setSearch,
    },
    reset,
    setAllExceptSearch,
    filtersPrecedence,
    setAll,
  };
};
