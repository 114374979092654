import { FC, useCallback, useContext, useRef, useState } from 'react';
import { ButtonsContainer, Container } from './styles';
import { OrderTileHeader } from './OrderTileHeader';
import { OrderCreationForm } from './OrderCreationForm';
import { Button, ButtonProps, IconChevronDown, IconChevronUp } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { OrderDetailsForm } from '../OrderDetailsForm';
import { Testable } from '@components/types';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';
import { useClickOutside } from '@hooks/useClickOutside';

interface OrderTileProps extends Testable {
  orderIndex: number;
}

const condensedButtonStyles: ButtonProps = {
  variant: 'condensed',
};

const plainButtonStyles: ButtonProps = {
  ...condensedButtonStyles,
  status: 'plain',
};

export const OrderTile: FC<OrderTileProps> = ({ orderIndex, 'data-testid': dataTestId }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation<Namespace>('common');
  const { validate, missingRequiredFields } = useContext(SingleOrderCreationContext);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const toggleDetailsOpen = useCallback(() => {
    setDetailsOpen(prev => !prev);
  }, []);

  useClickOutside(containerRef, validate);

  return (
    <Container
      data-testid={dataTestId}
      ref={containerRef}
      $error={missingRequiredFields.length > 0}
    >
      <OrderTileHeader
        detailsOpen={detailsOpen}
        orderIndex={orderIndex}
        onClick={toggleDetailsOpen}
      />

      <OrderCreationForm />

      {detailsOpen && <OrderDetailsForm />}

      <ButtonsContainer>
        <Button
          data-testid="show-details-button"
          {...plainButtonStyles}
          rightIcon={detailsOpen ? <IconChevronUp /> : <IconChevronDown />}
          onClick={toggleDetailsOpen}
        >
          {detailsOpen ? t('hideDetails') : t('showDetails')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};
