import { useAuth0 } from '@auth0/auth0-react';
import { isHttpError, isUnauthorizedError } from '@common/http';
import { StorageUtils, sessionKeys } from '@common/storage';
import { SuspenseLoader } from '@components/layout/styles';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { getUserInfoQueryOptions } from '@data/hooks/useUserInfo';
import { GeneralError } from '@routes/errors/general/general-error';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth: FC = () => {
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  const { getAccessTokenSilently, getIdTokenClaims, user, isAuthenticated, isLoading } = useAuth0();

  const processUserInfo = useCallback(async () => {
    if (error) return;

    try {
      const userData = await queryClient.fetchQuery(getUserInfoQueryOptions());

      if (!userData.agreementAccepted) {
        navigate(paths.termsAndConditions);
      } else if (userData.isAdmin && userData.isInternal) {
        navigate(paths.selectCustomer);
      } else if (userData.agreementAccepted) {
        const redirectPath = StorageUtils.popItem(sessionKeys.redirectUri) ?? paths.main;
        navigate(redirectPath);
      }
    } catch (e) {
      setError(e as Error);
    }
  }, [error, navigate]);

  const processTokens = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();

    if (idTokenClaims) StorageUtils.putItem(sessionKeys.idToken, idTokenClaims.__raw);

    StorageUtils.putItem(sessionKeys.accessToken, accessToken);
    StorageUtils.putItem(sessionKeys.logMetric, 'true');

    processUserInfo();
  }, [getAccessTokenSilently, getIdTokenClaims, processUserInfo]);

  useEffect(() => {
    if (isLoading) return;

    if (StorageUtils.hasItem(sessionKeys.accessToken)) {
      processUserInfo();

      return;
    }

    if (isAuthenticated) {
      if (user) processTokens();
      else setError(new Error('User not found'));

      return;
    }
  }, [user, isAuthenticated, isLoading]);

  if (error && isHttpError(error) && isUnauthorizedError(error)) {
    navigate(paths.main); //Navigate to Root, to handle 401 error by displaying the error page wrapped with the header and the navigation bar
    return;
  }

  return <>{error ? <GeneralError /> : <SuspenseLoader />}</>;
};

export default Auth;
