import { HeaderCell, HeaderRow, TableTile, ValueCell, ValuesRow } from '@components/TableTile';
import { Col, ColProps, CommonColProps, ContentText, fontSizes } from '@fortum/elemental-ui';
import { FC } from 'react';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { hasValue } from '@utils/dataOperations';
import { VerticalContainer } from '@components/styles';
import { getDateString } from '@utils/dates';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { SectionContainer, sectionHeaderStyles } from './styles';

const colProps: CommonColProps = {
  xxs: 4,
};

const creatorValueColProps: ColProps = {
  ...colProps,
  ph: { xs: 0 },
};

export const CreationDetails: FC = () => {
  const { data: user } = useUserInfo();
  const { t } = useTranslation<Namespace>('orderCreation');

  if (!user) {
    throw Error('User not found');
  }

  const name = [user.name, user.lastname].filter(hasValue).join(' ');

  const creationDate = getDateString(new Date());

  return (
    <SectionContainer>
      <ContentText {...sectionHeaderStyles}>{t('summaryPage.creationDetails.header')}</ContentText>

      <TableTile data-testid="creation-details-tile">
        <HeaderRow>
          <HeaderCell
            text={t('summaryPage.creationDetails.orderCreator')}
            gridColumnProps={colProps}
          />
          <HeaderCell
            text={t('summaryPage.creationDetails.creationDate')}
            gridColumnProps={colProps}
          />
        </HeaderRow>

        <ValuesRow>
          <Col {...creatorValueColProps} data-testid="order-creator-value-col">
            <VerticalContainer>
              <ContentText fontSize={fontSizes.s}>{name}</ContentText>
              <ContentText fontSize={fontSizes.s}>{user.phone}</ContentText>
              <ContentText fontSize={fontSizes.s}>{user.email}</ContentText>
            </VerticalContainer>
          </Col>

          <ValueCell
            text={creationDate}
            gridColumnProps={colProps}
            data-testid="creation-date-value-col"
          />
        </ValuesRow>
      </TableTile>
    </SectionContainer>
  );
};
