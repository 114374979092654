import { OrderType } from '@models/orders';
import {
  countAllMissingRequiredFields,
  initializeCreationFormWithOrder,
  updateOrderForm,
} from '@utils/ordersCreation';
import { useCallback, useState } from 'react';
import { CreationForms, FormValues } from 'src/types/ordersCreation';
import { v4 as uuidV4 } from 'uuid';
import { requiredFields } from '../config';
import { ServiceSummary } from '@models/contract';

export const useOrdersCreation = () => {
  const [creationForms, setCreationForms] = useState<CreationForms>([]);

  const clearForms = useCallback(() => setCreationForms([]), []);

  const setValue = useCallback(
    (
      fieldKey: keyof FormValues,
      value: FormValues[keyof FormValues],
      formIdentifier: string,
      contractIdentifier: string,
    ) =>
      setCreationForms(prev =>
        prev.map(creationForm =>
          creationForm.contractIdentifier !== contractIdentifier
            ? creationForm
            : {
                ...creationForm,
                forms: updateOrderForm(creationForm.forms, formIdentifier, fieldKey, value),
              },
        ),
      ),
    [],
  );

  const initializeCreationForms = useCallback(
    (contractIdentifier: string) =>
      setCreationForms([
        {
          contractIdentifier,
          forms: [],
        },
      ]),
    [],
  );

  const initializeCreationFormWithFirstOrder = useCallback(
    (
      contractIdentifier: string,
      serviceSummary: ServiceSummary,
      orderType: OrderType,
      contactPersonId: string,
      orderLevelReference?: string,
    ) =>
      setCreationForms(prev =>
        initializeCreationFormWithOrder(
          prev,
          uuidV4(),
          contractIdentifier,
          serviceSummary,
          orderType,
          contactPersonId,
          orderLevelReference,
        ),
      ),
    [],
  );

  const countMissingRequiredFields = useCallback(
    () => countAllMissingRequiredFields(creationForms, requiredFields),
    [creationForms],
  );

  return {
    creationForms,
    setValue,
    initializeCreationFormWithOrder: initializeCreationFormWithFirstOrder,
    initializeCreationForms,
    countMissingRequiredFields,
    clearForms,
  };
};
