import { WasteType } from '@config/services';
import { TabItem } from '@fortum/elemental-ui';
import { ServiceType } from '@models/service';
import { wasteTypeOrder } from '@routes/services/components/table/ContractLayout';
import { camelCase } from 'lodash';
import { translate } from './internationalization';

export type ContractTypeTab = TabItem<string> & {
  type: ServiceType['type'];
  service: ServiceType;
};

const getTabName = (service: ServiceType) => {
  const contractType = translate(camelCase(service.type), 'domain', 'contractType');

  return `${contractType} (${service.contracts.length})`;
};

// TODO: change WasteType enum to object
// Make type field of ServiceType to have correct type (value of WasteType)
// and get rid of the casting in the sorting function
// Write tests
export const mapToSortedTabItems = (services: ServiceType[]): ContractTypeTab[] =>
  services
    .map((service, index) => ({
      id: `${service.type}-${index}`,
      name: getTabName(service),
      type: service.type,
      service,
    }))
    .sort(
      (a, b) =>
        wasteTypeOrder.indexOf(a.type as WasteType) - wasteTypeOrder.indexOf(b.type as WasteType),
    );

export const getActiveTab = (
  tabs: ContractTypeTab[],
  activeTabFromSearchParams: string | null,
  activeTab: string,
) =>
  tabs.find(tab => tab.id === activeTabFromSearchParams || tab.id === activeTab)?.id || tabs[0]?.id;
