import { getEnvProperty } from '@utils/general';
import { AnalyticsService } from './analytics';
import { ContractService } from './contracts';
import { OrderService } from './orders';
import { UserService } from './users';

export const urls = {
  ...AnalyticsService,
  ...ContractService,
  ...OrderService,
  ...UserService,
  qlikVirtualProxy:
    getEnvProperty('REACT_APP_QLIK_BASE_URL') && getEnvProperty('REACT_APP_QLIK_VIRTUAL_PROXY_NAME')
      ? `${getEnvProperty('REACT_APP_QLIK_BASE_URL')}/${getEnvProperty('REACT_APP_QLIK_VIRTUAL_PROXY_NAME')}/qrs/about`
      : null,
  termsAndConditions: getEnvProperty('REACT_APP_TERMS_AND_CONDITIONS_URL') ?? null,
};
