import { WasteType } from '@config/services';
import { EquipmentServiceType, equipmentServiceTypes } from '@models/contract';
import { Contract, ServiceSummary } from '@models/contract';

export const mapContractToServiceSummary = (contract: Contract): ServiceSummary => ({
  contractNo: contract.generalInfo.contractNumber,
  wasteDescription: contract.serviceDetails.wasteDetails.description,
  serviceType: contract.generalInfo.serviceType,
  equipmentDescription: contract.serviceDetails.equipmentDetails.description,
  transportType: contract.serviceDetails.transportDetails.type,
  company: {
    customer: contract.generalInfo.customer,
    location: contract.generalInfo.location,
  },
});

export const isEquipmentSalesService = (contract: Contract) =>
  contract.generalInfo.serviceType === WasteType.EQUIPMENT_SALES ||
  contract.generalInfo.serviceType === WasteType.EQUIPMENT_SALES_SERVICE;

export const containsWasteDetails = (contract: Contract) =>
  contract.generalInfo.wasteDescription && !isEquipmentSalesService(contract);

export const isEquipmentServiceType = (serviceType: string): serviceType is EquipmentServiceType =>
  equipmentServiceTypes.includes(serviceType as EquipmentServiceType);
