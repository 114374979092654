import { createContext, PropsWithChildren } from 'react';
import {
  useContractsFiltersHandler,
  UseContractsFiltersHandlerReturnValue,
} from '@hooks/useContractsFiltersHandler';

type ContractsFiltersContextProps = UseContractsFiltersHandlerReturnValue;

export const ContractsFiltersContext = createContext<ContractsFiltersContextProps>({
  selectedWasteTypes: [],
  selectedEquipmentTypes: [],
  search: '',
  queryResult: { isError: false, isLoading: false, fetchStatus: 'idle' },
  filtersKeysWithSearch: { wastes: [], equipments: [], search: '' },
  debouncedFiltersKeysWithSearch: { wastes: [], equipments: [], search: '' },
  filtersSelectItems: { wasteTypes: [], equipmentTypes: [] },
  lastDataReloadCause: null,
  handleEquipmentTypesChange: () => undefined,
  handleSearchChange: () => undefined,
  handleWasteTypesChange: () => undefined,
  resetAll: () => undefined,
});

export const ContractsFilterStateProvider = ({ children }: PropsWithChildren) => {
  const filtersHandler = useContractsFiltersHandler();

  return (
    <ContractsFiltersContext.Provider value={filtersHandler}>
      {children}
    </ContractsFiltersContext.Provider>
  );
};
