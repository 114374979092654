export const ReasonsForContacting = [
  'safety',
  'services',
  'orders',
  'reports',
  'invoicing',
  'reclamation',
  'general',
] as const;
export type ReasonForContacting = (typeof ReasonsForContacting)[number];

export interface UserMessage {
  reason: ReasonForContacting;
  message: string;
  locationCodes: string[];
  title: string;
}
