import styled from 'styled-components';
import { HorizontalContainer, HorizontalWrappableContainer } from '../styles';
import { QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL } from '@components/qlik/components/QlikSelectionObject';

export const BottomContainer = styled(HorizontalContainer)`
  flex: 1;
`;

export const TopContainer = styled(HorizontalWrappableContainer)`
  margin-left: calc(${`${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`} + var(--column-gap));
`;
