import { NO_DATA_BADGE, NO_DATA_INDICATOR } from './consts';
export * from './multiselect';
export * from './wasteDetails';
export * from './consts';
export * from './dataSource';

export const displayedValue = (value: string | null | undefined) =>
  !value?.trim() || value === NO_DATA_INDICATOR ? NO_DATA_BADGE : value;

export const hasValue = (value: string | null | undefined): value is string =>
  !!value?.trim() && value !== NO_DATA_INDICATOR;
