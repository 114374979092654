import { SelectItem } from '@fortum/elemental-ui';
import { t } from 'i18next';

export const displayMultiselectAppliedValues = (selectedValues: string[]) => {
  return selectedValues.length > 1
    ? t('common:multiselect', { count: selectedValues.length })
    : selectedValues[0];
};

export const moveSelectedToStart = (items: SelectItem<string>[], selectedItemsValues: string[]) => {
  const head = items.filter(item => selectedItemsValues.some(selected => selected === item.value));
  const tail = items.filter(item => !selectedItemsValues.some(selected => selected === item.value));

  return [...head, ...tail];
};

export const filterSelectItems = <T>(items: SelectItem<T>[], filter: string) =>
  filter ? items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())) : [];
