import { useCallback, useEffect, useState } from 'react';
import { NotificationType } from './Notification';

const NOTIFICATION_AUTO_CLOSE_DELAY_MS = 5000;

export const useNotification = (autoCloseDelay: number = NOTIFICATION_AUTO_CLOSE_DELAY_MS) => {
  const [displayNotification, setDisplayNotification] = useState<NotificationType | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (displayNotification === 'success') {
        setDisplayNotification(null);
      }
    }, autoCloseDelay);

    return () => clearTimeout(timeout);
  }, [displayNotification]);

  const setDisplaySuccessfulNotification = useCallback(() => {
    setDisplayNotification('success');
  }, []);

  const setDisplayErrorNotification = useCallback(() => {
    setDisplayNotification('error');
  }, []);

  const closeNotification = useCallback(() => {
    setDisplayNotification(null);
  }, []);

  return {
    displayNotification,
    setDisplayErrorNotification,
    setDisplaySuccessfulNotification,
    closeNotification,
  };
};
