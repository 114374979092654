import { equipmentServiceTypesToOrderTypesMap } from '@config/order';
import { isEquipmentServiceType } from './contract';
import { RadioProps } from '@fortum/elemental-ui';
import { flatten, uniq } from 'lodash';
import { OrderType } from '@models/orders';
import { t } from 'i18next';
import { orderTypes } from '@models/orders';
import {
  BasicFormValues,
  CreationForm,
  CreationForms,
  FormRequiredFields,
  FormValues,
} from 'src/types/ordersCreation';
import { ServiceSummary } from '@models/contract';

export const mapOrderTypesToRadioItems = (orderTypes: OrderType[]): RadioProps[] =>
  orderTypes.map(orderType => ({
    label: t(`orderCreation:form.orderType.${orderType}`),
    value: orderType,
  }));

export const mapRawEquipmentServiceTypesToOrderTypes = (rawEquipmentServiceTypes: string[]) =>
  uniq(
    flatten(
      rawEquipmentServiceTypes
        .map(rawEquipmentServiceType => rawEquipmentServiceType.toUpperCase().replace(/-/g, '_'))
        .filter(isEquipmentServiceType)
        .map(eqServiceType => equipmentServiceTypesToOrderTypesMap[eqServiceType]),
    ),
  );

export const orderTypesSortingFunction =
  (sortingOrder: OrderType[]) => (a: OrderType, b: OrderType) =>
    sortingOrder.indexOf(a) - sortingOrder.indexOf(b);

export const isOrderType = (value: string): value is OrderType =>
  orderTypes.includes(value as OrderType);

export const getMissingRequiredFields = (
  formValues: FormValues,
  requiredFields: (keyof BasicFormValues)[],
) =>
  requiredFields.filter(field =>
    typeof formValues[field] === 'string' ? !formValues[field]?.trim() : !formValues[field],
  );

export const countAllMissingRequiredFields = (
  creationForms: CreationForms,
  requiredFields: FormRequiredFields,
) =>
  creationForms
    .flatMap(creationForm =>
      creationForm.forms.map(
        orderForm =>
          getMissingRequiredFields(orderForm.values, requiredFields[orderForm.values.orderType])
            .length,
      ),
    )
    .reduce((prev, current) => prev + current, 0);

export const isContractsFetchingEnabled = (
  selectedSites: string[],
  selectedWasteTypes: string[],
  selectedEquipmentTypes: string[],
  search: string,
) =>
  selectedSites.length > 0 ||
  search.trim().length > 0 ||
  selectedWasteTypes.length > 0 ||
  selectedEquipmentTypes.length > 0;

const getInitialFormValues = (
  orderType: OrderType,
  contactPersonId: string,
  orderLevelReference?: string,
): FormValues => ({
  orderType,
  contactPersonId,
  orderLevelReference: orderLevelReference || '',
  estimatedWasteQuantity: '',
  equipmentQuantity: '',
  comments: '',
  specifiedWasteDescription: '',
  locationPointAdditionalInfo: '',
});

export const initializeCreationFormWithOrder = (
  currentForms: CreationForms,
  formIdentifier: string,
  contractIdentifier: string,
  serviceSummary: ServiceSummary,
  orderType: OrderType,
  contactPersonId: string,
  orderLevelReference?: string,
): CreationForms => {
  const orderForm: CreationForm['forms'][number] = {
    formIdentifier,
    values: getInitialFormValues(orderType, contactPersonId, orderLevelReference),
  };

  const contractForm = currentForms.find(form => form.contractIdentifier === contractIdentifier);

  const creationForm: CreationForm = {
    contractIdentifier,
    serviceSummary,
    forms: [orderForm],
  };

  if (!contractForm) {
    return [...currentForms, creationForm];
  }

  return currentForms.map(form =>
    form.contractIdentifier == contractIdentifier ? creationForm : form,
  );
};

export const updateOrderForm = (
  forms: CreationForm['forms'],
  formIdentifier: string,
  fieldKey: keyof FormValues,
  value: FormValues[keyof FormValues],
): CreationForm['forms'] =>
  forms.map(form => {
    if (form.formIdentifier !== formIdentifier) return form;

    const temporaryState = { ...form };
    temporaryState.values = temporaryState.values ?? {};

    if (fieldKey !== 'orderType') {
      temporaryState.values[fieldKey] = value;
    } else if (isOrderType(value)) {
      temporaryState.values.orderType = value;
    }

    return { ...temporaryState };
  });
