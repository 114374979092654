import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { envPropKeys } from '@common/environmentProps';
import { sessionKeys, StorageUtils } from '@common/storage';
import { clearAuthenticatedUser } from '@config/azureInsights';
import { paths } from '@config/routes';
import { terminateApplicationSession } from '@data/api/auth';
import { getEnvProperty } from '@utils/general';
import { useCallback, useEffect } from 'react';

const loginParams: RedirectLoginOptions = {
  authorizationParams: { redirectUri: getEnvProperty(envPropKeys.reactAppAuthRedirectUri) },
};

interface UseAuthProvider {
  logout: () => Promise<void>;
}

export const useAuthProvider = (): UseAuthProvider => {
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();

  useEffect(() => {
    if (!StorageUtils.hasItem(sessionKeys.redirectUri) && !location.pathname.includes(paths.auth)) {
      const redirectUri = `${location.pathname}${location.search ?? ''}`;

      sessionStorage.setItem(StorageUtils.sessionKeys.redirectUri, redirectUri);
    }

    if (isLoading || isAuthenticated) return;

    loginWithRedirect(loginParams);
  }, [isLoading, isAuthenticated]);

  const handleLogout = useCallback(async () => {
    await terminateApplicationSession();

    StorageUtils.removeItem(sessionKeys.accessToken);
    StorageUtils.removeItem(sessionKeys.idToken);

    clearAuthenticatedUser();

    // terminate the Auth0 session
    logout();
  }, []);

  return { logout: handleLogout };
};
