import { horizontalContainer, verticalContainer } from '@components/styles';
import { ForwardLink, breakpoints, colors, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const CustomerServiceIconRoundedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${colors.pineGreen};
`;

export const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${`(max-width: ${breakpoints.xl}${'px'})`} {
    row-gap: ${spacing.xxs};
  }

  row-gap: ${spacing.xs};
  height: 100%;
  box-sizing: border-box;
`;

export const SocialMediaContainer = styled.div`
  ${horizontalContainer};
  padding: ${spacing.xs};
  background-color: ${colors.lightGrey};

  align-items: center;
  flex: 1;
  box-sizing: border-box;
`;

export const ETrainingContainer = styled.div`
  ${horizontalContainer};
  padding: ${spacing.xs};
  background-color: ${colors.lightGrey};

  align-items: center;
  flex: 1;
  box-sizing: border-box;
`;

export const ContactUsTileContainer = styled.div`
  ${verticalContainer};
  align-items: center;
  padding: ${spacing.xs};
  background-color: ${colors.turquoiseGreen};
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
`;

export const NonWrappingForwardLink = styled(ForwardLink)`
  white-space: nowrap;
`;
