import { horizontalContainer, verticalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  ${horizontalContainer};
  margin: 48px 0px;
  gap: ${spacing.xs};

  justify-content: flex-end;
`;

export const ServiceTilesContainer = styled.div`
  ${verticalContainer};

  flex: 1;

  gap: 48px;
`;
