import { TokenRefreshUtils } from '@common/auth';
import { StorageUtils } from '@common/storage';
import { logEvent, setAuthenticatedUser } from '@config/azureInsights';
import { isUser } from '@models/user';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { onRequestError } from '@utils/data';
import { getUserRoleForStatistics } from '@utils/user';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, query) => onRequestError(err, query.queryKey[0] as string),
    onSuccess: (data, query) => {
      TokenRefreshUtils.onSuccessfulTokenValidation(query.queryKey[0] as string);

      if (!isUser(data)) return;

      setAuthenticatedUser(data);
      const shouldSendEvent = StorageUtils.popItem(StorageUtils.sessionKeys.logMetric);

      if (!shouldSendEvent) return;

      logEvent('successfulLogin', { userRole: getUserRoleForStatistics(data) });

      if (data.isInternal || data.isAdmin) return;

      data.businessPartners.map(bp => logEvent('loginPerCompany', { companyName: bp.name }));
    },
  }),
});
