import { QueryKeys } from '@common/query';
import { Namespace } from '@config/i18n';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { urls } from '@data/api/config';
import { updateUserAgreement } from '@data/api/user';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { Button, ContentText, IconCodeOfConduct, Link, Modal } from '@fortum/elemental-ui';
import { useAuthProvider } from '@hooks/useAuthProvider';
import { GeneralError } from '@routes/errors/general/general-error';
import { useMutation } from '@tanstack/react-query';
import { isUserInternalAdmin } from '@utils/user';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  buttonStyles,
  ContainerBox,
  iconStyles,
  linkStyles,
  secondaryButtonStyles,
  textStyles,
  titleStyles,
} from './style';

const TermsAndConditions: FC = () => {
  const { t } = useTranslation<Namespace>('termsAndCondition');
  const navigate = useNavigate();

  const { data: user } = useUserInfo();
  const { logout } = useAuthProvider();
  const [error, setError] = useState(false);

  const userAgreementMutation = useMutation({
    mutationFn: updateUserAgreement,
    onSuccess: () => {
      queryClient.setQueryData([QueryKeys.userInfo], { ...user, agreementAccepted: true });
      setError(false);

      isUserInternalAdmin(user) ? navigate(paths.selectCustomer) : navigate(paths.main);
    },
    onError: () => setError(true),
  });

  const handleTermsAndConditionsLink = () => window.open(urls.termsAndConditions ?? '');

  return error ? (
    <GeneralError />
  ) : (
    <Modal id="modalTermsAndConditions" hideCloseButton={true} opened={true} width="880px">
      <IconCodeOfConduct {...iconStyles} />
      <ContentText id="textTermsAndConditions" {...titleStyles}>
        {t('termsAndConditions')}
      </ContentText>
      <ContentText {...textStyles}>
        {t('acceptContent')}
        <Link
          id="linkTermsAndConditions"
          onClick={handleTermsAndConditionsLink}
          target="_blank"
          rel="noopener noreferrer"
          {...linkStyles}
        >
          {t('termsAndConditionsLink')}
        </Link>
        {t('contentInfo')}
      </ContentText>

      <ContainerBox>
        <Button
          id="saveTermsAndConditionsButton"
          role="saveTermsAndConditionsRole"
          onClick={() => userAgreementMutation.mutate()}
          {...buttonStyles}
        >
          {t('accept')}
        </Button>
        <Button
          id="cancelTermsAndConditionsButton"
          role="cancelTermsAndConditionsRole"
          onClick={logout}
          {...secondaryButtonStyles}
        >
          {t('cancel')}
        </Button>
      </ContainerBox>
    </Modal>
  );
};

export default TermsAndConditions;
