import i18n, { Namespace } from '@config/i18n';
import { t } from 'i18next';

export const translate = (key: string, namespace: Namespace, subNamespace?: string) => {
  const fullKey = namespace + ':' + (subNamespace ? `${subNamespace}.` : '') + key;

  if (i18n.exists(fullKey)) {
    return t(fullKey);
  }

  return key;
};

export const translateIfExist = (
  key: string,
  namespace: Namespace,
  subNamespace?: string,
): string | undefined => {
  const fullKey = namespace + ':' + (subNamespace ? `${subNamespace}.` : '') + key;

  return i18n.exists(fullKey) ? t(fullKey) : undefined;
};
