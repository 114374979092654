import { FC } from 'react';
import { Box, Col, Row } from '@fortum/elemental-ui';
import { GRID_GAP } from '@routes/serviceDetails/style';
import {
  WasteDetailsTile,
  CompanySiteDetailsTile,
  EquipmentDetailsTile,
  TransportDetailsTile,
  InvoicingDetailsTile,
} from '.';
import { Contract } from '@models/contract';

interface ServiceDetailsWithWasteTilesProps {
  contract: Contract;
  isEquipmentSales: boolean;
}

export const ServiceDetailsWithWasteTiles: FC<ServiceDetailsWithWasteTilesProps> = ({
  contract,
  isEquipmentSales,
}) => {
  return (
    <>
      <Row mb={GRID_GAP}>
        <Col xs={12}>
          <CompanySiteDetailsTile
            customer={contract.generalInfo.customer}
            location={contract.generalInfo.location}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={6} mb={{ xs: GRID_GAP, xl: 0 }}>
          <WasteDetailsTile
            generalInfo={contract.generalInfo}
            wasteDetails={contract.serviceDetails.wasteDetails}
          />
        </Col>

        <Col xl={6}>
          <Box display="flex" flexDirection="column" gap={GRID_GAP}>
            <EquipmentDetailsTile equipmentDetails={contract.serviceDetails.equipmentDetails} />
            <TransportDetailsTile
              isEquipmentSales={isEquipmentSales}
              transportDetails={contract.serviceDetails.transportDetails}
              contactDetails={contract.contractDetails.contactDetails}
            />
            <InvoicingDetailsTile invoicingDetails={contract.contractDetails.invoicingDetails} />
          </Box>
        </Col>
      </Row>
    </>
  );
};
