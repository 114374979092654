import {
  QlikObject,
  useDefaultSelections,
  useCurrentSelections,
  QlikSelectionObject,
  BookmarksContextProvider,
} from '@components/qlik';
import {
  DimensionsContainer,
  DynamicTableContainer,
  FiltersContainer,
  HorizontalContainer,
} from './styles';
import { ReportProps } from '../types';
import { Grid, spacing, Row, Col } from '@fortum/elemental-ui';
import { FirstRow } from './FirstRow';
import { ErrorViewWithBackground, QlikButton } from '../styles';
import { PageHeader, SelectionsManagement } from '../components';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { DashboardReportObjects } from '@config/qlik/dashboard';
import { CenteredLoader } from '@components/layout/styles';
import { paths } from '@config/routes';
import { FC } from 'react';

export const Report: FC<ReportProps<DashboardReportObjects>> = ({
  defaultSelections,
  exportDataHandler,
  objectIds,
  mandatorySelections,
}) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'errors']);

  const { qSelections, clearSelections, isError, isLoading } = useCurrentSelections();

  const { mandatorySelectionsError, mandatorySelectionsValidationInProgress } =
    useDefaultSelections(defaultSelections, mandatorySelections);

  if (mandatorySelectionsValidationInProgress) {
    return <CenteredLoader />;
  }

  if (mandatorySelectionsError) {
    return (
      <ErrorViewWithBackground
        header={t('errors:reports.nothingToReport.header')}
        message={t('errors:reports.nothingToReport.message')}
        link={{
          navigatePath: paths.selectCustomer,
          text: t('errors:reports.nothingToReport.linkMessage'),
        }}
      />
    );
  }

  return (
    <BookmarksContextProvider qSelections={qSelections}>
      <PageHeader title={t('reports:dashboard.title')} exportDataHandler={exportDataHandler} />
      <Grid mb={spacing.xxxs} maxWidth={{ xxl: `calc(100% - 2*${spacing.xs})` }}>
        <Row marginBottom={spacing.xs} marginTop={spacing.xxxs}>
          <Col xxs={12} paddingHorizontal={{ xs: spacing.xxxxs }}>
            <SelectionsManagement
              qSelections={qSelections}
              clearSelections={clearSelections}
              isSelectionsFetchingError={isError}
              isSelectionsLoading={isLoading}
            />
          </Col>
        </Row>
        <Row marginVertical={spacing.xxxs}>
          <FiltersContainer>
            <HorizontalContainer>
              <QlikButton
                id={objectIds.filters.filterByLastLastYear}
                padding="0px"
                width="64px"
                dataTestId="filter-by-last-last-year"
              />

              <QlikButton
                id={objectIds.filters.filterByLastYear}
                padding="0px"
                width="64px"
                dataTestId="filter-by-last-year"
              />

              <QlikButton
                id={objectIds.filters.filterByCurrentYear}
                padding="0px"
                width="64px"
                dataTestId="filter-by-current-year"
              />
            </HorizontalContainer>

            <QlikSelectionObject
              id={objectIds.filters.company}
              padding="0px"
              dataTestId="filter-company"
            />

            <QlikSelectionObject
              id={objectIds.filters.hazardous}
              padding="0px"
              dataTestId="filter-hazardous"
            />
          </FiltersContainer>
        </Row>
        <FirstRow
          marginVertical={spacing.xxxs}
          height="350px"
          columnsPaddingHorizontal={{ xs: spacing.xxxxs }}
          objectIds={objectIds}
        />

        <Row marginVertical={spacing.xxxs}>
          <Col xxs={12} l={6} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
            <QlikObject
              id={objectIds.charts.receivedMaterialOverTime}
              dataTestId="chart-received-material-over-time"
            />
          </Col>
          <Col xxs={12} l={6} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
            <QlikObject id={objectIds.charts.costsInEuros} dataTestId="chart-costs-in-euros" />
          </Col>
        </Row>

        <Row marginVertical={spacing.xxxs}>
          <Col l={4} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
            <QlikObject id={objectIds.charts.wasteHandling} dataTestId="chart-waste-handling" />
          </Col>
          <Col l={4} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
            <QlikObject id={objectIds.tables.summaryByWaste} dataTestId="chart-summary-by-waste" />
          </Col>
          <Col l={4} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
            <QlikObject
              id={objectIds.tables.receivedMaterialOverTime}
              dataTestId="table-received-material-over-time"
            />
          </Col>
        </Row>

        {!!objectIds.tables.summaryByCustomerSite && (
          <Row>
            <Col xxs={12} height="350px" paddingHorizontal={{ xs: spacing.xxxxs }}>
              <QlikObject
                id={objectIds.tables.summaryByCustomerSite}
                dataTestId="table-summary-by-customer-site"
              />
            </Col>
          </Row>
        )}

        <Row marginTop={spacing.xxxs}>
          <Col xxs={12} paddingHorizontal={{ xs: spacing.xxxxs }} height="500px">
            <DynamicTableContainer>
              <DimensionsContainer>
                <QlikSelectionObject
                  id={objectIds.dynamicTable.dimensions.company}
                  dataTestId="dimension-company"
                />
                <QlikSelectionObject
                  id={objectIds.dynamicTable.dimensions.wasteDetails}
                  dataTestId="dimension-waste-details"
                />
                <QlikSelectionObject
                  id={objectIds.dynamicTable.dimensions.measures}
                  dataTestId="dimensions-measures"
                />
              </DimensionsContainer>
              <QlikObject id={objectIds.dynamicTable.table} height="500px" dataTestId="table" />
            </DynamicTableContainer>
          </Col>
        </Row>
      </Grid>
    </BookmarksContextProvider>
  );
};
