import { QueryKeys } from '@common/query';
import { Service } from '@models/service';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternalAdmin } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { ContractFiltersKeys } from 'src/types/services';
import { fetchServices } from '../api/service';
import { QueryOptions } from './types';

const servicesQuery = (
  selectedFiltersKeys: ContractFiltersKeys,
  userAccess: UserAccess,
  language: string,
): QueryOptions<Service> => ({
  queryKey: isUserAccessOfInternalAdmin(userAccess)
    ? [
        QueryKeys.servicesAdmin,
        language,
        selectedFiltersKeys,
        userAccess.selectedBusinessPartners.map(bp => bp.id).join(','),
      ]
    : [QueryKeys.services, language, selectedFiltersKeys],

  queryFn: () => fetchServices(selectedFiltersKeys, userAccess, language),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useServices = (selectedFiltersKeys: ContractFiltersKeys) => {
  const { i18n } = useTranslation();

  return useQuery<Service, Error>(
    servicesQuery(selectedFiltersKeys, getUserAccessInfo(), i18n.language),
  );
};
