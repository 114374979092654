import { Namespace } from '@config/i18n';
import { IconProfileOutline, Menu, MenuItemWithIcon } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { paths } from '@config/routes';
import { urls } from '@data/api/config';
import { fallbackLang, isSupportedLanguage } from '@common/languages';
import { getPrivacyNoticeURL } from '@common/privacyNotive';

export interface AuthWidgetProps {
  logout: () => void;
  userName: string;
}

type MenuValue = 'user-profile' | 'logout' | 'privacy-notice' | 'terms-and-conditions';
type AuthWidgetItem = MenuItemWithIcon<MenuValue> | Omit<MenuItemWithIcon<MenuValue>, 'icon'>;

export const AuthWidget: FC<AuthWidgetProps> = ({ userName, logout }) => {
  const { t, i18n } = useTranslation<Namespace[]>(['common', 'mainLayout', 'termsAndCondition']);
  const navigate = useNavigate();

  const items: AuthWidgetItem[] = useMemo(
    () => [
      { value: 'user-profile', name: t('mainLayout:userProfile') },
      {
        value: 'privacy-notice',
        name: t('mainLayout:privacyNotice'),
      },
      {
        value: 'terms-and-conditions',
        name: t('termsAndCondition:termsAndConditions'),
      },
      {
        value: 'logout',
        name: t('common:logout'),
      },
    ],
    [i18n.language],
  );

  const onSelect = useCallback(
    (value: MenuValue | SyntheticEvent<HTMLElement, Event>) => {
      if (typeof value !== 'string') {
        return;
      }

      if (value === 'user-profile') {
        navigate(paths.userProfile);
        return;
      }

      if (value === 'privacy-notice') {
        const currentLanguage = isSupportedLanguage(i18n.language) ? i18n.language : fallbackLang;
        const privacyNoticeURL = getPrivacyNoticeURL(currentLanguage);

        if (!privacyNoticeURL) throw Error('Privacy notice URL not found');

        window.open(privacyNoticeURL, '_blank');
        return;
      }

      if (value === 'terms-and-conditions') {
        if (!urls.termsAndConditions) throw Error('Terms and Conditions URL not found');
        window.open(urls.termsAndConditions, '_blank');
        return;
      }

      logout();
    },
    [i18n.language],
  );

  return (
    <Menu
      id="auth-widget"
      items={items}
      label={userName}
      onSelect={onSelect}
      icon={<IconProfileOutline />}
      menuWidth="221px"
    />
  );
};
