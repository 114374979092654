import {
  Button,
  ContentText,
  InputField,
  ModalProps,
  Textarea,
  lineHeights,
} from '@fortum/elemental-ui';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ModalButtonsContainer,
  ModalInputsContainer,
  StyledIconCustomerReleases,
  StyledModal,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

type BookmarkCreationModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
  createBookmark: (name: string, description: string) => void;
  onClose: () => void;
};

const DESCRIPTION_MAX_LENGTH = 150;
const NAME_MAX_LENGTH = 25;

export const BookmarkCreationModal: FC<BookmarkCreationModalProps> = ({
  createBookmark,
  ...props
}) => {
  const { t } = useTranslation<Namespace[]>(['common', 'reports', 'errors']);

  const [bookmark, setBookmark] = useState<{ name: string; description: string }>({
    name: '',
    description: '',
  });

  const onNameChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setBookmark(prev => ({ ...prev, name: ev.target.value.substring(0, NAME_MAX_LENGTH) }));
  }, []);

  const onDescriptionChange = useCallback((ev: ChangeEvent<HTMLTextAreaElement>) => {
    setBookmark(prev => ({ ...prev, description: ev.target.value }));
  }, []);

  const saveDisabled = useMemo(
    () => !bookmark.name.trim() || bookmark.description.trim().length > DESCRIPTION_MAX_LENGTH,
    [bookmark.description, bookmark.name],
  );

  const nameInputError = useMemo(
    () => bookmark.name.length > 0 && !bookmark.name.trim(),
    [bookmark.name],
  );

  const onBookmarkSave = useCallback(() => {
    if (saveDisabled) {
      return;
    }

    props.onClose();

    createBookmark(bookmark.name.trim(), bookmark.description.trim());
  }, [createBookmark, saveDisabled, bookmark.name, bookmark.description]);

  useEffect(() => {
    setBookmark({ name: '', description: '' });
  }, [props.opened]);

  return (
    <StyledModal {...props}>
      <StyledIconCustomerReleases size={48} />
      <ContentText fontSize={'36px'} lineHeight={lineHeights.tight}>
        {t('reports:bookmarks.create')}
      </ContentText>
      <ModalInputsContainer>
        <InputField
          onChange={onNameChange}
          name="bookmark-name"
          label={t('reports:bookmarks.name')}
          value={bookmark.name}
          required
          error={nameInputError}
          errorMessage={t('errors:reports.bookmarks.invalidName')}
        />
        <Textarea
          name="bookmark-description"
          label={t('reports:bookmarks.description')}
          value={bookmark.description}
          maxLength={DESCRIPTION_MAX_LENGTH}
          onChange={onDescriptionChange}
        />
      </ModalInputsContainer>
      <ModalButtonsContainer>
        <Button disabled={saveDisabled} onClick={onBookmarkSave} id="save-bookmark-button">
          {t('reports:bookmarks.save.short')}
        </Button>
        <Button status="secondary" onClick={props.onClose} id="close-button">
          {t('common:cancel')}
        </Button>
      </ModalButtonsContainer>
    </StyledModal>
  );
};
