import { Namespace } from '@config/i18n';
import { Chip, ChipItem, IconCheck, IconFilter } from '@fortum/elemental-ui';
import { memo, useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionContainer,
  StyledAccordion,
  AbsolutelyPositionedButton,
  ChipsContainer,
} from './styles';
import { isEqual } from 'lodash';

interface FiltersAccordionProps<T extends ChipItem<string>> {
  id?: string;
  chipsItems: T[];
  removeFilter: (item: T) => void;
  clearAllFilters: () => void;
  accordionOpenedByDefault?: boolean;
}

const FiltersAccordionLayout = <T extends ChipItem<string>>({
  id = 'filters-accordion',
  chipsItems,
  removeFilter,
  clearAllFilters,
  accordionOpenedByDefault,
}: FiltersAccordionProps<T>) => {
  const { t, i18n } = useTranslation<Namespace>('common');
  const [openTextWidth, setOpenTextWidth] = useState<string | undefined>();

  useLayoutEffect(() => {
    const accordionButtonSelector = `#${id}-button`;
    const accordionOpenTextElement = document.querySelector(`${accordionButtonSelector} > span`);
    if (!accordionOpenTextElement) {
      return;
    }

    setOpenTextWidth(`${accordionOpenTextElement.getBoundingClientRect().width}px`);
  }, [i18n.language]);

  const renderChips = useCallback(
    () => (
      <ChipsContainer>
        {chipsItems.map(item => (
          <Chip
            id={item.value}
            key={item.label}
            label={item.label}
            value={item.value}
            active
            activeIcon={<IconCheck />}
            onClick={() => removeFilter(item)}
          />
        ))}
      </ChipsContainer>
    ),
    [chipsItems, removeFilter],
  );

  return (
    <AccordionContainer>
      <StyledAccordion
        openText={t('appliedFilters', { amount: chipsItems.length })}
        opened={accordionOpenedByDefault}
        leftIcon={<IconFilter />}
        width="100%"
        id={id}
      >
        {renderChips()}
      </StyledAccordion>
      <AbsolutelyPositionedButton
        variant="condensed"
        status="plain"
        $textWidth={openTextWidth}
        id="clear-filters-button"
        onClick={clearAllFilters}
        disabled={chipsItems.length === 0}
      >
        {t('clearFilters')}
      </AbsolutelyPositionedButton>
    </AccordionContainer>
  );
};

export const FiltersAccordion = memo(FiltersAccordionLayout, (prev, next) =>
  isEqual(prev, next),
) as typeof FiltersAccordionLayout;
