import { FC, ReactNode, createContext } from 'react';
import { CreationForm } from 'src/types/ordersCreation';
import { useSingleOrderCreationForm } from './useSingleOrderCreationForm';
import { Contract } from '@models/contract';
import { OrderType } from '@models/orders';
import { Contact } from '@models/contact';
import { UseQueryResult } from '@tanstack/react-query';

type UseSingleOrderCreationFormReturnValue = ReturnType<typeof useSingleOrderCreationForm>;

type SingleOrderCreationContextProps = Omit<
  UseSingleOrderCreationFormReturnValue,
  'restoreValues'
> & {
  availableOrderTypes: OrderType[];
  contract: Contract;
  form: CreationForm['forms'][number];
  contactsDataHandler: UseQueryResult<Contact[], Error>;
};

export const SingleOrderCreationContext = createContext<SingleOrderCreationContextProps>({
  form: {} as CreationForm['forms'][number],
  missingRequiredFields: [],
  availableOrderTypes: [],
  contract: {} as Contract,
  contactsDataHandler: {} as UseQueryResult<Contact[], Error>,
  setComments: () => undefined,
  setEquipmentQuantity: () => undefined,
  setEstimatedWasteQuantity: () => undefined,
  setLocationPointAdditionalInfo: () => undefined,
  setOrderLevelReference: () => undefined,
  setSelectedOrderType: () => undefined,
  setSpecifiedWasteDescription: () => undefined,
  setContactPersonId: () => undefined,
  validate: () => true,
  isFieldRequired: () => true,
});

interface SingleOrderCreationContextProviderProps {
  children: ReactNode;
  contract: Contract;
  availableOrderTypes: OrderType[];
  contractIdentifier: string;
  orderForm: CreationForm['forms'][number];
  contactsDataHandler: UseQueryResult<Contact[], Error>;
}

export const SingleOrderCreationContextProvider: FC<SingleOrderCreationContextProviderProps> = ({
  contract,
  contactsDataHandler,
  availableOrderTypes,
  contractIdentifier,
  orderForm,
  children,
}) => {
  const creationHandler = useSingleOrderCreationForm(orderForm, contractIdentifier);

  const contextValue: SingleOrderCreationContextProps = {
    ...creationHandler,
    availableOrderTypes,
    contract,
    form: orderForm,
    contactsDataHandler,
  };

  return (
    <SingleOrderCreationContext.Provider value={contextValue}>
      {children}
    </SingleOrderCreationContext.Provider>
  );
};
