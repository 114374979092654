import {
  Box,
  BoxProps,
  ContentText,
  ContentTextProps,
  colors,
  fontSizes,
} from '@fortum/elemental-ui';
import { FC, ReactNode } from 'react';
import { ChildrenWrapper } from './styles';
import { Testable } from '@components/types';

interface VerticalListItemProps extends Testable {
  label: string;
  children: ReactNode;
}

const containerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
};

const labelStyles: ContentTextProps = {
  fontSize: fontSizes.xs.default,
  color: colors.sonicGrey,
};

export const VerticalListItem: FC<VerticalListItemProps> = ({
  label,
  children,
  'data-testid': testId,
}) => (
  <Box {...containerStyles} data-testid={testId}>
    <ContentText {...labelStyles}>{label}</ContentText>

    <ChildrenWrapper>{children}</ChildrenWrapper>
  </Box>
);
