import { Box, Button, ContentText, fontSizes, IconFilter, SelectItem } from '@fortum/elemental-ui';
import { MainFiltersLayout } from '../Filters/MainFiltersLayout';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ChangeEvent, forwardRef, useCallback, useContext } from 'react';
import { ContentContainer, StyledSelect } from '@routes/orders/components/PageHeader/style';
import { SortByValue, sortByValues } from '../config';
import { FILTER_MAX_WIDTH } from '../Filters/styles';
import { SelectChangeEvent } from '@components/types';
import { getTranslatedSelectItemName } from '../utils';
import { OrdersFiltersContext } from '../OrdersFiltersContextProvider';

interface PageHeaderProps {
  pageName: string;
  toggleFiltersPane: () => void;
}

const sortByItems: SelectItem<SortByValue>[] = sortByValues.map(value => ({
  name: `orders:table.${value}`,
  value,
}));

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  ({ pageName, toggleFiltersPane }, ref) => {
    const filtersContext = useContext(OrdersFiltersContext);

    if (!filtersContext) {
      throw Error('Missing orders filters context');
    }

    const { t } = useTranslation<Namespace[]>(['common', 'orders']);

    const onSortByChange = useCallback(
      (e: ChangeEvent<SelectChangeEvent<SortByValue>>) => filtersContext.setSortBy(e.target.value),
      [],
    );

    return (
      <ContentContainer ref={ref}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ContentText fontSize={fontSizes.xl}>{pageName}</ContentText>
          <Button
            variant="condensed"
            leftIcon={<IconFilter />}
            status="plain"
            onClick={toggleFiltersPane}
          >
            {t('common:moreFilters')}
          </Button>
        </Box>

        <MainFiltersLayout />

        <StyledSelect
          id="ordersTableSortBy"
          name="ordersTableSortBy"
          label={t('orders:sortBy')}
          maxWidth={FILTER_MAX_WIDTH}
          variant="condensed"
          borderStyle="full"
          onChange={onSortByChange}
          selected={filtersContext.sortBy}
          items={sortByItems}
          renderItem={getTranslatedSelectItemName}
        />
      </ContentContainer>
    );
  },
);

PageHeader.displayName = 'PageHeader';
