import styled from 'styled-components';
import { Search, Select, spacing } from '@fortum/elemental-ui';
import { staticBorderStyles, horizontalContainer } from '@components/styles';
import { Multiselect } from '@components/Multiselect';

export const FILTER_MIN_WIDTH = '7rem';
export const FILTER_MAX_WIDTH = '25rem';
export const FILTERS_GAP = spacing.xs;

export const SearchWithBorder = styled(Search)`
  input {
    ${staticBorderStyles('2px')};
  }
`;

export const FiltersContainer = styled.div`
  ${horizontalContainer};
  column-gap: ${FILTERS_GAP};
  justify-content: flex-end;

  & > div {
    flex: 1 1 0px;
    max-width: ${FILTER_MAX_WIDTH};
  }
`;

export const ShrinkableSelect: typeof Select = styled(Select)`
  min-width: ${FILTER_MIN_WIDTH};
`;

export const ShrinkableMultiselect: typeof Multiselect = styled(Multiselect)`
  min-width: ${FILTER_MIN_WIDTH};
`;
