import { translate } from '@utils/internationalization';
import { Namespace } from '@config/i18n';
import { SelectItem } from '@fortum/elemental-ui';
import { OrdersFiltersHandlerReturnType } from './useOrdersFiltersHandler';
import { compact, uniqBy } from 'lodash';
import { HandlingStatusGroup, HandlingStatusMap, HandlingStatusesGroups } from '@models/orders';
import { OrdersSelectedFiltersWithSearch } from '@models/filters';
import { t } from 'i18next';
import { hasValue } from '@utils/dataOperations';

export const mapToSelectItems = (data: string[]): SelectItem<string>[] =>
  data.map(datum => ({
    value: datum,
    name: datum,
  }));

export const mapToTranslatedSelectItems = (
  data: string[],
  namespace: Namespace,
  subNamespace: string,
): SelectItem<string>[] =>
  data.map(datum => ({
    value: datum,
    name: translate(datum.toUpperCase(), namespace, subNamespace),
  }));

/**
 * Different order status codes can have same translations, e.g. O27_PICKED and O29_DELIVE are both translated to 'In transportation'
 * uniqBy function was used, so that there are no duplicates on the Order Status Multiselect list
 */
export const mapOrderStatusToSelectItems = (
  status: string[],
): SelectItem<HandlingStatusGroup>[] => {
  const allStatuses = mapToTranslatedSelectItems(status, 'domain', 'orderHandlingStatus');
  const itemsUniqByTranslatedName = uniqBy(allStatuses, status => status.name);

  return compact(
    itemsUniqByTranslatedName.map(item => {
      const statusGroup = getStatusGroup(item.value);
      if (!statusGroup) {
        return;
      }
      return { ...item, value: statusGroup };
    }),
  );
};

export class FiltersAvailabilityValidator {
  constructor(
    private selectItems: OrdersFiltersHandlerReturnType['selectItems'],
    private isLoading: boolean,
    private isError: boolean,
    private precedence: OrdersFiltersHandlerReturnType['filtersPrecedence'],
  ) {}

  filterDisabled = (key: keyof OrdersFiltersHandlerReturnType['selectItems']) => {
    if (
      this.selectItems[key].length === 0 ||
      (this.isLoading && this.precedence.length === 0) ||
      this.isError
    ) {
      return true;
    }

    return this.isLoading && this.precedence[this.precedence.length - 1].key !== key;
  };
}

const getStatusGroup = (orderStatusCode: string) =>
  HandlingStatusesGroups.find(statusGroup =>
    HandlingStatusMap[statusGroup].includes(orderStatusCode),
  );

export const getSelectedFiltersAmount = (
  selectedFiltersValues: OrdersSelectedFiltersWithSearch,
  searchIsCounted = false,
) =>
  Object.entries(selectedFiltersValues)
    .map(([key, values]) => {
      if (key === 'search') return searchIsCounted ? 1 : 0;

      if (typeof values === 'string' && values.length > 0) {
        return 1;
      }
      return values.length;
    })
    .reduce((prev, current) => prev + current);

export const getTranslatedSelectItemName = (item: SelectItem<string>) => t(item.name);

export const getExecutionDate = (row: { executionDate: string; orderHandlingStatus: string }) =>
  HandlingStatusMap.IN_PROCESS.includes(row.orderHandlingStatus) && hasValue(row.executionDate)
    ? `${row.executionDate.slice(0, 10)} ${t('common:estimation')}`
    : row.executionDate.slice(0, 10);
