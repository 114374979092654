import { useMemo, useState } from 'react';
import { DashboardReportObjects, getDashboardReportConfig } from '@config/qlik';
import { useTranslation } from 'react-i18next';
import { QlikWrapper } from '@components/qlik';
import { fallbackLang, isSupportedLanguage } from '@common/languages';
import { Report } from './report';
import { Namespace } from '@config/i18n';
import { useNotification, Notification } from '@components/Notification';
import { CenteredLoader } from '@components/layout/styles';
import { ReportProps } from '../types';

const DashboardReport = () => {
  const { t, i18n } = useTranslation<Namespace[]>(['errors', 'reports']);
  const [errorMessage, setErrorMessage] = useState(t('errors:dataExport'));
  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();

  const reportConfig = useMemo(() => {
    const currentLanguage = isSupportedLanguage(i18n.language) ? i18n.language : fallbackLang;
    return getDashboardReportConfig(currentLanguage);
  }, [i18n.language]);

  const exportDataHandler = useMemo<ReportProps<DashboardReportObjects>['exportDataHandler']>(
    () => ({
      objectId: reportConfig.objectIds.dynamicTable.table,
      onError: () => {
        setErrorMessage(t('errors:dataExport'));
        setDisplayErrorNotification();
      },
      onEmptyData: () => {
        setErrorMessage(t('errors:reports.emptyVisData.dashboard'));
        setDisplayErrorNotification();
      },
    }),
    [setDisplayErrorNotification, reportConfig, i18n.language],
  );

  return (
    <>
      <QlikWrapper config={reportConfig.qlikAppConfig} fallback={<CenteredLoader />}>
        <Report
          defaultSelections={reportConfig.defaultSelections}
          exportDataHandler={exportDataHandler}
          objectIds={reportConfig.objectIds}
          mandatorySelections={reportConfig.mandatorySelections}
        />
      </QlikWrapper>

      <Notification
        type="error"
        message={errorMessage}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="30rem"
      />
    </>
  );
};

export default DashboardReport;
