import {
  ContentText,
  Heading,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
  Box,
  colors,
  ForwardLink,
} from '@fortum/elemental-ui';
import { MainContainer } from '@routes/errors/general/styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { PageNotFoundImage } from '@assets/images/PageNotFoundImage';
import { paths } from '@config/routes';
import { FC } from 'react';

export const GeneralError: FC = () => {
  const { t } = useTranslation<Namespace>('errors');
  return (
    <Box
      display="flex"
      justifyContent="center"
      backgroundColor={colors.snowWhite}
      height="100%"
      overflow="auto"
    >
      <MainContainer>
        <Heading level={3} textAlign="center">
          {t('generalError.header')}
        </Heading>
        <PageNotFoundImage />
        <Box display="flex" flexDirection="column">
          <Heading
            level={5}
            fontWeight={fontWeights.bold}
            lineHeight={1}
            textAlign="center"
            paddingBottom={spacing.xxs}
          >
            {t('generalError.subheader')}
          </Heading>
          <ContentText
            fontSize={fontSizes.l}
            fontWeight={fontWeights.regular}
            lineHeight={lineHeights.normal}
            textAlign="center"
            paddingBottom={spacing.xxxs}
          >
            {t('generalError.message')}
          </ContentText>
          <ForwardLink href={paths.main} fontSize={fontSizes.l} margin="auto">
            {t('generalError.link')}
          </ForwardLink>
        </Box>
      </MainContainer>
    </Box>
  );
};
