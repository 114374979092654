import { QlikObject } from '@components/qlik';
import { BoxPropValue, Col, Row } from '@fortum/elemental-ui';
import { QlikButton } from '../styles';
import { DashboardReportObjects } from '@config/qlik';
import { FC } from 'react';

const SwitchButtonsRow: FC<{
  height: BoxPropValue;
  buttonsIds: DashboardReportObjects['buttons'];
}> = ({ height, buttonsIds }) => (
  <Row height={height}>
    <Col xxs={12} l={6}>
      <QlikButton
        id={buttonsIds.switchToMonthly}
        padding="0px"
        dataTestId="button-switch-to-monthly"
      />
    </Col>
    <Col xxs={12} l={6}>
      <QlikButton
        id={buttonsIds.switchToCumulative}
        padding="0px"
        dataTestId="button-switch-to-cumulative"
      />
    </Col>
  </Row>
);

export const FirstRow: FC<{
  marginVertical?: BoxPropValue;
  height?: BoxPropValue;
  columnsPaddingHorizontal?: BoxPropValue;
  objectIds: DashboardReportObjects;
}> = ({ marginVertical, height, columnsPaddingHorizontal, objectIds }) => {
  return (
    <Row marginVertical={marginVertical}>
      <Col l={4} height={height} paddingHorizontal={columnsPaddingHorizontal}>
        <Row height="90%">
          <Col xxs={12}>
            <QlikObject
              id={objectIds.charts.costByServiceGroup}
              dataTestId="chart-cost-by-service-group"
            />
          </Col>
        </Row>
        <SwitchButtonsRow height="10%" buttonsIds={objectIds.buttons} />
      </Col>
      <Col l={8} height={height} paddingHorizontal={columnsPaddingHorizontal}>
        <QlikObject
          id={objectIds.charts.costsAndCreditsOverTime}
          dataTestId="chart-cost-and-credits-over-time"
        />
      </Col>
    </Row>
  );
};
