import { paths } from '@config/routes';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmationModal,
  NavigationHeaderWithMarginBottom,
  OrdersCreationContext,
} from '../components';
import { SummaryTable } from './components/SummaryTable/SummaryTable';
import { Container, ButtonsContainer } from './components/styles';
import { CreationDetails } from './components/CreationDetails';
import { Button, Checkbox, ContentText, ErrorMessage, useToggle } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { VerticalContainer } from '@components/styles';
import { discardButtonStyles, backButtonStyles, primaryButtonStyles } from './components/styles';
import { SummaryPreviewHeader } from './components/SummaryPreviewHeader/SummaryPreviewHeader';
import { SummaryHeader } from './SummaryHeader/SummaryHeader';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';

interface SummaryPreviewProps {
  togglePreview: () => void;
}
//TODO: connect submit to BE
const SummaryPreview: FC<SummaryPreviewProps> = ({ togglePreview }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const headerLinkProps = useMemo<PageNavigationHeaderProps['link']>(
    () => ({
      id: 'navigate-to-home-link',
      description: t('orderCreation:returnToPreviousPage'),
      onClick: () => navigate(-1),
    }),
    [i18n.language],
  );

  const checkBoxMessage = `* ${t('orderCreation:summaryPage.confirmationCheckbox.label')}`;

  const { clearForms } = useContext(OrdersCreationContext);

  const [modalOpen, toggleModalOpen] = useToggle(false);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [displayCheckboxError, setDisplayCheckboxError] = useState(false);

  const toggleCheckbox = useCallback(
    () =>
      setCheckboxChecked(prev => {
        if (!prev) setDisplayCheckboxError(false);

        return !prev;
      }),
    [],
  );

  const navigateBack = useCallback(() => navigate(-1), []);
  const goToOrdersCreation = useCallback(() => {
    clearForms();
    navigate(paths.ordersCreation);
  }, []);

  const submit = useCallback(() => {
    if (checkboxChecked) togglePreview();
    else setDisplayCheckboxError(true);
  }, [checkboxChecked]);

  return (
    <>
      <NavigationHeaderWithMarginBottom
        link={headerLinkProps}
        title={t('orderCreation:pageHeader')}
      />
      <Container>
        <SummaryPreviewHeader />
        <CreationDetails />

        <SummaryTable />

        <VerticalContainer>
          <Checkbox name="confirmVerification" label={checkBoxMessage} onClick={toggleCheckbox} />
          <ErrorMessage active={displayCheckboxError}>
            <ContentText>{t('orderCreation:summaryPage.checkboxError')}</ContentText>
          </ErrorMessage>
        </VerticalContainer>

        <ButtonsContainer>
          <Button
            data-testid="discard-creation-button"
            {...discardButtonStyles}
            onClick={toggleModalOpen}
          >
            {t('common:discard')}
          </Button>
          <Button data-testid="back-to-forms-button" {...backButtonStyles} onClick={navigateBack}>
            {t('common:back')}
          </Button>
          <Button data-testid="submit-creation-button" {...primaryButtonStyles} onClick={submit}>
            {t('common:send')}
          </Button>
        </ButtonsContainer>
      </Container>

      <ConfirmationModal
        headerText={t('common:delete')}
        messageText={t('orderCreation:summaryPage.discardModal.message')}
        opened={modalOpen}
        onClose={toggleModalOpen}
        onConfirmClick={goToOrdersCreation}
      />
    </>
  );
};

const Summary: FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const { clearForms } = useContext(OrdersCreationContext);

  const navigateToOrdersOverview = useCallback(() => navigate(paths.orders), []);

  const goToOrdersCreation = useCallback(() => {
    clearForms();
    navigate(paths.ordersCreation);
  }, []);

  return (
    <Container>
      <SummaryHeader onClick={goToOrdersCreation} />

      <CreationDetails />

      <SummaryTable />

      <ButtonsContainer>
        <Button
          data-testid="navigate-to-orders-button"
          {...primaryButtonStyles}
          onClick={navigateToOrdersOverview}
        >
          {t('orderCreation:summaryPage.goToOrdersOverview')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export const SummaryPage: FC = () => {
  const navigate = useNavigate();

  const { creationForms } = useContext(OrdersCreationContext);
  const [preview, togglePreview] = useToggle(true);

  useEffect(() => {
    if (creationForms.length === 0) navigate(paths.ordersCreation);
  }, []);

  return preview ? <SummaryPreview togglePreview={togglePreview} /> : <Summary />;
};
