import { SelectChangeEvent, Stylable } from '@components/types';
import {
  SelectProps as ElementalSelectProps,
  MultiselectProps as ElementalMultiselectProps,
  Select as ElementalSelect,
  Multiselect as ElementalMultiselect,
} from '@fortum/elemental-ui';
import { ChangeEvent, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

type PickedElementalSelectProps = Pick<
  ElementalSelectProps<string>,
  | 'borderStyle'
  | 'disabled'
  | 'displayValue'
  | 'error'
  | 'errorMessage'
  | 'id'
  | 'items'
  | 'maxWidth'
  | 'name'
  | 'placeholder'
  | 'selected'
  | 'size'
  | 'width'
  | 'variant'
>;

type PickedElementalMultiselectProps = Pick<ElementalMultiselectProps<string>, 'filterItems'>;

type SelectProps = Stylable &
  PickedElementalSelectProps &
  PickedElementalMultiselectProps & {
    onSelectedItemChange: (selectedItem: string) => void;
    label: string;
  };

export const Select = ({
  name,
  disabled,
  selected,
  onSelectedItemChange,
  filterItems,
  displayValue,
  className,
  ...rest
}: SelectProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isInputUsed, setIsInputUsed] = useState(false);

  const { selectName, multiselectName, multiselectSelector } = useMemo(() => {
    const multiselectName = `${name}-multiselect`;

    return {
      selectName: `${name}-select`,
      multiselectName,
      multiselectSelector: `#${multiselectName}`,
    };
  }, [name]);

  useLayoutEffect(() => {
    if (!isInputUsed || disabled) return;

    const currentRef = containerRef.current;
    const multiselectElement = currentRef?.querySelector<HTMLElement>(multiselectSelector);

    const listener = () => {
      setIsInputUsed(false);
    };

    multiselectElement?.focus();
    multiselectElement?.addEventListener('blur', listener, { once: true });

    return () =>
      currentRef
        ?.querySelector<HTMLElement>(multiselectSelector)
        ?.removeEventListener('blur', listener);
  }, [isInputUsed, multiselectSelector, disabled]);

  const onChange = useCallback(
    (e: ChangeEvent<SelectChangeEvent<string[]>>) => {
      const currentValues = e.target.value.filter(value => selected !== value);

      onSelectedItemChange(currentValues[0] || '');
      setIsInputUsed(false);
    },
    [onSelectedItemChange, selected],
  );

  const onSelectClick = useCallback(() => !disabled && setIsInputUsed(true), [disabled]);

  return (
    <div ref={containerRef} className={className}>
      {isInputUsed ? (
        <ElementalMultiselect
          name={multiselectName}
          selected={selected ? [selected] : []}
          onChange={onChange}
          filterItems={filterItems}
          {...rest}
        />
      ) : (
        <ElementalSelect
          name={selectName}
          displayValue={displayValue}
          selected={selected}
          onClick={onSelectClick}
          {...rest}
        />
      )}
    </div>
  );
};
