import { DATA_SOURCE_PREFIX, DATA_SOURCE_DELIMITER } from './consts';

export const getDataSourceByCountry = (countryCode: string) =>
  DATA_SOURCE_PREFIX + DATA_SOURCE_DELIMITER + countryCode;

export const getCountryCodeFromDataSource = (dataSource: string | undefined) => {
  const dataSourceElements = dataSource?.split(DATA_SOURCE_DELIMITER);

  return dataSourceElements && dataSourceElements.length > 1
    ? dataSourceElements?.pop()
    : undefined;
};
