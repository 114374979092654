import { translate } from '@utils/internationalization';
import { NO_DATA_BADGE } from './consts';
import { AdrHazardClass, isAdrHazardClass, environmentallyHazardous } from '@models/waste';
import { compact } from 'lodash';

export const getHPCodeDisplayableDescription = (code: string) => {
  const codeDescription = translate(code, 'domain', 'hpCode');

  return code.toLowerCase() === codeDescription.toLowerCase() ? code : `${code} ${codeDescription}`;
};

export const getHazardousInfoDescription = (hazardous: boolean | null) =>
  hazardous === null
    ? NO_DATA_BADGE
    : translate(`${hazardous}`, 'domain', 'ewcCodeWasteIsHazardous');
interface AdrDetails {
  hazardClass: AdrHazardClass | undefined;
  description: string;
}

export const getAdrDetails = (adr: string): AdrDetails => {
  const adrSubstring = adr.substring(0, 3);
  const hazardClass = isAdrHazardClass(adrSubstring) ? adrSubstring : undefined;

  return {
    hazardClass,
    description: getAdrDescription(adr, hazardClass),
  };
};
const getAdrDescription = (adr: string, hazardClass: AdrHazardClass | undefined) => {
  if (!hazardClass) {
    return adr;
  }

  const translatedADR = translate(hazardClass, 'domain', 'adr');

  return adr === environmentallyHazardous ? translatedADR : `${adr} ${translatedADR}`;
};

export const getFullUnDescription = (
  unNumber: string | null,
  packagingGroup: string | null,
  hazMatTypeDescription: string | null,
) => {
  const descriptionElements = compact([unNumber, packagingGroup, hazMatTypeDescription]);

  return descriptionElements.length === 0 ? NO_DATA_BADGE : descriptionElements.join(', ');
};
