import { Namespace } from '@config/i18n';
import { Container } from '@routes/orders/style';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersPane, OrdersTableLayout, PageHeader } from './components';

const OrdersOverview: FC = () => {
  const [filtersPaneOpen, toggleFiltersPane] = useState<boolean>(false);

  const { t } = useTranslation<Namespace>('orders');
  const pageHeaderContainerRef = useRef<HTMLDivElement>(null);

  const [pageHeaderHeight, setPageHeaderHeight] = useState<string | undefined>(
    pageHeaderContainerRef.current?.clientHeight
      ? `${pageHeaderContainerRef.current?.clientHeight}px`
      : undefined,
  );

  useLayoutEffect(() => {
    if (!pageHeaderContainerRef?.current) return;

    const observer = new ResizeObserver(entries => {
      setPageHeaderHeight(entries[0].borderBoxSize[0].blockSize.toString() + 'px');
    });
    observer.observe(pageHeaderContainerRef?.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Container>
      <PageHeader
        ref={pageHeaderContainerRef}
        pageName={t('ordersOverview')}
        toggleFiltersPane={() => toggleFiltersPane(true)}
      />
      <OrdersTableLayout pageHeaderHeight={pageHeaderHeight} />

      <FiltersPane open={filtersPaneOpen} onClose={() => toggleFiltersPane(false)} />
    </Container>
  );
};

export default OrdersOverview;
