import {
  Modal,
  px2rem,
  lineHeights,
  spacing,
  HeadingProps,
  BoxProps,
  ContentTextProps,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  max-width: ${px2rem(880)};
  width: 100%;
  margin-right: min(152px, 20%);
  margin-left: min(152px, 20%);
  display: flex;
`;

export const headingTextStyles: HeadingProps = {
  level: 4,
  lineHeight: lineHeights.normal,
  marginTop: spacing.xxs,
  marginBottom: spacing.xxs,
};

export const contentTextStyles: ContentTextProps = {
  marginBottom: spacing.xs,
};

export const buttonsContainerStyles: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  gap: spacing.xs,
};
