import { Testable } from '@components/types';
import { Box, ContentText, fontSizes } from '@fortum/elemental-ui';
import { NO_DATA_BADGE, displayedValue } from '@utils/dataOperations';
import { splitStreetAddressOnTheZipCode } from '@utils/dataOperations/address';
import { FC } from 'react';

interface LocationPointDetailsProps extends Testable {
  companyName: string | null | undefined;
  address: string | null | undefined;
}

export const LocationPointDetails: FC<LocationPointDetailsProps> = ({
  companyName,
  address,
  'data-testid': dataTestId,
}) => {
  return !companyName && !address ? (
    NO_DATA_BADGE
  ) : (
    <Box display="flex" flexDirection="column" data-testid={dataTestId}>
      {[companyName, ...splitStreetAddressOnTheZipCode(address)]
        .map(displayedValue)
        .map((value, index) => (
          <ContentText key={index} fontSize={fontSizes.s}>
            {value}
          </ContentText>
        ))}
    </Box>
  );
};
