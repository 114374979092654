import {
  QlikError,
  QlikRawError,
  QlikRootApiConfig,
  qlikRawErrorMessages,
} from '@components/qlik/types';
import schema from 'enigma.js/schemas/12.170.2.json';

export const getProtocol = (isSecure: boolean) => (isSecure ? 'https' : 'http');

export const getQlikBaseURL = (config: QlikRootApiConfig) => {
  const hostWithOptionalPort = config.port ? `${config.host}:${config.port}` : config.host;

  return `${getProtocol(config.isSecure)}://${hostWithOptionalPort}${config.prefix ? '/' + config.prefix : ''}`;
};

export const appendSlash = (prefix: string | undefined) => {
  if (!prefix?.trim() || prefix === '/') return '/';

  return `/${prefix}/`;
};

export const toQlikError = (error: QlikRawError) => {
  if (error.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_ACCESS_DENIED) {
    return new QlikError(error.message, 'ACCESS_DENIED');
  }

  if (
    error.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_CONNECTION_LOST ||
    error.message === qlikRawErrorMessages.proxySessionTimedOut ||
    error.message === qlikRawErrorMessages.proxySessionClosed
  ) {
    return new QlikError('Session closed', 'SESSION_TIMED_OUT');
  }

  return error.message === qlikRawErrorMessages.noData
    ? null
    : new QlikError('Failed to open the app', 'APP_OPENING_ERROR');
};
