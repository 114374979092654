import { Namespace } from '@config/i18n';
import {
  Box,
  BoxProps,
  ContentText,
  ContentTextProps,
  IconDistrictHeatingOutage,
  IconMassMarketTested,
  IconProps,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const iconStyle: IconProps = { size: 48, color: colors.silverGrey, mb: spacing.xxxs };
const boxStyle: BoxProps = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
const headingStyle: ContentTextProps = { fontSize: fontSizes.xl.s, color: colors.emperorGrey };
const messageStyle: ContentTextProps = {
  fontSize: fontSizes.m.default,
  color: colors.silverGrey,
  maxWidth: '350px',
  textAlign: 'center',
};

interface Props {
  headingKey: 'noResults' | 'failedToLoadData';
  messageKey: 'tryToAdjust' | 'tryAgainOrContactUs';
}

const InfoNote: FC<Props> = ({ headingKey, messageKey }) => {
  const { t } = useTranslation<Namespace>('common');

  const icon =
    headingKey === 'noResults' ? (
      <IconMassMarketTested {...iconStyle} />
    ) : (
      <IconDistrictHeatingOutage {...iconStyle} />
    );

  return (
    <Box {...boxStyle}>
      {icon}
      <ContentText {...headingStyle}>{t(headingKey)}</ContentText>
      <ContentText {...messageStyle}>{t(messageKey)}</ContentText>
    </Box>
  );
};

export const NoResultsNote: FC = () => <InfoNote headingKey="noResults" messageKey="tryToAdjust" />;

export const FailedToLoadDataNote: FC = () => (
  <InfoNote headingKey="failedToLoadData" messageKey="tryAgainOrContactUs" />
);
