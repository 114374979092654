import { HorizontalContainer, VerticalContainer } from '@components/styles';
import {
  ButtonProps,
  ContentTextProps,
  IconProps,
  colors,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  gap: ${spacing.xxs};
`;

export const buttonStyles: ButtonProps = {
  status: 'secondary',
  variant: 'condensed',
  alignSelf: 'flex-start',
};

export const mediumTextStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
  mr: spacing.xxxxs,
  color: colors.oceanGreen,
};

export const textStyles: ContentTextProps = {
  display: 'inline-flex',
  color: colors.oceanGreen,
};

export const iconStyles: IconProps = {
  color: colors.oceanGreen,
  mr: spacing.xxxs,
};

export const HeaderSubContainer = styled(HorizontalContainer)`
  height: var(--page-header-height);
  align-items: center;
  gap: ${spacing.xxxs};
`;
