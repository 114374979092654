import type { MapLocation } from '@models/home';

export type StatisticLoggerUserRole = 'external' | 'internalAdmin' | 'internal';

export interface InsightEvents {
  successfulLogin: { userRole: StatisticLoggerUserRole };
  loginPerCompany: { companyName: string };
  // TODO: rename and use or remove, decide during the implementation of the bottom list
  searchUsedInOrders: { searchedValue: string };

  // Home
  homeLocationElementClicked: {
    locationBusinessPartnerName: MapLocation['businessPartnerName'];
  };
  // TODO: implement everything to the bottom
  homeQuickAccessOrderTrackingTrackOrdersClicked: undefined;
  homeQuickAccessMyServicesServiceViewClicked: undefined;
  homeQuickAccessReportsDashboardClicked: undefined;
  homeQuickAccessReportsWasteDetailsClicked: undefined;
  homeQuickAccessReportsCostDetailsClicked: undefined;
  homeInfoSafetyTrainingClicked: undefined;
  homeInfoFollowUsClicked: undefined;
  homeInfoContactUsClicked: undefined;

  // Order_Tracking
  /**
   * TODO: pay attention to details of logging
   *
   * These filters are applied automatically when user selects a value.
   *
   * These filters are also a part of filters drawer.
   */
  ordersTrackingFilterTimePeriodSelected: { selectedValue: string };
  ordersTrackingFilterStatusSelected: { selectedValue: string };
  ordersTrackingFilterWasteTypeSelected: { selectedValue: string };

  /**
   * TODO: pay attention to details of logging
   *
   * These filters could be applied only from the filters drawer, so we want to record
   * them only when user clicks `Apply` button.
   */
  ordersTrackingFilterOrderTypeSelected: { selectedValue: string };
  ordersTrackingFilterTransportTypeSelected: { selectedValue: string };
  ordersTrackingFilterEquipmentTypeSelected: { selectedValue: string };
  ordersTrackingFilterCompanySelected: { selectedValue: string };
  ordersTrackingFilterCitySelected: { selectedValue: string };

  ordersTrackingSortingSelected: { selectedValue: string };

  ordersTrackingSearchUsedDebounced: { searchedValue: string };

  // My_Services
  myServicesFilterWasteTypeSelected: { selectedValue: string };
  myServicesFilterEquipmentTypeSelected: { selectedValue: string };

  myServicesSearchUsedDebounced: { searchedValue: string };

  // Order_Details
  orderDetailsServiceDetailsClicked: undefined;

  // Service_Details
  serviceDetailsOpenOrdersClicked: undefined;
}

export type InsightEventName = keyof InsightEvents;
export type InsightEventProps<T extends InsightEventName> = InsightEvents[T];

export const insightsEventToTitleMap: Record<InsightEventName, string> = {
  successfulLogin: 'Successful logged in',
  loginPerCompany: 'Company user logged in',

  // orders
  searchUsedInOrders: 'Search used in orders overview',

  // Home
  homeLocationElementClicked: 'SINGLE_CLICK: Home - location element',
  homeQuickAccessOrderTrackingTrackOrdersClicked:
    'SINGLE_CLICK: Home - quick access - order tracking - track orders',
  homeQuickAccessMyServicesServiceViewClicked:
    'SINGLE_CLICK: Home - quick access - my services - service view',
  homeQuickAccessReportsDashboardClicked: 'SINGLE_CLICK: Home - quick access - reports - dashboard',
  homeQuickAccessReportsWasteDetailsClicked:
    'SINGLE_CLICK: Home - quick access - reports - waste details',
  homeQuickAccessReportsCostDetailsClicked:
    'SINGLE_CLICK: Home - quick access - reports - cost details',
  homeInfoSafetyTrainingClicked: 'SINGLE_CLICK: Home - info - safety training',
  homeInfoFollowUsClicked: 'SINGLE_CLICK: Home - info - follow us',
  homeInfoContactUsClicked: 'SINGLE_CLICK: Home - info - contact us',

  // Order_Tracking
  ordersTrackingFilterTimePeriodSelected: 'SELECT: Order_Tracking - filter - time period',
  ordersTrackingFilterStatusSelected: 'SELECT: Order_Tracking - filter - status',
  ordersTrackingFilterWasteTypeSelected: 'SELECT: Order_Tracking - filter - waste type',
  ordersTrackingFilterOrderTypeSelected: 'SELECT: Order_Tracking - filter - order type',
  ordersTrackingFilterTransportTypeSelected: 'SELECT: Order_Tracking - filter - transport type',
  ordersTrackingFilterEquipmentTypeSelected: 'SELECT: Order_Tracking - filter - equipment type',
  ordersTrackingFilterCompanySelected: 'SELECT: Order_Tracking - filter - company',
  ordersTrackingFilterCitySelected: 'SELECT: Order_Tracking - filter - city',

  ordersTrackingSortingSelected: 'SELECT: Order_Tracking - sorting',

  ordersTrackingSearchUsedDebounced: 'SEARCH_USED: Order_Tracking',

  // My_Services
  myServicesFilterWasteTypeSelected: 'SELECT: My_Services - filter - waste type',
  myServicesFilterEquipmentTypeSelected: 'SELECT: My_Services - filter - equipment type',

  myServicesSearchUsedDebounced: 'SEARCH_USED: My_Services',

  // Order_Details
  orderDetailsServiceDetailsClicked: 'SINGLE_CLICK: Order_Details - service details',

  // Service_Details
  serviceDetailsOpenOrdersClicked: 'SINGLE_CLICK: Service_Details - open orders',
};
