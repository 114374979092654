import { verticalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  ${verticalContainer};

  padding: ${spacing.m} ${spacing.xs};
  height: 100%;
  box-sizing: border-box;
`;
