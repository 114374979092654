import { AdrPictogram } from '@components/image/adr-pictogram';
import {
  Box,
  BoxProps,
  ContentText,
  ContentTextProps,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui';
import { FC } from 'react';
import { NO_DATA_BADGE, getAdrDetails } from '@utils/dataOperations';
import { environmentallyHazardous } from '@models/waste';
import { Testable } from '@components/types';

interface ADRLabelsProps extends Testable {
  hazardousMaterialClasses: string[];
  environmentalHazardous: boolean | null;
}

const containerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xxxs,
};

const innerContainerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: spacing.xxxs,
};

const descriptionTextStyles: ContentTextProps = {
  color: colors.inkGrey,
  fontSize: fontSizes.s,
};

export const ADRLabels: FC<ADRLabelsProps> = ({
  hazardousMaterialClasses,
  environmentalHazardous,
  'data-testid': dataTestId,
}) => {
  const adrs = [
    ...hazardousMaterialClasses,
    ...(environmentalHazardous ? [environmentallyHazardous] : []),
  ];

  if (adrs.length === 0) {
    return NO_DATA_BADGE;
  }

  return (
    <Box {...containerStyles} data-testid={dataTestId}>
      {adrs.map(getAdrDetails).map(({ description, hazardClass }, index) => (
        <Box {...innerContainerStyles} key={index} data-testid={`adr-item-${hazardClass}`}>
          <AdrPictogram hazardClass={hazardClass} />
          <ContentText {...descriptionTextStyles}>{description}</ContentText>
        </Box>
      ))}
    </Box>
  );
};
