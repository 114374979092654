import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikReportConfig, ReportDefaultSelections, ReportIds } from './types';
import { qlikAppConfig } from './connection';
import { getUserAccessInfo } from '@utils/user';

// TODO: uncomment when Norvegian translations will be finished. Add Norwegian to CompanyNameSelectionFieldName

const WasteQtyReports: ReportIds = {
  en: process.env.REACT_APP_WASTE_QTY_REPORT_ID_EN ?? '',
  fi: process.env.REACT_APP_WASTE_QTY_REPORT_ID_FI ?? '',
  sv: process.env.REACT_APP_WASTE_QTY_REPORT_ID_SV ?? '',
  // nb: process.env.REACT_APP_WASTE_QTY_REPORT_ID_NO ?? '',
};

export const WasteQtyReportDefaultSelections: ReportDefaultSelections = {
  en: [
    {
      fieldName: 'Company Information',
      values: ['Company name'],
    },
    {
      fieldName: 'Waste Details',
      values: ['Waste description'],
    },
    {
      fieldName: 'Measures',
      values: ['Material quantity'],
    },
    {
      fieldName: 'Execution Year',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  fi: [
    { fieldName: 'Yrityksen tiedot', values: ['Yrityksen nimi'] },
    {
      fieldName: 'Jätetiedot',
      values: ['Jätteen kuvaus'],
    },
    {
      fieldName: 'Määrä',
      values: ['Vastaanotettu määrä'],
    },
    {
      fieldName: 'Tapahtumavuosi',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  sv: [
    {
      fieldName: 'År',
      values: [`${new Date().getFullYear()}`],
    },
    {
      fieldName: 'Avfallsdetaljer',
      values: ['Avfallsbeskrivning'],
    },
    {
      fieldName: 'Mått',
      values: ['Avfallsmängd'],
    },
    {
      fieldName: 'Företagsinformation',
      values: ['Adress', 'Ort'],
    },
    {
      fieldName: 'Orderdetaljer',
      values: ['Kontrakt nr.'],
    },
  ],
  /* nb: [
    { fieldName: 'Avfalls detaljer', values: ['Avfallsbeskrivelse'] },
    { fieldName: 'Måle', values: ['Mengde'] },
    { fieldName: 'Firma informasjon', values: ['Anleggets adresse', 'By'] },
    { fieldName: 'Ordredetaljer', values: ['Kontrakt nr.'] },
    {
      fieldName: 'År',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  */
};

const CompanyNameSelectionFieldName: { [key in SupportedLanguage]: string } = {
  en: 'Company name',
  fi: 'Yrityksen nimi',
  sv: 'Företagsnamn',
};

export interface QtyReportObjects {
  filters: {
    executionMonth: string;
    executionQuarter: string;
    executionYear: string;
  };
  visualization: {
    table: string;
    dimensions: {
      companyInformation: string | undefined;
      customerHierarchy: string | undefined;
      wasteDetails: string | undefined;
      orderDetails: string | undefined;
      invoiceDetails: string | undefined;
      tourInformation: string | undefined;
      deliveryPointInformation: string | undefined;
      measures: string | undefined;
    };
  };
  buttons: {
    changeWeightUnits: string;
  };
}

const ReportCommonObjectsIds: QtyReportObjects = {
  filters: {
    executionMonth: 'sjzmMf',
    executionQuarter: 'ksFxZd',
    executionYear: 'JwcyJ',
  },
  visualization: {
    table: 'QdjyCkp',
    dimensions: {
      companyInformation: 'pUzcjPP',
      customerHierarchy: 'LNEyLf',
      wasteDetails: 'YKbPdE',
      orderDetails: 'KuJa',
      invoiceDetails: 'DSMFUjS',
      tourInformation: 'jPkvjy',
      deliveryPointInformation: 'fVjdunq',
      measures: 'mQCbr',
    },
  },
  buttons: {
    changeWeightUnits: 'ksPPWXz',
  },
};

const ReportsObjectsIdsPerLang: { [key in SupportedLanguage]: QtyReportObjects } = {
  en: { ...ReportCommonObjectsIds },
  fi: { ...ReportCommonObjectsIds },
  sv: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
        invoiceDetails: undefined,
      },
    },
  },
  /* nb: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
        deliveryPointInformation: undefined,
      },
    },
  },
  */
};

export const getWasteQtyReportConfig = (
  lang: SupportedLanguage,
): QlikReportConfig<QtyReportObjects> => {
  const staticConfig = {
    qlikAppConfig: {
      ...qlikAppConfig,
      appId: WasteQtyReports[lang] || WasteQtyReports[fallbackLang],
    },
    defaultSelections: WasteQtyReportDefaultSelections[lang] || [],
    objectIds: ReportsObjectsIdsPerLang[lang],
  };

  const userAccessInfo = getUserAccessInfo();

  if (!(userAccessInfo.isInternal && userAccessInfo.isAdmin)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: CompanyNameSelectionFieldName[lang],
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};
