import { StorageUtils } from './storage';

export type RefreshIssuer = string;

const MAX_REFRESH_ATTEMPTS = 1;

type AnalyzeResult =
  | { result: 'MAX_ATTEMPTS' | 'LESS_ATTEMPTS'; attempts: number }
  | { result: 'INVALID_VALUE'; attempts: null };

const getStorageItemKey = (issuer: RefreshIssuer) =>
  `${StorageUtils.sessionKeys.tokenRefreshAttempt}_${issuer}`;

const analyzeRefreshTokenAttempts = (issuer: RefreshIssuer): AnalyzeResult => {
  const storageItemKey = getStorageItemKey(issuer);
  const refreshAttemptsStringValue = sessionStorage.getItem(storageItemKey);
  const refreshAttempts = refreshAttemptsStringValue ? +refreshAttemptsStringValue : null;

  if (
    refreshAttempts !== null &&
    isFinite(refreshAttempts) &&
    refreshAttempts >= MAX_REFRESH_ATTEMPTS
  ) {
    return { result: 'MAX_ATTEMPTS', attempts: refreshAttempts };
  }

  if (refreshAttempts === null || !isFinite(refreshAttempts) || refreshAttempts < 0) {
    return { result: 'INVALID_VALUE', attempts: null };
  }

  return { result: 'LESS_ATTEMPTS', attempts: refreshAttempts };
};

const validateTokenRefreshAttempts = (issuer: RefreshIssuer): { refreshAvailable: boolean } => {
  const res = analyzeRefreshTokenAttempts(issuer);

  if (res.result === 'MAX_ATTEMPTS') {
    return { refreshAvailable: false };
  }

  const storageItemKey = getStorageItemKey(issuer);

  if (res.result === 'INVALID_VALUE') {
    sessionStorage.setItem(storageItemKey, '1');
  } else {
    sessionStorage.setItem(storageItemKey, (res.attempts + 1).toString());
  }

  return { refreshAvailable: true };
};

const onLogout = (issuer: RefreshIssuer) => {
  sessionStorage.removeItem(getStorageItemKey(issuer));
};

const onSuccessfulTokenValidation = (issuer: RefreshIssuer) => {
  sessionStorage.removeItem(getStorageItemKey(issuer));
};

const tokenRefreshAvailable = (issuer: RefreshIssuer) => {
  const res = analyzeRefreshTokenAttempts(issuer);

  return res.result !== 'MAX_ATTEMPTS';
};

export const TokenRefreshUtils = {
  onLogout,
  onSuccessfulTokenValidation,
  validateTokenRefreshAttempts,
  tokenRefreshAvailable,
};
