import { FC, useMemo, useContext, useCallback, ChangeEvent, useEffect } from 'react';
import {
  ContentText,
  Box,
  spacing,
  IconButton,
  IconCross,
  colors,
  fontSizes,
  Button,
  Select,
} from '@fortum/elemental-ui';
import { SideDrawer } from '@components/SideDrawer';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import {
  OrdersFiltersHandlerReturnType,
  SELECTED_FILTERS_DEFAULT_VALUES,
  useOrdersFiltersHandler,
} from '@routes/orders/components/useOrdersFiltersHandler';
import { ContentContainer } from './styles';
import { OrdersFiltersContext } from '../OrdersFiltersContextProvider';
import { isEqual } from 'lodash';
import { Multiselect } from '@components/Multiselect';
import { OrdersSelectedFiltersWithSearch } from '@models/filters';
import { useFiltersDisplayValues } from '../useFiltersDisplayValue';
import { FiltersAvailabilityValidator, getSelectedFiltersAmount } from '../utils';
import { filterSelectItems } from '@utils/dataOperations/multiselect';
import { SelectChangeEvent } from '@components/types';

interface FiltersPaneProps {
  open: boolean;
  onClose: () => void;
}

/**
 * OrdersFiltersContext stores current value of filters, while useOrdersFiltersHandler is used in this component to store temporary data.
 * Once user clicks Apply button, temporary data is propagated to the current values in the context.
 */

export const FiltersPane: FC<FiltersPaneProps> = ({ open, onClose }) => {
  const { t } = useTranslation<Namespace[]>(['common', 'orders', 'errors']);
  const filtersContext = useContext(OrdersFiltersContext);

  if (!filtersContext) {
    throw Error('Missing orders filters context');
  }

  const {
    selectedFiltersValues,
    setters,
    selectItems,
    reset,
    filtersPrecedence,
    setAll,
    isLoading,
    isError,
  } = useOrdersFiltersHandler(true);

  const displayValues = useFiltersDisplayValues(selectedFiltersValues, selectItems);

  const setTimePeriod = useCallback(
    (e: ChangeEvent<SelectChangeEvent<string>>) => setters.timePeriod(e.target.value),
    [],
  );

  const selectedItemsAmount = useMemo(
    () => getSelectedFiltersAmount(selectedFiltersValues),
    [selectedFiltersValues],
  );

  const applyChanges = useCallback(() => {
    filtersContext.setAllExceptSearch(selectedFiltersValues, filtersPrecedence);
    onClose();
  }, [selectedFiltersValues]);

  const selectionsChanged = useMemo<boolean>(
    () =>
      (
        Object.entries(filtersContext.selectedFiltersValues) as [
          keyof OrdersSelectedFiltersWithSearch,
          string | string[],
        ][]
      ).some(([key, values]) => {
        return !isEqual(selectedFiltersValues[key], values);
      }),
    [filtersContext.selectedFiltersValues, selectedFiltersValues],
  );

  const selectionsEqualDefaults = useMemo(
    () => isEqual(selectedFiltersValues, SELECTED_FILTERS_DEFAULT_VALUES),
    [selectedFiltersValues],
  );

  useEffect(() => {
    if (open) {
      setAll(filtersContext.selectedFiltersValues, filtersContext.filtersPrecedence);
    }
  }, [open]);

  const shouldBeDisabled = useMemo<{
    [key in keyof OrdersFiltersHandlerReturnType['selectItems']]: boolean;
  }>(() => {
    const validator = new FiltersAvailabilityValidator(
      selectItems,
      isLoading,
      isError,
      filtersPrecedence,
    );

    return {
      orderStatus: validator.filterDisabled('orderStatus'),
      wasteDescription: validator.filterDisabled('wasteDescription'),
      orderType: validator.filterDisabled('orderType'),
      containerType: validator.filterDisabled('containerType'),
      transportType: validator.filterDisabled('transportType'),
      businessPartner: validator.filterDisabled('businessPartner'),
      city: validator.filterDisabled('city'),
      timePeriod: selectItems.timePeriod.length === 0,
    };
  }, [selectItems, isLoading, filtersPrecedence, isError]);

  return (
    <SideDrawer onClickOutside={onClose} open={open} width="400px">
      <ContentContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={spacing.s}>
          <ContentText fontSize={fontSizes.l}>{t('common:filters.label')}</ContentText>
          <IconButton
            size={spacing.xs}
            icon={<IconCross color={colors.inkGrey} />}
            status="plain"
            onClick={onClose}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={spacing.xxs}
          overflowY="auto"
          flex={1}
          mb={spacing.xxs}
        >
          <Select
            name="time-period-on-filter-pane"
            items={selectItems.timePeriod}
            selected={selectedFiltersValues.timePeriod}
            label={t('orders:filters.timePeriod.label')}
            variant="condensed"
            onChange={setTimePeriod}
            borderStyle="full"
            disabled={shouldBeDisabled.timePeriod}
            placeholder={t('common:all')}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.orderStatus}
            items={selectItems.orderStatus}
            onSelectedItemsChange={setters.orderStatus}
            label={t('orders:filters.orderStatus.label')}
            name="order-status-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.orderStatus}
            displayValue={displayValues.orderStatusDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.orderType}
            items={selectItems.orderType}
            onSelectedItemsChange={setters.orderType}
            label={t('orders:filters.orderTypes.label')}
            name="order-types-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.orderType}
            displayValue={displayValues.orderTypeDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.wasteDescription}
            items={selectItems.wasteDescription}
            onSelectedItemsChange={setters.wasteDescription}
            label={t('orders:filters.wasteDesc.label')}
            name="waste-desc-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.wasteDescription}
            displayValue={displayValues.wasteDescriptionDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.transportType}
            items={selectItems.transportType}
            onSelectedItemsChange={setters.transportType}
            label={t('orders:filters.transportTypes.label')}
            name="transport-types-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.transportType}
            displayValue={displayValues.transportTypeDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.containerType}
            items={selectItems.containerType}
            onSelectedItemsChange={setters.containerType}
            label={t('orders:filters.containerTypes.label')}
            name="container-types-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.containerType}
            displayValue={displayValues.containerTypeDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.businessPartner}
            items={selectItems.businessPartner}
            onSelectedItemsChange={setters.businessPartner}
            label={t('orders:filters.businessPartners.label')}
            name="business-partners-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.businessPartner}
            displayValue={displayValues.businessPartnerDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
          <Multiselect
            selected={selectedFiltersValues.city}
            items={selectItems.city}
            onSelectedItemsChange={setters.city}
            label={t('orders:filters.cities.label')}
            name="cities-on-filter-pane"
            variant="condensed"
            borderStyle="full"
            disabled={shouldBeDisabled.city}
            displayValue={displayValues.cityDisplayValue}
            placeholder={t('common:all')}
            filterItems={filterSelectItems}
            error={isError}
            errorMessage={t('errors:ordersOverview.filters.failedToFetch')}
          />
        </Box>

        <Box display="flex" marginTop="auto" gap={spacing.xs}>
          <Button status="secondary" flex={1} onClick={reset} disabled={selectionsEqualDefaults}>
            {t('common:clearAll')}
          </Button>
          <Button
            flex={1}
            onClick={applyChanges}
            disabled={!selectionsChanged}
          >{`${t('common:apply')} (${selectedItemsAmount})`}</Button>
        </Box>
      </ContentContainer>
    </SideDrawer>
  );
};
