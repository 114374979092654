import { Button, ContentText, fontSizes } from '@fortum/elemental-ui';
import { HeaderContainer, HeaderButtonsContainer } from '../../styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useDataExport, BookmarksContext } from '@components/qlik';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BookmarksManagement } from './BookmarksManagement';

export interface PageHeaderProps {
  title: string;
  exportDataHandler?: {
    objectId: string;
    onError: () => void;
    onEmptyData: () => void;
  };
}

export const PageHeader: FC<PageHeaderProps> = ({ title, exportDataHandler }) => {
  const { t } = useTranslation<Namespace>('common');
  const isMounted = useRef(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const { exportData } = useDataExport();

  const bookmarksContext = useContext(BookmarksContext);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const exportTableData = useCallback(() => {
    if (!exportDataHandler) {
      return;
    }

    setExportInProgress(true);

    return exportData(exportDataHandler.objectId)
      .catch(error => {
        if (error.message === 'No data') {
          exportDataHandler.onEmptyData();
        } else {
          exportDataHandler.onError();
        }
      })
      .finally(() => {
        if (!isMounted.current) {
          return;
        }

        setExportInProgress(false);
      });
  }, [exportData, exportDataHandler]);

  return (
    <HeaderContainer>
      <ContentText fontSize={fontSizes.xl}>{title}</ContentText>
      <HeaderButtonsContainer>
        {!!bookmarksContext && <BookmarksManagement />}
        {exportDataHandler && (
          <Button variant="condensed" onClick={exportTableData} disabled={exportInProgress}>
            {t('export')}
          </Button>
        )}
      </HeaderButtonsContainer>
    </HeaderContainer>
  );
};
