import {
  staticBorderStyles,
  horizontalContainer,
  verticalContainer,
  CONTAINER_BORDER_RADIUS,
  HorizontalContainer,
} from '@components/styles';
import {
  ColProps,
  CommonGridProps,
  ContentTextProps,
  RowProps,
  colors,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};

  ${staticBorderStyles()};
`;

export const Header = styled.div`
  ${horizontalContainer};
  background-color: ${colors.lightGrey};
`;

export const HeaderInformationContainer = styled(HorizontalContainer)`
  border-radius: ${CONTAINER_BORDER_RADIUS};

  align-items: center;
  gap: ${spacing.xxxs};
  padding: ${spacing.xxs} ${spacing.xs};
`;

export const HeaderTextContainer = styled.div`
  ${horizontalContainer};

  align-items: center;
  gap: ${spacing.xxxxs};
`;

export const DetailsColumnWrapper = styled.div`
  ${verticalContainer};

  gap: 12px;
`;

const MARGIN_HORIZONTAL_PX = '24px';

export const gridStyles: CommonGridProps = {
  mh: { xs: MARGIN_HORIZONTAL_PX },
  mt: { xs: spacing.xxs },
  pb: { xs: spacing.m },
  maxWidth: { xs: `calc(100% - 2 * ${MARGIN_HORIZONTAL_PX})` },
  width: { xs: `calc(100% - 2 * ${MARGIN_HORIZONTAL_PX})` },
};

export const rowWithMarginBottomStyles: RowProps = {
  mb: { xs: '12px' },
  mh: { xs: '12px' },
};

export const rowWithBigMarginBottomStyles: RowProps = {
  mb: { xs: spacing.s },
  mh: { xs: '12px' },
};

export const rowWithoutMarginHorizontal: RowProps = {
  mh: {
    xs: 0,
  },
};

export const fullWidthColStyles: ColProps = {
  xxs: 12,
};

export const orderTileColStyles: ColProps = {
  ...fullWidthColStyles,
  ph: {
    xs: 0,
  },
};

export const detailsColStyles: ColProps = {
  xxs: 12,
  l: 6,
};

export const detailsHeaderTextStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};
