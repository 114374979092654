import { HorizontalContainer, VerticalContainer } from '@components/styles';
import {
  ButtonProps,
  ContentTextProps,
  fontSizes,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  gap: ${spacing.xs};
  flex: 1;
`;
export const SectionContainer = styled(VerticalContainer)`
  gap: ${spacing.xxxs};
`;

export const sectionHeaderStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  fontWeight: fontWeights.medium,
};

export const ButtonsContainer = styled(HorizontalContainer)`
  justify-content: flex-end;
  margin-top: auto;
  gap: ${spacing.xxs};
`;

export const primaryButtonStyles: ButtonProps = {
  size: 'm',
};

export const discardButtonStyles: ButtonProps = {
  ...primaryButtonStyles,
  status: 'plain',
};

export const backButtonStyles: ButtonProps = {
  ...primaryButtonStyles,
  status: 'secondary',
};
