import React, { FC, useContext, useEffect, useMemo } from 'react';
import {
  Container,
  DetailsColumnWrapper,
  gridStyles,
  rowWithMarginBottomStyles,
  fullWidthColStyles,
  detailsHeaderTextStyles,
  detailsColStyles,
  rowWithBigMarginBottomStyles,
  orderTileColStyles,
  rowWithoutMarginHorizontal,
} from './styles';
import { Col, ContentText, Grid, Row, useToggle } from '@fortum/elemental-ui';
import { ServiceSummary } from '@models/contract';
import { VerticalListItem } from '@components/index';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ServiceTileHeader } from './ServiceTileHeader';
import { CompanyDetails } from '@components/CompanyDetails';
import { displayedValue, hasValue } from '@utils/dataOperations';
import { translate } from '@utils/internationalization';
import { camelCase } from 'lodash';
import { mapContractToServiceSummary } from '@utils/contract';
import { useContractDetails } from '@data/hooks/useContractDetails';
import { CenteredLoader } from '@components/layout/styles';
import { FailedToLoad } from '@components/layout/FailedToLoad';
import { destructureContractIdentifier } from '@utils/routes';
import { CreationForm } from 'src/types/ordersCreation';
import { getListItemTestId } from '@utils/testsHelpers';
import {
  mapRawEquipmentServiceTypesToOrderTypes,
  orderTypesSortingFunction,
} from '@utils/ordersCreation';
import { OrderType } from '@models/orders';
import { useContactsByBusinessPartner } from '@data/hooks/useContactsByBusinessPartner';
import { orderTypesSortingOrder } from '@routes/ordersCreation/config';
import {
  ConfirmationModal,
  IncorrectDataError,
  OrdersCreationContext,
} from '@routes/ordersCreation/components';
import { SingleOrderCreationContextProvider } from '../SingleOrderCreationContextProvider';
import { OrderTile } from '../OrderTile/OrderTile';

export interface ServiceTileProps {
  serviceIndex: number;
  contractIdentifier: string;
  ordersCreationForm: CreationForm;
  onChooseOtherServiceClick: () => void;
}

//TODO: adjust spacings when design finalized
export const ServiceTile: FC<ServiceTileProps> = ({
  serviceIndex,
  contractIdentifier,
  ordersCreationForm,
  onChooseOtherServiceClick,
}) => {
  const { t, i18n } = useTranslation<Namespace[]>([
    'wasteDetails',
    'services',
    'common',
    'orderCreation',
  ]);

  const { initializeCreationFormWithOrder, clearForms } = useContext(OrdersCreationContext);

  const [modalOpened, toggleModalOpened] = useToggle();

  const { contractNo, dataSource } = destructureContractIdentifier(contractIdentifier);

  const {
    data: contract,
    isLoading,
    error,
  } = useContractDetails(contractNo, dataSource, i18n.language);

  const contactsDataHandler = useContactsByBusinessPartner(contract?.generalInfo.customerCode);

  const serviceSummary = useMemo<ServiceSummary | undefined>(
    () => (contract ? mapContractToServiceSummary(contract) : undefined),
    [contract],
  );

  const availableOrderTypes = useMemo<OrderType[]>(
    () =>
      contract
        ? mapRawEquipmentServiceTypesToOrderTypes(
            contract.serviceDetails.equipmentDetails.serviceTypes,
          ).sort(orderTypesSortingFunction(orderTypesSortingOrder))
        : [],
    [contract],
  );

  useEffect(() => {
    if (
      !contract ||
      !serviceSummary ||
      availableOrderTypes.length === 0 ||
      ordersCreationForm.forms.length > 0
    )
      return;

    const orderLevelReference = hasValue(contract.contractDetails.invoicingDetails.orderReference)
      ? contract.contractDetails.invoicingDetails.orderReference
      : undefined;

    const contactPersonId = hasValue(contract.contractDetails.contactDetails.code)
      ? contract.contractDetails.contactDetails.code
      : '';

    initializeCreationFormWithOrder(
      contractIdentifier,
      serviceSummary,
      availableOrderTypes[0],
      contactPersonId,
      orderLevelReference,
    );
  }, [serviceSummary]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (!contract || error || !serviceSummary) {
    return <FailedToLoad />;
  }

  const serviceTypeValue = translate(
    camelCase(serviceSummary.serviceType),
    'domain',
    'contractType',
  );

  return (
    <>
      <Container data-testid={`service-tile-${contractNo}`}>
        <ServiceTileHeader
          serviceIndex={serviceIndex}
          contractNo={serviceSummary.contractNo}
          onIconClick={toggleModalOpened}
        />

        {contract && availableOrderTypes.length === 0 ? (
          <IncorrectDataError onClick={onChooseOtherServiceClick} />
        ) : (
          <Grid {...gridStyles} data-testid="service-tile-content">
            <Row {...rowWithMarginBottomStyles}>
              <Col {...fullWidthColStyles}>
                <ContentText {...detailsHeaderTextStyles} data-testid="service-details-label">
                  {t('wasteDetails:wasteServiceDetails')}
                </ContentText>
              </Col>
            </Row>

            <Row {...rowWithBigMarginBottomStyles} data-testid="service-details">
              <Col {...detailsColStyles}>
                <DetailsColumnWrapper>
                  <VerticalListItem
                    label={t('services:wasteType')}
                    data-testid={getListItemTestId('waste-type')}
                  >
                    {displayedValue(serviceSummary.wasteDescription)}
                  </VerticalListItem>

                  <VerticalListItem
                    label={t('wasteDetails:serviceType')}
                    data-testid={getListItemTestId('service-type')}
                  >
                    {serviceTypeValue}
                  </VerticalListItem>

                  <VerticalListItem
                    label={t('wasteDetails:equipmentType')}
                    data-testid={getListItemTestId('equipment-type')}
                  >
                    {displayedValue(serviceSummary.equipmentDescription)}
                  </VerticalListItem>
                </DetailsColumnWrapper>
              </Col>

              <Col {...detailsColStyles}>
                <DetailsColumnWrapper>
                  <VerticalListItem
                    label={t('wasteDetails:transportDetails.transportType')}
                    data-testid={getListItemTestId('transport-type')}
                  >
                    {translate(
                      displayedValue(serviceSummary.transportType),
                      'domain',
                      'transportType',
                    )}
                  </VerticalListItem>
                  <VerticalListItem label={t('wasteDetails:company')}>
                    <CompanyDetails company={serviceSummary.company} />
                  </VerticalListItem>
                </DetailsColumnWrapper>
              </Col>
            </Row>

            <Row {...rowWithoutMarginHorizontal}>
              <Col {...orderTileColStyles}>
                {ordersCreationForm.forms.map((form, index) => (
                  <SingleOrderCreationContextProvider
                    key={form.formIdentifier}
                    contract={contract}
                    contactsDataHandler={contactsDataHandler}
                    availableOrderTypes={availableOrderTypes}
                    contractIdentifier={contractIdentifier}
                    orderForm={form}
                  >
                    <OrderTile data-testid={`order-tile-${index}`} orderIndex={index} />
                  </SingleOrderCreationContextProvider>
                ))}
              </Col>
            </Row>
          </Grid>
        )}
      </Container>

      <ConfirmationModal
        opened={modalOpened}
        onClose={toggleModalOpened}
        aria-labelledby="modal-heading"
        onConfirmClick={clearForms}
        headerText={t('common:delete')}
        messageText={t('orderCreation:serviceDiscardingConfirmationMessage')}
      />
    </>
  );
};
