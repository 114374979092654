import { getCountryCodeFromDataSource, getDataSourceByCountry } from './dataOperations';

const CONTRACT_IDENTIFIER_DELIMITER = '-';

export const createContractIdentifier = (dataSource: string, contractNo: string) => {
  const dataSourceCountry = getCountryCodeFromDataSource(dataSource);

  return dataSourceCountry
    ? contractNo + CONTRACT_IDENTIFIER_DELIMITER + dataSourceCountry
    : contractNo;
};

interface ContractIdentifierElements {
  contractNo: string | null;
  dataSource: string | null;
}

export const destructureContractIdentifier = (
  identifier: string | undefined,
): ContractIdentifierElements => {
  if (!identifier) {
    return { contractNo: null, dataSource: null };
  }

  const dataSourceCountryIndex = identifier.indexOf(CONTRACT_IDENTIFIER_DELIMITER);

  return dataSourceCountryIndex === -1
    ? {
        contractNo: identifier,
        dataSource: null,
      }
    : {
        contractNo: identifier.substring(0, dataSourceCountryIndex),
        dataSource: getDataSourceByCountry(identifier.substring(dataSourceCountryIndex + 1)),
      };
};
