import { Box, BoxProps, CssValue, Placeholder } from '@fortum/elemental-ui';
import { FC } from 'react';

interface CellPlaceholderProps {
  cellWidth?: CssValue;
  placeholderHeight?: CssValue;
  cellHeight?: CssValue;
}

const wrapperProps: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const CellPlaceholder: FC<CellPlaceholderProps> = ({
  placeholderHeight = '20px',
  cellHeight = '52px',
  cellWidth,
}) => (
  <Box {...wrapperProps} width={cellWidth} height={cellHeight}>
    <Placeholder height={placeholderHeight} />
  </Box>
);
