import styled, { css } from 'styled-components';
import { Icon, colors, spacing } from '@fortum/elemental-ui';
import { NavLink } from 'react-router-dom';
import { horizontalContainer, verticalContainer } from '@components/styles';

export const Navigation = styled.nav`
  --icon-default-color: ${colors.hyperGreen};
  --icon-on-hover-color: ${colors.oceanGreen};
  --icon-disabled-color: ${colors.fogGrey};

  --border-default-color: ${colors.oceanGreen};
  --border-on-hover-color: ${colors.oceanGreen};
  --border-selected-color: ${colors.hyperGreen};
  --border-pressed-color: ${colors.snowWhite};

  --background-selected-color: rgba(147, 242, 220, 0.2);
  --background-on-hover-color: ${colors.snowWhite};
  --background-pressed-color: ${colors.pineGreen};

  --text-default-color: ${colors.snowWhite};
  --text-on-hover-color: ${colors.oceanGreen};
  --text-selected-color: ${colors.snowWhite};
  --text-disabled-color: ${colors.fogGrey};

  background-color: ${colors.oceanGreen};

  width: var(--app-navigation-width);
  min-width: var(--app-navigation-width);
  box-sizing: border-box;
  height: calc(100vh - var(--app-header-height));
  position: sticky;
  top: var(--app-header-height);
  overflow: auto;

  z-index: 9;
`;

export const routerDescriptionContainer = () => css`
  ${horizontalContainer};

  align-items: flex-start;
  padding: ${spacing.xxxs};
  text-decoration: none;
  box-sizing: border-box;
  column-gap: ${spacing.xxxs};

  svg {
    color: var(--icon-default-color);
  }

  color: var(--text-default-color);
  -webkit-text-fill-color: var(--text-default-color);
`;

export const IconContainer = styled.div<{ $textLineHeightInRem: string }>`
  ${verticalContainer};
  height: ${p => p.$textLineHeightInRem};
  align-self: center;
`;

export const SubNavigationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const SubNavigationHeaderContainer = styled.div<{ $disabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: align-self;
  height: 100%;
  padding: ${spacing.xxxs};
  border-left: 4px solid var(--border-default-color);

  svg {
    color: var(--icon-default-color);
  }

  &:hover {
    ${p =>
      !p.$disabled &&
      css`
        color: var(--text-on-hover-color);
        -webkit-text-fill-color: var(--text-on-hover-color);

        background-color: var(--background-on-hover-color);
        border-left: 4px solid var(--border-on-hover-color);
        cursor: pointer;
        svg {
          color: var(--icon-on-hover-color);
        }
      `};
  }

  ${p =>
    p.$disabled &&
    css`
      cursor: not-allowed;
      svg {
        color: ${colors.fogGrey};
      }
    `}
`;

export const navlinkHoveredState = () => css<{ $disabled: boolean; $pressed: boolean }>`
  &:hover {
    ${p =>
      !p.$disabled &&
      !p.$pressed &&
      css`
        color: var(--text-on-hover-color);
        -webkit-text-fill-color: var(--text-on-hover-color);
        background-color: var(--background-on-hover-color);
        cursor: pointer;
        border-left: 4px solid var(--border-on-hover-color);
        svg {
          color: var(--icon-on-hover-color);
        }
      `};
  }
`;

export const NavItemContainer = styled(NavLink)<{ $disabled: boolean; $pressed: boolean }>`
  ${routerDescriptionContainer};
  ${navlinkHoveredState};
  border-left: 4px solid var(--border-default-color);
  ${p =>
    p.$pressed &&
    css`
      color: var(--text-selected-color);
      -webkit-text-fill-color: var(--text-selected-color);

      background-color: var(--background-pressed-color);
      border-left: 4px solid var(--border-pressed-color);

      svg {
        color: var(--icon-default-color);
      }
    `}

  ${p =>
    p.$disabled &&
    css`
      color: ${colors.fogGrey};
      -webkit-text-fill-color: ${colors.fogGrey};

      svg {
        color: ${colors.fogGrey};
      }

      cursor: not-allowed;
    `}
  * &[class*='active'] {
    ${navlinkHoveredState};
    ${p =>
      !p.$disabled &&
      !p.$pressed &&
      css`
        background-color: var(--background-selected-color);
        color: var(--text-selected-color);
        -webkit-text-fill-color: var(--text-selected-color);
        cursor: pointer;
        border-left: 4px solid var(--border-selected-color);

        svg {
          color: var(--icon-default-color);
        }
      `}
  }
`;

export const AppVersionInfo = styled.div`
  position: absolute;
  bottom: ${spacing.xxs};
  width: 100%;
  padding-left: ${spacing.xxs};
  box-sizing: border-box;
`;

export const StyledIconChevron = styled(Icon)<{ $disabled: boolean }>`
  span svg {
    color: ${colors.snowWhite};
  }
  &:hover {
    ${p =>
      !p.$disabled &&
      css`
        span svg {
          color: $var(--icon-on-hover-color);
        }
      `}
  }

  ${p =>
    p.$disabled &&
    css`
      cursor: not-allowed;
      span svg {
        color: ${colors.fogGrey};
      }
    `}
`;
