import {
  IconWasteManagement,
  Heading,
  ContentText,
  Box,
  Button,
  CommonModalProps,
} from '@fortum/elemental-ui';
import { buttonsContainerStyles, contentTextStyles, headingTextStyles, StyledModal } from './style';
import { FC } from 'react';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { Testable } from '@components/types';

type ConfirmationModalProps = Omit<CommonModalProps, 'children'> &
  Testable & {
    headerText: string;
    messageText: string;
    onConfirmClick: () => void;
  };

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  headerText,
  messageText,
  onConfirmClick,
  'data-testid': dataTestId,
  ...props
}) => {
  const { t } = useTranslation<Namespace>('common');

  return (
    <StyledModal {...props} aria-labelledby="modal-heading" data-testid={dataTestId}>
      <IconWasteManagement size={48} />
      <Heading {...headingTextStyles}>{headerText}</Heading>
      <ContentText {...contentTextStyles}>{messageText}</ContentText>
      <Box {...buttonsContainerStyles}>
        <Button onClick={props.onClose}>{t('common:refuseDelete')}</Button>
        <Button status="secondary" onClick={onConfirmClick}>
          {t('common:confirmDelete')}
        </Button>
      </Box>
    </StyledModal>
  );
};
