import {
  Box,
  colors,
  fontSizes,
  fontWeights,
  IconChevronDown,
  IconChevronUp,
  spacing,
} from '@fortum/elemental-ui';
import { Address } from '@models/service';
import { memo, useMemo, useState } from 'react';
import {
  MenuItemWithPaddingTop,
  StyledContentText,
  StyledSpan,
} from '@routes/services/components/location/styles';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '@config/routes';
import { findExactAddressByLocationCode, getContractsNumberAmountLabel } from '@utils/services';

interface Props {
  id: string;
  cityName: string;
  companyName: string;
  address: Address;
}

export const AddressLayout = memo(({ id, address, cityName, companyName }: Props) => {
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const locationCodeFromSearchParams = searchParams.get(SEARCH_PARAMS.locationCode);
    return locationCodeFromSearchParams
      ? !!findExactAddressByLocationCode(address.exactAddresses, locationCodeFromSearchParams)
      : false;
  });

  const numberOfContractsLabel = useMemo(() => {
    const amountLabel = getContractsNumberAmountLabel(
      address.numberOfContracts,
      address.totalNumberOfContracts,
    );

    return `(${amountLabel})`;
  }, [address.numberOfContracts, address.totalNumberOfContracts]);

  const exactAddressesLayout = useMemo(
    () =>
      address.exactAddresses
        .filter(exact => exact.numberOfContracts > 0)
        .map((item, index) => {
          const key = `${companyName}-${cityName}-${item.name}-exact-address-${index}`;
          const numberOfContracts = getContractsNumberAmountLabel(
            item.numberOfContracts,
            item.totalNumberOfContracts,
          );

          return (
            <MenuItemWithPaddingTop
              key={key}
              id={item.name}
              numberOfContracts={numberOfContracts}
              address={address.name}
              streetAddress={item.name}
              locationCode={item.locationCode}
            />
          );
        }),
    [address.exactAddresses],
  );

  return (
    <Box id={id} display="flex" flex="1" flexDirection="column" justifyContent="space-between">
      <Box
        display="flex"
        flex="1"
        id={`${address.name}"-street_address-"${numberOfContractsLabel}`}
        cursor="pointer"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        <StyledContentText
          fontWeight={fontWeights.light}
          fontSize={fontSizes.s}
          color={colors.mineShaftGrey}
        >
          {address.name}
          <StyledSpan>{numberOfContractsLabel}</StyledSpan>
        </StyledContentText>
        <Box display="flex" alignSelf="flex-start" ph={spacing.xxxxs}>
          {isOpen ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" flex={1}>
        {isOpen && exactAddressesLayout}
      </Box>
    </Box>
  );
});

AddressLayout.displayName = 'AddressLayout';
