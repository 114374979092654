import styled from 'styled-components';
import { verticalContainer } from '@components/styles';
import { Select, spacing } from '@fortum/elemental-ui';
import { FILTERS_GAP, FILTER_MIN_WIDTH } from '../Filters/styles';

export const ContentContainer = styled.div`
  ${verticalContainer};

  padding: ${spacing.xs} ${spacing.xs} ${spacing.xxs};
  gap: ${spacing.xxs};
`;

/**
 * It should have same width as filters in MainFiltersLayout
 */
export const StyledSelect: typeof Select = styled(Select)`
  width: calc((100% - 3 * ${FILTERS_GAP}) / 4);
  min-width: ${FILTER_MIN_WIDTH};
`;
