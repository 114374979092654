import { NavLinkProps, useLocation } from 'react-router-dom';
import {
  IconContainer,
  NavItemContainer,
  StyledIconChevron,
  SubNavigationContainer,
  SubNavigationHeaderContainer,
} from './styles';
import {
  ComponentOrElement,
  ContentText,
  IconProps,
  lineHeights,
  colors,
  fontSizes,
  fontWeights,
  renderComponentOrElement,
  spacing,
  IconChevronUp,
  IconChevronDown,
  IconFiles,
} from '@fortum/elemental-ui';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

interface RouteDescriptionProps {
  icon?: ComponentOrElement<IconProps>;
  description: string;
  subNavItem?: boolean;
}

const RouteDescription: FC<RouteDescriptionProps> = ({ icon, description, subNavItem }) => {
  const lineHeightAsString = useMemo(() => `${lineHeights.tight.toString()}rem`, []);

  return (
    <>
      {!!icon && (
        <IconContainer $textLineHeightInRem={lineHeightAsString}>
          {renderComponentOrElement(icon, { size: 20 }, true)}
        </IconContainer>
      )}
      <ContentText
        textAlign="left"
        paddingLeft={!icon ? spacing.s : 0}
        fontSize={fontSizes.s}
        color={colors.snowWhite}
        fontWeight={fontWeights.medium}
        lineHeight={lineHeights.tight}
        paddingBottom={subNavItem ? undefined : spacing.xxxs}
        paddingTop={subNavItem ? undefined : spacing.xxxs}
      >
        {description}
      </ContentText>
    </>
  );
};

interface NavItemProps extends NavLinkProps, RouteDescriptionProps {
  disabled?: boolean;
}

export const NavItem: FC<NavItemProps> = ({
  icon,
  description,
  to,
  onClick,
  disabled = false,
  subNavItem = false,
}) => {
  const [elementPressed, setElementPressed] = useState<boolean>(false);

  const onPointerDown = useCallback(() => {
    setElementPressed(true);
  }, []);
  const onPointerUp = useCallback(() => {
    setElementPressed(false);
  }, []);

  return (
    <NavItemContainer
      to={to}
      onClick={onClick}
      $disabled={disabled}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      $pressed={elementPressed}
    >
      <RouteDescription icon={icon} description={description} subNavItem={subNavItem} />
    </NavItemContainer>
  );
};

interface SubNavigationProps extends Omit<RouteDescriptionProps, 'icon'> {
  disabled?: boolean;
  children: ReactNode;
  subnavigationPaths: string[];
}

export const SubNavigation: FC<SubNavigationProps> = ({
  description,
  disabled = false,
  children,
  subnavigationPaths,
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (subnavigationPaths.includes(location.pathname)) setOpen(true);
  }, [location]);

  return (
    <SubNavigationContainer>
      <SubNavigationHeaderContainer $disabled={disabled} onClick={() => setOpen(prev => !prev)}>
        <IconFiles size="20px" />
        <ContentText
          textAlign="left"
          fontSize={fontSizes.s}
          fontWeight={fontWeights.medium}
          lineHeight={lineHeights.tight}
          color={disabled ? colors.fogGrey : colors.snowWhite}
          paddingHorizontal={spacing.xxxs}
          paddingVertical={spacing.xxxs}
        >
          {description}
        </ContentText>
        <StyledIconChevron $disabled={disabled}>
          {open ? (
            <IconChevronUp position="absolute" size="22px" top={spacing.xxs} right={spacing.xxxs} />
          ) : (
            <IconChevronDown
              position="absolute"
              size="22px"
              top={spacing.xxs}
              right={spacing.xxxs}
            />
          )}
        </StyledIconChevron>
      </SubNavigationHeaderContainer>
      {open && children}
    </SubNavigationContainer>
  );
};
