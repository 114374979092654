import { FC } from 'react';
import {
  QlikObject,
  useDefaultSelections,
  QlikSelectionObject,
  BookmarksContextProvider,
  useCurrentSelections,
} from '@components/qlik';
import { ReportProps } from '../types';
import { QtyReportObjects } from '@config/qlik';
import {
  ReportWrapper,
  HorizontalWrappableContainer,
  QlikButton,
  VerticalContainer,
  ErrorViewWithBackground,
} from '../styles';
import { TopContainer, BottomContainer, LeftSideContainer } from './styles';
import { PageHeader, SelectionsManagement } from '../components';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { CenteredLoader } from '@components/layout/styles';
import { paths } from '@config/routes';

export const Report: FC<ReportProps<QtyReportObjects>> = ({
  defaultSelections,
  exportDataHandler,
  mandatorySelections,
  objectIds,
}) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'errors']);

  const { qSelections, clearSelections, isError, isLoading } = useCurrentSelections();

  const { mandatorySelectionsError, mandatorySelectionsValidationInProgress } =
    useDefaultSelections(defaultSelections, mandatorySelections);

  if (mandatorySelectionsValidationInProgress) {
    return <CenteredLoader />;
  }

  if (mandatorySelectionsError) {
    return (
      <ErrorViewWithBackground
        header={t('errors:reports.nothingToReport.header')}
        message={t('errors:reports.nothingToReport.message')}
        link={{
          navigatePath: paths.selectCustomer,
          text: t('errors:reports.nothingToReport.linkMessage'),
        }}
      />
    );
  }

  return (
    <BookmarksContextProvider qSelections={qSelections}>
      <PageHeader title={t('reports:wasteQty.title')} exportDataHandler={exportDataHandler} />
      <ReportWrapper>
        <SelectionsManagement
          qSelections={qSelections}
          clearSelections={clearSelections}
          isSelectionsFetchingError={isError}
          isSelectionsLoading={isLoading}
        />
        <TopContainer>
          <HorizontalWrappableContainer>
            <QlikSelectionObject
              id={objectIds.filters.executionYear}
              dataTestId="filters-execution-year"
            />
            <QlikSelectionObject
              id={objectIds.filters.executionQuarter}
              dataTestId="filters-execution-quarter"
            />
            <QlikSelectionObject
              id={objectIds.filters.executionMonth}
              dataTestId="filters-execution-month"
            />
          </HorizontalWrappableContainer>
        </TopContainer>
        <BottomContainer>
          <LeftSideContainer>
            <VerticalContainer>
              {!!objectIds.visualization.dimensions.companyInformation && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.companyInformation}
                  dataTestId="dimensions-company-information"
                />
              )}

              {!!objectIds.visualization.dimensions.customerHierarchy && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.customerHierarchy}
                  dataTestId="dimensions-customer-hierarchy"
                />
              )}

              {!!objectIds.visualization.dimensions.wasteDetails && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.wasteDetails}
                  dataTestId="dimensions-waste-details"
                />
              )}

              {!!objectIds.visualization.dimensions.orderDetails && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.orderDetails}
                  dataTestId="dimensions-order-details"
                />
              )}

              {!!objectIds.visualization.dimensions.invoiceDetails && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.invoiceDetails}
                  dataTestId="dimensions-invoice-details"
                />
              )}

              {!!objectIds.visualization.dimensions.tourInformation && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.tourInformation}
                  dataTestId="dimensions-tour-information"
                />
              )}

              {!!objectIds.visualization.dimensions.deliveryPointInformation && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.deliveryPointInformation}
                  dataTestId="dimensions-delivery-point-information"
                />
              )}

              {!!objectIds.visualization.dimensions.measures && (
                <QlikSelectionObject
                  id={objectIds.visualization.dimensions.measures}
                  dataTestId="dimensions-measures"
                />
              )}
            </VerticalContainer>
            <QlikButton
              id={objectIds.buttons.changeWeightUnits}
              height="35px"
              padding="0px"
              width="180px"
              dataTestId="button-change-weight-units"
            />
          </LeftSideContainer>

          <QlikObject
            id={objectIds.visualization.table}
            height="100%"
            minHeight="500px"
            dataTestId="table"
          />
        </BottomContainer>
      </ReportWrapper>
    </BookmarksContextProvider>
  );
};
