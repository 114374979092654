import {
  colors,
  ComponentOrElement,
  ContentText,
  fontWeights,
  IconError,
  IconInfo,
  IconProps,
  IconWarning,
  Notification,
  NotificationStatus,
  spacing,
} from '@fortum/elemental-ui';
import { translateIfExist } from '@utils/internationalization';
import { FC } from 'react';

type NotificationType = Extract<NotificationStatus, 'warning' | 'alert' | 'info'>;

const notificationTypeToIconMap: Record<NotificationType, ComponentOrElement<IconProps>> = {
  warning: <IconWarning height="100%" />,
  alert: <IconError height="100%" />,
  info: <IconInfo height="100%" />,
};

const globalNotificationsKeys = [
  'globalNotificationInfo',
  'globalNotificationWarning',
  'globalNotificationError',
] as const;
type GlobalNotificationKey = (typeof globalNotificationsKeys)[number];

const globalNotificationKeyToTypeMap: Record<GlobalNotificationKey, NotificationType> = {
  globalNotificationInfo: 'info',
  globalNotificationWarning: 'warning',
  globalNotificationError: 'alert',
};

const typeToBackgroundColorMap: Record<NotificationType, string> = {
  warning: colors.miniYellow,
  alert: colors.powerRed,
  info: colors.cushyBlue,
};

const typeToTextColorMap: Record<NotificationType, string> = {
  warning: colors.inkGrey,
  alert: colors.snowWhite,
  info: colors.snowWhite,
};

interface NotificationBannerProps {
  message: string;
  type: NotificationType;
  canClose?: boolean;
}

export const GlobalBanner: FC = () => {
  const alertNotification = translateIfExist('globalNotificationError', 'common');
  const warningNotification = translateIfExist('globalNotificationWarning', 'common');
  const infoNotification = translateIfExist('globalNotificationInfo', 'common');

  const shouldShowInfoNotification =
    infoNotification && !(alertNotification && warningNotification);

  return (
    <>
      {alertNotification && (
        <NotificationBanner
          message={alertNotification}
          type={globalNotificationKeyToTypeMap.globalNotificationError}
        />
      )}
      {warningNotification && (
        <NotificationBanner
          message={warningNotification}
          type={globalNotificationKeyToTypeMap.globalNotificationWarning}
          canClose
        />
      )}
      {shouldShowInfoNotification && (
        <NotificationBanner
          message={infoNotification}
          type={globalNotificationKeyToTypeMap.globalNotificationInfo}
          canClose
        />
      )}
    </>
  );
};

export const NotificationBanner: FC<NotificationBannerProps> = ({
  message,
  type,
  canClose = false,
}) => (
  <Notification
    hideCloseButton={!canClose}
    textColor={typeToTextColorMap[type]}
    backgroundColor={typeToBackgroundColorMap[type]}
    icon={notificationTypeToIconMap[type]}
    heading={<ContentText fontWeight={fontWeights.regular}>{message}</ContentText>}
    borderRadius="none"
    paddingLeft={spacing.xxxs}
    paddingRight={spacing.xxxs}
  />
);
