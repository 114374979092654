import {
  Box,
  spacing,
  colors,
  ContentText,
  fontSizes,
  ForwardLink,
  ComponentOrElement,
  IconProps,
  renderComponentOrElement,
} from '@fortum/elemental-ui';
import { FC } from 'react';
import { LinksContainer, TileContainer } from './styles';
import { isArray } from 'lodash';
import { Path } from '@config/routes';
import { useNavigate } from 'react-router-dom';

interface LinkProps {
  id: string;
  description: string;
  path: Path;
}

interface TileProps {
  icon: ComponentOrElement<IconProps>;
  header: string;
  description: string;
  links: LinkProps | LinkProps[];
}

export const Tile: FC<TileProps> = ({ icon, header, description, links }) => {
  const navigate = useNavigate();

  const isArrayOfLinks = isArray(links);

  return (
    <TileContainer>
      <Box display="flex" gap={spacing.xxxs} alignItems="center">
        {renderComponentOrElement(icon, { size: 24, color: colors.oceanGreen })}
        <ContentText fontSize={fontSizes.xl} textAlign="center">
          {header}
        </ContentText>
      </Box>
      <ContentText
        fontSize={fontSizes.s}
        textAlign="center"
        mb={isArrayOfLinks ? spacing.xxs : spacing.s}
      >
        {description}
      </ContentText>

      {isArrayOfLinks ? (
        <LinksContainer>
          {links.map(link => (
            <ForwardLink
              key={link.id}
              color={colors.oceanGreen}
              onClick={() => navigate(link.path)}
              id={link.id}
            >
              {link.description}
            </ForwardLink>
          ))}
        </LinksContainer>
      ) : (
        <ForwardLink color={colors.oceanGreen} onClick={() => navigate(links.path)} id={links.id}>
          {links.description}
        </ForwardLink>
      )}
    </TileContainer>
  );
};
