import { horizontalContainer, verticalContainer } from '@components/styles';

import { spacing, colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};
  min-height: var(--page-header-height);
  box-sizing: border-box;
  z-index: 9;
  position: sticky;
  top: var(--app-header-height);
  background-color: ${colors.lightGrey};
  align-items: center;
  padding: 22px ${spacing.xs} ${spacing.xxs};
  row-gap: 21px;
`;

export const FirstRow = styled.div`
  ${horizontalContainer};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
