import { StorageUtils } from '@common/storage';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import {
  Box,
  Button,
  colors,
  ContentText,
  fontSizes,
  fontWeights,
  IconSubtract,
  spacing,
} from '@fortum/elemental-ui';
import { BusinessPartner } from '@models/user';
import { Container } from '@routes/customer/style';
import { SELECTED_BUSINESS_PARTNERS_LIMIT } from '@utils/user';
import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectedBPs: BusinessPartner[];
  deselect: (bp: BusinessPartner) => void;
  clearSelection: () => void;
}
const SelectedComponent: FC<Props> = ({ selectedBPs, deselect, clearSelection }) => {
  const { t } = useTranslation<Namespace>('businessPartners');
  const navigate = useNavigate();
  const { sessionKeys: SessionStorageKeys } = StorageUtils;

  const selectedCustomers = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column" gap={spacing.s}>
        {selectedBPs.map((bp, index) => {
          const key = `${bp.name}-${bp.id}-"selected"-${index}`;
          return (
            <Box id={key} key={key} onClick={() => deselect(bp)}>
              <Box display="flex">
                <IconSubtract color={colors.oceanGreen} size={24} />
                <ContentText pl={spacing.xxs} fontSize={fontSizes.l} color={colors.inkGrey}>
                  {bp.name}
                </ContentText>
              </Box>
              <ContentText pl={spacing.m} fontSize={fontSizes.l} color={colors.sonicGrey}>
                {bp.id}
              </ContentText>
            </Box>
          );
        })}
      </Box>
    );
  }, [selectedBPs]);

  const save = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKeys.selectedBusinessPartners,
      JSON.stringify(selectedBPs),
    );
    navigate(paths.main);
  }, [selectedBPs]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" pt={spacing.xs} pb={spacing.xl}>
        <Button
          id="saveAndContinue"
          textAlign="left"
          disabled={selectedBPs.length <= 0}
          onClick={save}
        >
          {t('continue')}
        </Button>
      </Box>

      <Container p={spacing.s}>
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={spacing.s}>
          <ContentText
            id={'selectedCompanies'}
            color={colors.inkGrey}
            fontSize={fontSizes.m}
            fontWeight={fontWeights.medium}
          >
            {t('countSelected', {
              selected: `${selectedBPs.length} /${SELECTED_BUSINESS_PARTNERS_LIMIT}`,
            })}
          </ContentText>
          <Button
            p={0}
            m={0}
            id={'clearAllSelectedCompanies'}
            color={selectedBPs.length <= 0 ? colors.fogGrey : colors.oceanGreen}
            variant="normal"
            status="plain"
            onClick={clearSelection}
          >
            {t('clearAllSelection')}
          </Button>
        </Box>
        <Box>
          {selectedBPs.length === 0 ? (
            <ContentText italic color={colors.sonicGrey} fontSize={fontSizes.s}>
              {t('selectionsWillAppear')}
            </ContentText>
          ) : (
            <>{selectedCustomers}</>
          )}
        </Box>
      </Container>
    </>
  );
};

export const SelectedLayout = memo(SelectedComponent);
