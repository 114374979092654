import { Namespace } from '@config/i18n';
import {
  Box,
  Button,
  ContentText,
  IconDistrictHeatingOutage,
  IconReload,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const SessionTimedOutError = () => {
  const { t } = useTranslation<Namespace>('errors');

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      paddingTop="246px"
      backgroundColor={colors.snowWhite}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="450px"
        alignItems="center"
        gap={spacing.xxxs}
      >
        <IconDistrictHeatingOutage paddingBottom={spacing.xxxs} color={colors.hazeRedOrange} />
        <ContentText fontSize="41px" color={colors.inkGrey} textAlign="center">
          {t('reports.sessionTimedOut.header')}
        </ContentText>
        <ContentText textAlign="center" fontSize={fontSizes.l} color={colors.inkGrey}>
          {t('reports.sessionTimedOut.message')}
        </ContentText>
        <Button leftIcon={<IconReload />} mt={spacing.xs} onClick={refresh} status="secondary">
          {t('reports.sessionTimedOut.button')}
        </Button>
      </Box>
    </Box>
  );
};
