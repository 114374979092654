import { Namespace } from '@config/i18n';
import { Button, useToggle } from '@fortum/elemental-ui';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer, ServiceTilesContainer } from './styles';
import { MissingFieldsModal } from './MissingFieldsModal';
import { ORDERS_CREATION_SUMMARY_ROUTE_PATH } from '@config/routes';
import {
  ConfirmationModal,
  FillAvailableContainer,
  OrdersCreationContext,
} from '@routes/ordersCreation/components';
import { ServiceTile, ServiceTileProps } from '../ServiceTile';

type ServiceTilesProps = Pick<ServiceTileProps, 'onChooseOtherServiceClick'>;

export const ServicesTiles: FC<ServiceTilesProps> = ({ onChooseOtherServiceClick }) => {
  const navigate = useNavigate();

  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'common']);

  const { creationForms, countMissingRequiredFields, clearForms } =
    useContext(OrdersCreationContext);

  const [missingFieldsModalOpen, toggleMissingFieldsModalOpen] = useToggle(false);
  const [discardOrderModalOpen, toggleDiscardOrderModalOpen] = useToggle(false);

  const [missingRequiredFieldsCount, setMissingRequiredFieldsCount] = useState(0);

  const submit = useCallback(() => {
    const missingRequiredFields = countMissingRequiredFields();

    setMissingRequiredFieldsCount(missingRequiredFields);

    if (missingRequiredFields > 0) {
      toggleMissingFieldsModalOpen();
      return;
    }

    navigate(ORDERS_CREATION_SUMMARY_ROUTE_PATH);
  }, [countMissingRequiredFields]);

  return (
    <>
      <FillAvailableContainer>
        <ServiceTilesContainer>
          {creationForms.map((creationForm, index) => (
            <ServiceTile
              key={creationForm.contractIdentifier}
              serviceIndex={index}
              contractIdentifier={creationForm.contractIdentifier}
              ordersCreationForm={creationForm}
              onChooseOtherServiceClick={onChooseOtherServiceClick}
            />
          ))}
        </ServiceTilesContainer>

        {creationForms.length > 0 && (
          <ButtonsContainer>
            <Button
              status="plain"
              onClick={toggleDiscardOrderModalOpen}
              data-testid="discard-orders-button"
            >
              {t('common:discard')}
            </Button>

            <Button onClick={submit} data-testid="submit-order-button">
              {t('orderCreation:submitOrder')}
            </Button>
          </ButtonsContainer>
        )}
      </FillAvailableContainer>

      <MissingFieldsModal
        opened={missingFieldsModalOpen}
        onClose={toggleMissingFieldsModalOpen}
        missingRequiredFieldsCount={missingRequiredFieldsCount}
      />

      <ConfirmationModal
        data-testid="discard-order-modal"
        headerText={t('common:delete')}
        messageText={t('orderCreation:summaryPage.discardModal.message')}
        opened={discardOrderModalOpen}
        onClose={toggleDiscardOrderModalOpen}
        onConfirmClick={clearForms}
      />
    </>
  );
};
