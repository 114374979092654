import { HorizontalContainer } from '@components/styles';
import { Namespace } from '@config/i18n';
import {
  Button,
  ContentText,
  IconAdd,
  IconCheck,
  IconDocuments,
  colors,
} from '@fortum/elemental-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, HeaderSubContainer } from './styles';
import { iconStyles, textStyles, mediumTextStyles, buttonStyles } from './styles';

interface SummaryHeaderProps {
  onClick: () => void;
}

export const SummaryHeader: FC<SummaryHeaderProps> = ({ onClick }) => {
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <Container>
      <HeaderSubContainer>
        <IconDocuments color={colors.oceanGreen} />

        <ContentText size={29}>{t('summaryPage.header.mainText')}</ContentText>
      </HeaderSubContainer>

      <HorizontalContainer>
        <IconCheck {...iconStyles} />
        <ContentText {...textStyles}>
          <ContentText {...mediumTextStyles}>
            {t('summaryPage.confirmationMessage.bolded')}
          </ContentText>
          {t('summaryPage.confirmationMessage.regular')}
        </ContentText>
      </HorizontalContainer>

      <Button {...buttonStyles} onClick={onClick} rightIcon={<IconAdd />}>
        {t('orderCreation:createNewOrder')}
      </Button>
    </Container>
  );
};
