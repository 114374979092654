import { CONTAINER_BORDER_RADIUS, staticBorderStyles, verticalContainer } from '@components/styles';
import { ContentTextProps, colors, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};

  ${staticBorderStyles()}
`;

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  fontWeight: fontWeights.medium,
  ph: spacing.xs,
  pv: spacing.xxs,
  backgroundColor: colors.lightGrey,
  borderRadius: CONTAINER_BORDER_RADIUS,
};
