import { horizontalContainer, verticalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const DynamicTableContainer = styled.div`
  ${horizontalContainer}
  column-gap: ${spacing.xxxs};
`;

export const DimensionsContainer = styled.div`
  ${verticalContainer}

  row-gap: ${spacing.xxxxs};
`;

export const FiltersContainer = styled.div`
  ${horizontalContainer}
  column-gap: ${spacing.xxxs};
  flex: 1;
`;

export const HorizontalContainer = styled.div`
  ${horizontalContainer}
`;
