import React, { FC } from 'react';
import { MenuButton } from './styles';
import { StyledSpan } from '@routes/services/components/location/styles';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '@config/routes';
import { Stylable } from '@components/types';

interface MenuItemProps extends Stylable {
  id: string;
  address: string;
  streetAddress: string;
  locationCode: string;
  numberOfContracts: string;
}

export const MenuItem: FC<MenuItemProps> = ({
  id,
  address,
  streetAddress,
  locationCode,
  numberOfContracts,
  className,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <MenuButton
      id={id}
      type="button"
      name={address}
      className={className}
      $checked={searchParams.get(SEARCH_PARAMS.locationCode) === locationCode}
      onClick={() => {
        searchParams.delete('serviceTabId');
        searchParams.set(SEARCH_PARAMS.locationCode, locationCode);
        setSearchParams(searchParams);
      }}
    >
      {streetAddress}
      <StyledSpan>{`(${numberOfContracts})`}</StyledSpan>
    </MenuButton>
  );
};
