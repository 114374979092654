import { StyledDescriptionListItem } from '@components/styles';
import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { displayedValue } from '@utils/dataOperations';
import { translate } from '@utils/internationalization';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderStatusTileProps {
  orderStatus: OrderDetails['orderStatus'];
}

//TODO: handle status code translation basing on the status group instead of the actual code
export const OrderStatusTile: FC<OrderStatusTileProps> = ({ orderStatus }) => {
  const { t } = useTranslation<Namespace>('orderDetails');

  return (
    <Tile header={t('orderStatus')} data-testid={getTileTestId('order-status')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('status')} data-testid={getListItemTestId('status')}>
          {translate(orderStatus.description, 'domain', 'orderHandlingStatus')}
        </StyledDescriptionListItem>
        <StyledDescriptionListItem
          label={t('lastUpdated')}
          data-testid={getListItemTestId('last-updated')}
        >
          {displayedValue(orderStatus.lastUpdated)}
        </StyledDescriptionListItem>
      </DescriptionList>
    </Tile>
  );
};
