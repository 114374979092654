import { FC, memo } from 'react';
import {
  Box,
  Button,
  Chips,
  colors,
  ContentText,
  fontSizes,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { CharacterChips } from '@routes/customer/types';

interface Props {
  characterChips: CharacterChips;
  setActive: (items: string[]) => void;
  active: string[];
}
const CharactersComponent: FC<Props> = ({ characterChips, active, setActive }) => {
  const { t } = useTranslation<Namespace>('businessPartners');

  return (
    <>
      <ContentText color={colors.inkGrey} fontSize={fontSizes.xl} pt={spacing.xs} pb={spacing.xxxs}>
        {t('selectBusinessPartner')}
      </ContentText>
      <ContentText color={colors.inkGrey} fontSize={fontSizes.m}>
        {t('pleaseSelect')}
      </ContentText>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={spacing.m}
        pb={spacing.xxs}
      >
        <ContentText color={colors.inkGrey} fontSize={fontSizes.m} fontWeight={fontWeights.medium}>
          {t('allBusinessPartners')}
        </ContentText>
        <Button
          id="clearAllSelectedCustomerLetters"
          p={0}
          variant="normal"
          status="plain"
          color={active.length <= 0 ? colors.fogGrey : colors.oceanGreen}
          onClick={() => setActive([])}
        >
          {t('clearAllSelection')}
        </Button>
      </Box>
      <Chips
        id="businessPartnersLetters"
        items={characterChips.letters}
        active={active}
        onChange={setActive}
        multiSelect
        pb={spacing.xs}
      />
    </>
  );
};

export const CharactersLayout = memo(CharactersComponent);
