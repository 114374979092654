import {
  Box,
  colors,
  ContentText,
  fontSizes,
  IconClimateAndResources,
  spacing,
} from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';

export const EmptyLocationLayout: FC = () => {
  const { t } = useTranslation<Namespace>('home');

  return (
    <Box textAlign="center" padding={spacing.xs}>
      <IconClimateAndResources color={colors.oceanGreen} />
      <ContentText color={colors.inkGrey} fontSize={fontSizes.xl}>
        {t('map.yourLocations')}
      </ContentText>
      <ContentText
        textAlign="center"
        color={colors.emperorGrey}
        pt={spacing.xxs}
        fontSize={fontSizes.s}
      >
        {t('map.selectLocation')}
      </ContentText>
    </Box>
  );
};
